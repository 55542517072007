import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import { SimpleTableContext } from "./utils";
import SimpleTableRow from "./SimpleTableRow";
import styles from "./SimpleTable.module.scss";
import LoadingAnimationPage from "../LoadingAnimationPage/LoadingAnimationPage";
import { withStyles } from "@material-ui/core/styles";
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import classnames from "classnames";
import { createReducer } from "../../../utils/store";
import Button from "react-bootstrap/Button";

const EnhancedPaper = props => <Paper variant={"outlined"} {...props} />,
  EmptyTableCell = withStyles({
    root: {
      backgroundColor: "#fafafa"
    }
  })(props => <TableCell {...props} />),
  AddRowButton = withStyles({
    root: {
      position: "absolute",
      bottom: 0,
      right: "0.7rem",
      transform: "translateY(75%)"
    }
  })(({ children, ...props }) => (
    <Fab {...props}>
      <AddIcon />
    </Fab>
  ));

const tableReducer = createReducer(
  { editRow: -1, mode: "" },
  {
    setMode: (state, { mode }) => ({ ...state, mode })
  }
);

const SimpleTable = ({
  as = EnhancedPaper,
  headers = {},
  footers = null,
  data,
  className,
  style,
  editable = false,
  options = {
    rowEditAtStart: false,
    subRender: {}
  },
  callbacks = {},
  ...props
}) => {
  const [editRow, setEditRow] = React.useState(-1),
    [loading, setLoading] = React.useState(false),
    [{ mode }, dispatch] = React.useReducer(tableReducer, {
      editRow: -1,
      mode: ""
    });
  const display = (field, row, isEditing) => {
      let value = row[field];
      const header = headers[field];
      if (!isEditing)
        if (typeof header.display === "function")
          value = header.display(value, row);
      if (isEditing)
        if (typeof header.editValueDisplay === "function")
          value = header.editValueDisplay(value, row);
      return value;
    },
    displayEditHeader = editable && <TableCell />,
    forEachHeader = fn => {
      for (let field in headers)
        if (headers.hasOwnProperty(field)) fn(field, headers[field]);
    };

  const renderEmptyTable = options.subRender?.onEmpty;
  React.useEffect(() => {
    if (mode === "add") {
      dispatch({ type: "setMode", mode: "" });
      setEditRow(data.length - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <SimpleTableContext.Provider
      value={{
        editable,
        editRow,
        setEditRow,
        display,
        options,
        data,
        forEachHeader,
        callbacks,
        setLoading
      }}
    >
      <div className={classnames(styles.Table, className)} style={style}>
        <TableContainer component={as}>
          <Table {...props}>
            <TableHead>
              <TableRow>
                {options.rowEditAtStart === true && displayEditHeader}
                {Object.values(headers).map(
                  header =>
                    header.hidden !== true && (
                      <TableCell
                        className={styles.TableHead}
                        style={header.style || {}}
                      >
                        {!header.hideTitle && (
                          <strong>
                            {typeof header.title === "string"
                              ? header.title
                              : header}
                          </strong>
                        )}
                      </TableCell>
                    )
                )}
                {options.rowEditAtStart !== true && displayEditHeader}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row, index) => (
                <SimpleTableRow row={row} index={index} headers={headers} />
              ))}
            </TableBody>
            {data?.length === 0 && renderEmptyTable && (
              <TableRow>
                <EmptyTableCell
                  colSpan={Object.keys(headers).length + (editable ? 1 : 0)}
                >
                  {renderEmptyTable}
                </EmptyTableCell>
              </TableRow>
            )}
            {footers}
          </Table>
          {editable && <LoadingAnimationPage loading={loading} />}
        </TableContainer>
        {editable && typeof callbacks.onRowAdd === "function" && (
          <div className={"d-flex justify-content-end mt-2"}>
            <Button
              size={"sm"}
              variant={"primary"}
              className={"pr-4"}
              onClick={async () => {
                dispatch({ type: "setMode", mode: "add" });
                await callbacks.onRowAdd();
              }}
            >
              <AddIcon className={"mr-2"} />
              Add
            </Button>
          </div>
        )}
      </div>
    </SimpleTableContext.Provider>
  );
};

export default SimpleTable;
