import { defineActionNames } from "../../utils/store";

const BoothActionType = {
  FETCHING: "booth/fetching",
  FETCHED: "booth/fetched"
};

const [
  BOOTH_ACTIONS_UPDATE_PRICES,
  BOOTH_ACTIONS_UPDATE_PRICES_SUCCESS,
  BOOTH_ACTIONS_UPDATE_PRICE,
  BOOTH_ACTIONS_UPDATE_PRICE_SUCCESS,
  BOOTH_ACTION_UPDATE,
  BOOTH_ACTION_UPDATE_SUCCESS
] = defineActionNames("booth", ["update-prices", "update-price", "update"]);

export default BoothActionType;
export {
  BOOTH_ACTIONS_UPDATE_PRICES,
  BOOTH_ACTIONS_UPDATE_PRICES_SUCCESS,
  BOOTH_ACTIONS_UPDATE_PRICE,
  BOOTH_ACTIONS_UPDATE_PRICE_SUCCESS,
  BOOTH_ACTION_UPDATE,
  BOOTH_ACTION_UPDATE_SUCCESS
};

export const BOOTH_ACTION_ERROR = "booth/error";
