import React from "react";
import * as classnames from "classnames";
import * as PropTypes from "prop-types";
import Select, { components as ReactSelectComponents } from "react-select";
import RelativePortal from "../RelativePortal/RelativePortal";

const ReactSelect = ({
  className = "",
  size = "md",
  components = {},
  styles = {},
  classes = {},
  ...props
}) => {
  const { control: controlStyles, ...otherStyles } = styles;

  return (
    <Select
      className={classnames(className)}
      {...props}
      components={Object.assign(
        {},
        {
          IndicatorSeparator: () => null,
          Menu: props => (
            <RelativePortal
              component={"div"}
              style={{ zIndex: "9999999" }}
              fullWidth={true}
            >
              <ReactSelectComponents.Menu {...props} />
            </RelativePortal>
          ),
          MenuList: ({ className: className$1, ...props }) => (
            <ReactSelectComponents.MenuList
              className={classnames(className$1, classes.MenuList)}
              {...props}
            />
          )
        },
        components
      )}
      theme={theme => {
        // console.log(theme);
        return {
          ...theme,
          spacing: Object.assign(
            {},
            theme.spacing,
            size === "lg"
              ? {
                  controlHeight: 48
                }
              : {}
          ),
          colors: {
            ...theme.colors,
            primary: "#e10f76"
          }
        };
      }}
      styles={{
        control: (base, { isFocused }) => ({
          ...(typeof controlStyles === "function" ? controlStyles(base) : base),
          borderWidth: "1px !important",
          borderColor: isFocused ? "#f9bbd3 !important" : "#ced4da",
          paddingLeft: "0.35rem",
          boxShadow: isFocused
            ? "0 0 0 0.2rem rgba(239, 70, 134, 0.25)"
            : "inherit"
        }),
        ...(otherStyles || {})
      }}
    />
  );
};
ReactSelect.propTypes = {
  getOptionLabel: PropTypes.func,
  options: PropTypes.any
};

export default ReactSelect;
export { ReactSelectComponents };
