import React from "react";
import { useLocation } from "react-router";

const TopBarContext = React.createContext({});

export default TopBarContext;

export const withTopBarContext = (opts = {}) => Component => {
  function TopBarContextInner({ setFullscreen, ...props }) {
    React.useEffect(() => {
      if (typeof opts.isFullscreen === "boolean")
        setFullscreen(opts.isFullscreen);
      return () => {
        setFullscreen(undefined);
      };
    }, []);

    return <Component {...props} />;
  }
  return class extends React.Component {
    render() {
      return (
        <TopBarContext.Consumer>
          {context => (
            <TopBarContextInner
              {...this.props}
              setFullscreen={context.setFullscreen}
            />
          )}
        </TopBarContext.Consumer>
      );
    }
  };
};

export const TopBarContextProvider = ({ children }) => {
  const [isFullscreen, setFullscreen] = React.useState(false),
    location = useLocation(),
    [theme, setTheme] = React.useState("light");

  React.useEffect(() => {
    if (location.pathname.startsWith("/internal")) setTheme("dark");
    else setTheme("light");
  }, [location]);

  return (
    <TopBarContext.Provider value={{ isFullscreen, setFullscreen, theme }}>
      {children}
    </TopBarContext.Provider>
  );
};
