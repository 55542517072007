import React from "react";
import styles from "./AlertDismissable.module.scss";
import Alert from "react-bootstrap/Alert";
// import { hot } from "react-hot-loader";

function AlertDismissable({ heading = "", children, ...props }) {
  const [show, setShow] = React.useState(true);
  if (show) {
    return (
      <Alert
        className={styles.AlertDismissible}
        variant="danger"
        // onClose={() => setShow(false)}
        // dismissible
        {...props}
      >
        {heading.length > 0 && <Alert.Heading>{heading}</Alert.Heading>}
        <p>{children}</p>
      </Alert>
    );
  }
  return <div />;
}

// export default AlertDismissible;
export default AlertDismissable;
