import { createReducer } from "../../utils/store";
import AdminActionTypes from "./const";

const initState = {
  status: "init",
  eUsers: []
};

const adminReducer = createReducer(initState, {
  [AdminActionTypes.FETCH_INTERNAL_USERS]: (state, _) => ({
    status: "fetch-internal-user-pending",
    eUsers: state && state.eUsers
  }),
  [AdminActionTypes.FETCH_INTERNAL_USERS_ERROR]: (_, action) => ({
    status: "fetch-internal-user-error",
    error: action.err
  }),
  [AdminActionTypes.FETCH_INTERNAL_USERS_SUCCESS]: (_, action) => ({
    status: "fetch-internal-user-success",
    eUsers: action.users
  })
});

export default adminReducer;
