import React from "react";
import classnames from "classnames";

const PageTitle = ({
  component: Component = "h1",
  className = "",
  children,
  style = {},
  ...props
}) => (
  <Component
    className={classnames(className.includes("mb-") ? "" : "mb-4", className)}
    style={Object.assign({}, { fontWeight: 600 }, style)}
    {...props}
  >
    {children}
  </Component>
);

export default PageTitle;

export const CardTitle = ({
  component: Component = "h4",
  className = "",
  children,
  style = {},
  ...props
}) => (
  <Component
    className={classnames(className)}
    style={Object.assign({}, { fontWeight: 500, margin: `.25rem 0` }, style)}
    {...props}
  >
    {children}
  </Component>
);