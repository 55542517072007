import React from "react";
// import styles from "./IconButton.module.scss";
// import * as propTypes from "prop-types";
import MuiIconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#e10f76",
    padding: theme.spacing(2),
    "&:hover": {
      backgroundColor: "#ba386a"
    },
    "&:focus": {
      outline: "none"
    }
  },
  sizeSmall: {
    padding: "3px",
    fontSize: "1.125rem"
  },
  label: {
    color: "white"
  }
}));

const IconButton = ({
  children,
  tooltip = null,
  className = null,
  ...props
}) => {
  const classes = useStyles(),
    iconButton = (
      <MuiIconButton
        className={className}
        classes={Object.assign(
          {
            root: classes.root,
            label: classes.label
          },
          props.size === "small" && { sizeSmall: classes.sizeSmall }
        )}
        {...props}
      >
        {children}
      </MuiIconButton>
    );
  if (!tooltip) return iconButton;
  return (
    <TooltipWrapper title={tooltip.title} placement={tooltip.placement}>
      <div>{iconButton}</div>
    </TooltipWrapper>
  );
};

export default IconButton;
