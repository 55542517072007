import React, { useState, useEffect } from "react";

import { ReactComponent as UserIcon } from "../../../images/UserIcon.svg";
import { ReactComponent as DocumentIcon } from "../../../images/DocumentIcon.svg";
import { SvgIcon } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import styles from "./TextInput.module.scss";

const TextInput = ({
  size,
  children,
  className,
  style = {},
  type,
  label,
  variant = 'white',
  inputIcon,
  ...props
}) => {
  const input = React.useRef(null);
  const [hasInputIcon, setHasInputIcon] = useState(inputIcon);
  const [useInputIcon, setInputIcon] = useState();

  useEffect(() => {
    if (hasInputIcon === 'user') {
      setInputIcon(<UserIcon />)
      return
    }
    if (hasInputIcon === 'document') {
      setInputIcon(<DocumentIcon />)
      return
    }
  }, [hasInputIcon])

  return (
    <>
      <TextField
        variant="outlined"
        autoComplete="outlined"
        fullWidth
        ref={input}
        label={label}
        type={type ? type : "text"}
        className={`${styles.TextInput} ${hasInputIcon ? styles.TextInputHasIcon : ''}`}
        classes={{}}
        {...props}
        inputProps={{
          style: {}
        }}
        InputProps={
          hasInputIcon ? {
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  aria-label={label ? label : "Text"}
                  edge="start"
                  size="small"
                  disabled
                >
                  <SvgIcon fontSize="small">
                    {useInputIcon}
                  </SvgIcon>
                </IconButton>
              </InputAdornment>
            ),
          } : {}
        }
      />
    </>
  );
}

export default TextInput;
