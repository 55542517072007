import React, { useState, useEffect } from "react";
import styles from "./BsNavPills.module.scss";
import Nav from 'react-bootstrap/Nav';
import BsButton from 'react-bootstrap/Button';

const BsNavPills = ({
    align = `end`,
    size,
    options = [],
    value,
    defaultValue,
    setActiveState,
    className,
    style = {},
    ...props
}) => {
    const navPills = React.useRef(null)
    const [itemActive, setItemActive] = useState(0)

    const Button = ({children, ...props}) => (
        <BsButton variant="" {...props}>{children}</BsButton>
    )
    const justifyContent = 
        [ "end", "center", "start" ].includes(align)
            ? `justify-content-${align}`
            : `justify-content-end`
    
    const setValue = (v, key) => {
        value = v
        setActiveState(value)
        setItemActive(key)
    }

    useEffect(() => {
    })

    return (
        <Nav
            ref={navPills}
            className={`${justifyContent} ${styles.NavPills}`}
        >
            <Nav.Item className={`${styles.NavPillsItem}`}>
                <div className={`${styles.ButtonGroup}`}>
                    {options.map((item, key) => (
                        <Button
                            className={`${itemActive === key && styles.ItemActive}`}
                            onClick={() => setValue(item.value, key)}
                        >
                            {item.label}
                        </Button>
                    ))}
                </div>
            </Nav.Item>
        </Nav>
    )
}

export default BsNavPills
