import { createReducer, defineActionName } from "../../utils/store";
import { produce } from "immer";
import groupBy from "lodash/groupBy";
import mapValues from "lodash/mapValues";
import keyBy from "lodash/keyBy";
import { combineReducers } from "redux";

function assign(draft, exhibitorId, licences) {
  if (!draft[exhibitorId]) draft[exhibitorId] = {};
  draft[exhibitorId].status = "fetched";
  draft[exhibitorId].list = Object.assign(
    draft[exhibitorId].list || {},
    licences.reduce((acc, val) => ({ ...acc, [val.id]: val }), {})
  );
}

const licencesFn = m => {
    const actionName = defineActionName(m);
    return createReducer(
      {},
      {
        [actionName("fetch")]: (state, { exhibitorId }) =>
          produce(state, draft => {
            draft[exhibitorId] = {
              status: "fetching",
              list: draft[exhibitorId]?.list || {}
            };
          }),
        [actionName("fetch-success")]: (state, { exhibitorId, licences }) =>
          produce(state, draft => {
            assign(draft, exhibitorId, licences);
            // draft[exhibitorId].list = Object.assign({})
          }),
        [actionName("fetch-admin-success")]: (state, { licences }) => {
          return mapValues(groupBy(licences, "exhibitor_id"), list => ({
            status: "fetched",
            list: keyBy(list, "id")
          }));
        },
        [actionName("save-success")]: (state, { exhibitorId, licence }) =>
          produce(state, draft => {
            if (licence.id && draft[exhibitorId]?.list)
              draft[exhibitorId].list[licence.id] = licence;
          })
      }
    );
  },
  licences = combineReducers({
    fstall: licencesFn("food-stall"),
    fhandler: licencesFn("food-handler"),
    reg: licencesFn("reg")
  });

export default licences;
