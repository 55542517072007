import { createReducer } from "../../utils/store";
import BoothActionType from "./const";
import { produce } from "immer";

const booth = createReducer(
  { status: {}, list: {} },
  {
    [BoothActionType.FETCHING]: (state, { id }) =>
      produce(state, draft => {
        draft.status[id] = "fetching";
      }),
    [BoothActionType.FETCHED]: (state, { id, booths, pricing }) =>
      produce(state, draft => {
        draft.status[id] = "fetched";
        draft.list[id] = { booths, pricing };
      })
  }
);

export default booth;
