const internalUserLst = {
  FETCH_INTERNAL_USERS: "admin/fetch-user",
  FETCH_INTERNAL_USERS_SUCCESS: "admin/fetch-user-success",
  FETCH_INTERNAL_USERS_ERROR: "admin/fetch-user-error"
};

const modifyUserInfo = {
  ENABLE_USER: "admin/enable-user",
  ENABLE_USER_SUCCESS: "admin/enable-user-success",
  ENABLE_USER_ERROR: "admin/enable-user-error",

  DISABLE_USER: "admin/disable-user",
  DISABLE_USER_SUCCESS: "admin/disable-user-success",
  DISABLE_USER_ERROR: "admin/disable-user-error"
};

const AdminActionTypes = {
  ...internalUserLst,
  ...modifyUserInfo
};

export default AdminActionTypes;
