import storageSession from "redux-persist/lib/storage/session";
import storage from "redux-persist/lib/storage";
import {
  all,
  call,
  spawn,
  takeLeading as _takeLeading,
  fork,
  take,
  join,
  put,
  race
} from "@redux-saga/core/effects";
import {
  ERROR_ACTION,
  WAIT_FOR_ACTION,
  CALLBACK_ARGUMENT
} from "redux-wait-for-action";
import memoize from "lodash/memoize";
export function createReducer(
  initialState,
  handlers = {},
  { actionMiddleware = payload => payload } = {}
) {
  // console.log(op.postState);
  return function reducer(state = initialState, { type, ...otherActionObj }) {
    if (handlers.hasOwnProperty(type)) {
      return handlers[type](state, actionMiddleware(otherActionObj));
    } else {
      return state;
    }
  };
}
export function persistStorageSelect() {
  let useLocalStorage = process.env.REACT_APP_USELOCALSTORAGE;
  if (typeof useLocalStorage === "string")
    useLocalStorage = useLocalStorage !== "false";
  return !useLocalStorage ? storageSession : storage;
}

export function* combineRootSagas(...sagas) {
  // yield all(sagas.map(saga => call(saga)));
  yield all(
    sagas.map(saga =>
      spawn(function*() {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.error(e);
          }
        }
      })
    )
  );
}

export function* putWait(action) {
  // console.log(action.type);
  yield put(action);
  if (action[WAIT_FOR_ACTION]) {
    const [success] = yield race(
      [
        take(action[WAIT_FOR_ACTION]),
        action[ERROR_ACTION] && take(action[ERROR_ACTION])
      ].filter(Boolean)
    );
    if (success && typeof action[CALLBACK_ARGUMENT] === "function") {
      yield action[CALLBACK_ARGUMENT](success);
    } else yield success;
  }
  // console.log(action[WAIT_FOR_ACTION]);
}

export const takeLeading =
  process.env.NODE_ENV === "production"
    ? _takeLeading
    : (patternOrChannel, saga, ...args) =>
        fork(function*() {
          while (true) {
            const action = yield take(patternOrChannel);
            const task = yield fork(saga, ...args.concat(action));
            yield join(task);
          }
        });

export const defineActionName = memoize(m =>
  memoize(actionName => m + "/" + actionName)
);
export const defineActionNames = (m, actionNames = []) =>
  actionNames
    .reduce(
      (acc, actionName) => [...acc, actionName, actionName + "-success"],
      []
    )
    .map(actionName => defineActionName(m)(actionName));
