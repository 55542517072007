import React from 'react'

const BoothTypeBadge = ({ id, bgColor, name, isSmall = false, ...props }) => (
    <span
        className={`custom-badge`}
        style={{ 
            backgroundColor: bgColor,
            color: [78, 82, 84, 85, 86, 95].includes(id) ? `#fff` : `inherit`,
            border: id === 80 && `1px #414042 solid`,
            ...isSmall ? {
                paddingTop: `0`,
                paddingBottom: `0`,
            } : {}
        }}
        {...props}
    >
        {name}
    </span>
)

export default BoothTypeBadge