import React from "react";
import styles from "./ExhibitorBadge.module.scss";
import * as propTypes from "prop-types";
import * as classnames from "classnames";
import Badge from "react-bootstrap/Badge";

const ExhibitorBadge = ({ role, className, ...props }) => (
  <Badge
    className={classnames(styles.Badge, className)}
    variant={role === "OWNER" ? "primary" : "secondary"}
    {...props}
  >
    {role}
  </Badge>
);
ExhibitorBadge.propTypes = {
  role: propTypes.string.isRequired
};

export default ExhibitorBadge;
