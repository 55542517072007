const EventActionType = {
  FETCHING: "event/fetching",
  FETCHED: "event/fetched",
  ONE_FETCHING: "event/one-fetching",
  ONE_FETCHED: "event/one-fetched",
  UPDATING: "event/updating",
  UPDATED: "event/updated"
};

export const EVENT_ACTION_ADD = "event/adding";
export const EVENT_ACTION_ADDED = "event/added";
export const EVENT_ACTION_ERROR = "event/error";
export const EVENT_ACTION_UPDATE = "event/updating";
export const EVENT_ACTION_UPDATED = "event/updated";

export default EventActionType;

export const EventStatus = {
  0: "Inactivated",
  1: "Draft",
  2: "Activated",
  3: "Finalized",
  4: "Sold out",
};
