import React, { useState, useEffect }  from "react";
// import styles from "./SideBarDesktop.module.scss";
import styles2 from "./InternalSideBarDesktop.module.scss";
// import * as PropTypes from "prop-types";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Collapse from 'react-bootstrap/Collapse';
import * as classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import { ReactComponent as LogoWhite } from "../../../images/QooeeLogo.svg";
import { ReactComponent as LogoDark } from "../../../images/QooeeLogoAdmin.svg";
import { ReactComponent as HamburgerIconTransparent } from "../../../images/HamburgerIconTransparent.svg";
import { useRole } from "../../../HOCs/withRole";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { 
  Person, PersonAdd, PeopleAlt,
  RecentActors, SupervisedUserCircleOutlined, SupervisedUserCircle,
  SupervisorAccount, SupervisorAccountOutlined, PeopleOutline,
  Event, NoteAdd, Receipt,
  Group, GroupAdd, DonutLarge, BarChart,
  Share, InfoOutlined, Help
} from '@material-ui/icons';
// import { styled, ThemeProvider, createTheme } from '@material-ui/core/styles';
// import Box from '@material-ui/core/Box';
// import Divider from '@material-ui/core/Divider';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemButton from '@material-ui/core/ListItemButton';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import Paper from '@material-ui/core/Paper';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import ArrowRight from '@material-ui/icons/ArrowRight';
// import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
// import Home from '@material-ui/icons/Home';
// import Settings from '@material-ui/icons/Settings';
import People from '@material-ui/icons/People';
// import PermMedia from '@material-ui/icons/PermMedia';
// import Dns from '@material-ui/icons/Dns';
// import Public from '@material-ui/icons/Public';

// const menus = [
//   { icon: <People />, label: 'Users' },
//   { icon: <Dns />, label: 'Exhibitors' },
//   { icon: <PermMedia />, label: 'Events' },
//   { icon: <Public />, label: 'Bookings' },
// ];

// const FireNav = styled(List)({
//   '& .MuiListItemButton-root': {
//     paddingLeft: 24,
//     paddingRight: 24,
//   },
//   '& .MuiListItemIcon-root': {
//     minWidth: 0,
//     marginRight: 16,
//   },
//   '& .MuiSvgIcon-root': {
//     fontSize: 20,
//   },
// });

export const InternalSideBarDesktop = ({ user, theme }) => {
  const _SideBar = React.createRef(),
        history = useHistory()
        
  const defaultStateMenu = {
    general: false,
    infomation: false,
  }
  const [stateMenu, setStateMenu] = useState(defaultStateMenu)
  const [open, setOpen] = React.useState(true);
  const handleDropdownToggle = (e) => {
    let menu = e.currentTarget.dataset?.menu
    setStateMenu({ ...defaultStateMenu, [menu]: !stateMenu[menu] })
  }

  useEffect(() => {
    // Open one specific Menu on component mount
    setStateMenu({
      ...defaultStateMenu,
      [Object.keys(defaultStateMenu)[1]]: true
    })
  }, [])

  return (
    <>
    <Navbar
      className={styles2.SideBar}
      fixed={"top"}
      style={{ zIndex: 99, paddingLeft: 30 }}
      ref={_SideBar}
    >
      <Nav className="flex-column">

      <Nav.Item>
          <Navbar.Brand className={styles2.VerticalLabel}>
          <span className={styles2.Label}>ANALYTICS</span>
          </Navbar.Brand>
          <Nav className={`${styles2.Items} flex-column`}>
            <Nav.Item
              onClick={() => history.push("/analytics/dashboard")}
            >
              
              Dashboard
            </Nav.Item>
            <Nav.Item onClick={() => history.push("/analytics/user-actions")}>
              {/* <span><PersonAdd /></span> */}
              User Actions
            </Nav.Item>
          </Nav>
        </Nav.Item>
        <Nav.Item>
          <Navbar.Brand className={styles2.VerticalLabel}>
          <span className={styles2.Label}>USER / VENDOR</span>
          </Navbar.Brand>
          <Nav className={`${styles2.Items} flex-column`}>
            <Nav.Item
              onClick={() => history.push("/internal/users")}
            >
              
              All Users
            </Nav.Item>
            <Nav.Item onClick={() => history.push("/internal/register")}>
              {/* <span><PersonAdd /></span> */}
              Create new user
            </Nav.Item>
          </Nav>
          <Nav className={`${styles2.Items} flex-column`}>
            <Nav.Item onClick={() => history.push("/internal/exhibitors")}>
              {/* <span><PeopleAlt /></span> */}
              All Exhibitors
            </Nav.Item>
          </Nav>
        </Nav.Item>

        <Nav.Item>
          <Navbar.Brand className={styles2.VerticalLabel}>
          <span className={styles2.Label}>EVENTS</span>
          </Navbar.Brand>
          <Nav className={`${styles2.Items} flex-column`}>
            <Nav.Item onClick={() => history.push("/internal/event")}>
              Management
            </Nav.Item>
            <Nav.Item onClick={() => history.push("/internal/event?modal=addEvent&modalSize=xl")}>
              {/* <span><NoteAdd /></span> */}
              Add new event
            </Nav.Item>
          </Nav>
        </Nav.Item>

        <Nav.Item>
          <Navbar.Brand className={styles2.VerticalLabel}>
          <span className={styles2.Label}>BOOKING</span>
          </Navbar.Brand>
          <Nav className={`${styles2.Items} flex-column`}>
            <Nav.Item onClick={() => history.push("/bookings/shopping-carts")}>
              Shopping Carts
            </Nav.Item>
          </Nav>
          <Nav className={`${styles2.Items} flex-column`}>
            <Nav.Item onClick={() => history.push("/bookings/abandoned-carts")}>
              Abandoned Carts
            </Nav.Item>
          </Nav>
          <Nav className={`${styles2.Items} flex-column`}>
            <Nav.Item onClick={() => history.push("/bookings/management")}>
              Bookings
            </Nav.Item>
          </Nav>
          <Nav className={`${styles2.Items} flex-column`}>
            <Nav.Item onClick={() => history.push("/bookings/payments")}>
              Payments
            </Nav.Item>
          </Nav>
          <Nav className={`${styles2.Items} flex-column`}>
            <Nav.Item onClick={() => history.push("/bookings/sfa")}>
              SFA Submissions
            </Nav.Item>
          </Nav>
          <Nav className={`${styles2.Items} flex-column`}>
            <Nav.Item onClick={() => history.push("/internal/promotions")}>
              Promotions
            </Nav.Item>
          </Nav>
        </Nav.Item>


        <Nav.Item>
          <Navbar.Brand className={styles2.VerticalLabel}>
          <span className={styles2.Label}>LUCKY DRAW</span>
          </Navbar.Brand>
          <Nav className={`${styles2.Items} flex-column`}>
            <Nav.Item onClick={() => history.push("/winners")}>
              Entries
            </Nav.Item>
          </Nav>
          <Nav className={`${styles2.Items} flex-column`}>
            <Nav.Item onClick={() => window.open(`https://winner.qooee.com/?p=${user.obj?.auth?.jwt}`, "blank")}>
              Lucky Draw Page
            </Nav.Item>
          </Nav>
          
        </Nav.Item>

        <Nav.Item>
          <Navbar.Brand className={styles2.VerticalLabel}>
          <span className={styles2.Label}>OTHERS</span>
          </Navbar.Brand>
          <Nav className={`${styles2.Items} flex-column`}>
            <Nav.Item onClick={() => window.open(`${process.env.REACT_APP_API_HOST_SHORT}/v1/accounting/xero/connect`, '_blank')}>
              Connect XERO
            </Nav.Item>
          </Nav>
          <Nav className={`${styles2.Items} flex-column`}>
            <Nav.Item onClick={() => {}}>
              Xero Invoices
            </Nav.Item>
          </Nav>
          
          <Nav className={`${styles2.Items} flex-column`}>
            <Nav.Item onClick={() => history.push("/game-results")}>
              Contest
            </Nav.Item>
          </Nav>
          <Nav className={`${styles2.Items} flex-column`}>
            <Nav.Item onClick={() => history.push("/others/reports")}>
              Dynamic reports
            </Nav.Item>
          </Nav>
          <Nav className={`${styles2.Items} flex-column`}>
            <Nav.Item onClick={() => history.push("/edm")}>
              EDM
            </Nav.Item>
          </Nav>
        </Nav.Item>

      </Nav>
    </Navbar>
    </>
  )
}


export const InternalSideBarDesktopOPS = ({ user, theme }) => {
  const _SideBar = React.createRef(),
        history = useHistory()
        
  const defaultStateMenu = {
    general: false,
    infomation: false,
  }
  const [stateMenu, setStateMenu] = useState(defaultStateMenu)
  const [open, setOpen] = React.useState(true);
  const handleDropdownToggle = (e) => {
    let menu = e.currentTarget.dataset?.menu
    setStateMenu({ ...defaultStateMenu, [menu]: !stateMenu[menu] })
  }

  useEffect(() => {
    // Open one specific Menu on component mount
    setStateMenu({
      ...defaultStateMenu,
      [Object.keys(defaultStateMenu)[1]]: true
    })
  }, [])

  return (
    <>
    <Navbar
      className={styles2.SideBar}
      fixed={"top"}
      style={{ zIndex: 99, paddingLeft: 30 }}
      ref={_SideBar}
    >
      <Nav className="flex-column">

      
        <Nav.Item>
          <Navbar.Brand className={styles2.VerticalLabel}>
          <span className={styles2.Label}>LUCKY DRAW</span>
          </Navbar.Brand>
          <Nav className={`${styles2.Items} flex-column`}>
            <Nav.Item onClick={() => history.push("/winners")}>
              Entries
            </Nav.Item>
          </Nav>
          <Nav className={`${styles2.Items} flex-column`}>
            <Nav.Item onClick={() => window.open(`https://winner.qooee.com/?p=${user.obj?.auth?.jwt}`, "blank")}>
              Lucky Draw Page
            </Nav.Item>
          </Nav>
          
        </Nav.Item>

      </Nav>
    </Navbar>
    </>
  )
}