import React from "react";

export const SimpleTableContext = React.createContext({
  editRow: -1,
  setEditRow: () => {}
});

export const withSimpleTableContext = Component => props => (
  <SimpleTableContext.Consumer>
    {contextProps => <Component {...contextProps} {...props} />}
  </SimpleTableContext.Consumer>
);
