import user from "./user/reducer";
import admin from "./admin/reducer";
import { combineReducers } from "redux";
import exhibitor from "./exhibitor/reducer";
import event from "./event/reducer";
import app from "./app/reducers";
import boothType from "./boothType/reducer";
import booth from "./booth/reducer";
import noti from "./noti/reducer";
import licences from "./licences/reducer";
// import { connectRouter } from "connected-react-router";

const createRootReducer = (history, asyncReducers = {}) => {
  const rootReducer = combineReducers({
    // router: connectRouter(history),
    app,
    user,
    admin,
    exhibitor,
    event,
    boothType,
    booth,
    noti,
    licences,
    ...asyncReducers
  });

  return rootReducer;
  // if (process.env.NODE_ENV === "production") return rootReducer;
  // else
  //   return (state, action) =>
  //     action.type === "@app/hydrate"
  //       ? action.payload
  //       : rootReducer(state, action);
};

export default createRootReducer;
