import React, { useEffect, useState } from "react";
// import styles from "./Responsive.module.scss";
// import * as propTypes from "prop-types";
import { NullComponent } from "../../../utils/common";
import { isMobile } from "react-device-detect";

function Responsive({
  mobile: Mobile = NullComponent,
  desktop: Desktop,
  ...props
}) {
  
  const checkIsReponsive = () => {
    return window.innerWidth < 560;
  }

  const [isReponsive, setIsReponsive] = useState(checkIsReponsive);

  useEffect(() => {
    const handleResize = () => {
      const isReponsive = checkIsReponsive();
      setIsReponsive(isReponsive);    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, []);

  if (isMobile) return <Mobile {...props} />;
  if (isReponsive) return <Mobile {...props} />;
  return <Desktop {...props} />;

}

export default Responsive;

export function InternalResponsive({
  mobile: Mobile = NullComponent,
  desktop: Desktop,
  ...props
}) {

  if (isMobile) return <Mobile {...props} />
  return <Desktop {...props} />
}