import React from "react";
import { Button, Row, Input, Alert, Col } from "reactstrap";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import _ from "lodash";
import { axiosApi } from "../../utils/api";
import { NotificationManager } from "react-notifications";
import classnames from "classnames";
import TextField from "@material-ui/core/TextField";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router";


const PaymentBatchDetail = props => {
  const history = useHistory();
  const {
    onClose,
    payment,
    paymentMethods,
    paymentStatus,
    fetchOrder,
    auth
  } = props;
  const [payload, onChangePayload] = React.useState(
    {
      ..._.pick(payment, ['payment_due', 'payment_date', 'message', 'status', 'payment_method', 'short_amount_paid', 'payment_no']),
      payment_date: payment.payment_date ?? moment(new Date()).format("YYYY-MM-DD"),
      payment_due: payment.payment_due ?? moment(new Date()).format("YYYY-MM-DD")
    }
    );

  const [error, setError] = React.useState("");

  const onChange = (key, value) =>
    onChangePayload({
      ...payload,
      [key]: value
    });

  const onSuccess = e => {
    if (e) 
      setError(_.get(e, "message", "Please fill in the required fields"));
    else {
      setError("");
      onClose();
      NotificationManager.success("Approved payment successfully.");
      fetchOrder();
    }
  };

  const onSubmit = async e => {
    try {
      const response = payment.id
        ? await axiosApi
            .auth(auth)
            .put(
              `/v1/booking/a/order/${payment.order_id}/payment-batch/${payment.id}`,
              payload
            )
        : await axiosApi
            .auth(auth)
            .post(`/v1/booking/a/order/${payment.order_id}/payment-batch`, {
              ...payload,
              order_id: payment.order_id
            });
      const { data } = response;
      const { success, error } = data;
      return onSuccess(success ? "" : error);
      
    } catch (e){
      onSuccess(e);
    }

  };

  return (
    <div
      className={classnames("custom-ui shadow")}
      style={{ width: 400, margin: "70px auto" }}
    >
      <div className="p-5">
        <Row>
          <h3>
            Payment batch detail {payment.is_view_mode && <>(View only)</>}
          </h3>
        </Row>
        <Row className="mt-3">
          <TextField
            className="w-100"
            variant="outlined"
            type="number"
            label="Amount*"
            value={payload.short_amount_paid}
            onChange={e =>
              onChange("short_amount_paid", parseFloat(e.target.value))
            }
          />
        </Row>
        <Row className="mt-3">
          <TextField
            className="w-100"
            type="number"
            variant="outlined"
            label="Payment No.*"
            value={payload.payment_no}
            onChange={e => onChange("payment_no", parseInt(e.target.value))}
          />
        </Row>
        <Row className="mt-3">
          <h6 className="w-100">Payment Method*</h6>
          <Select
            className="w-100"
            defaultValue={paymentMethods.find(
              o => o.value === payload.payment_method
            )}
            onChange={({ value }) => onChange("payment_method", value)}
            options={paymentMethods}
          />
        </Row>

        <Row className="mt-3">
          <h6 className="w-100">Status*</h6>
          <Select
            className="w-100"
            defaultValue={paymentStatus.find(o => o.value === payload.status)}
            onChange={({ value }) => onChange("status", value)}
            options={paymentStatus}
          />
        </Row>

        <Row className="mt-3">
          <Col className="pl-0">
            <h6 className="w-100">Payment Date*</h6>
            <DatePicker 
              className="w-100"
              selected={new Date(payload.payment_date ??  new Date())} 
              onChange={v =>
                onChange(
                  "payment_date",
                  moment(new Date(v)).format("YYYY-MM-DD")
                )
              } 
            />
          </Col>
          <Col className="pr-0">
            <h6 className="w-100">Payment Due*</h6>
            <DatePicker 
              className="w-100"
              selected={new Date(payload.payment_due ??  new Date())} 
              onChange={v =>
                onChange(
                  "payment_due",
                  moment(new Date(v)).format("YYYY-MM-DD")
                )
              } 
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <h6 className="w-100">Note</h6>
          <textarea
            className="w-100"
            value={payload.message}
            onChange={e => onChange("message", e.target.value)}
          />
        </Row>

        {error && error.length > 0 && <Alert className="mt-3" color={"danger"}>{error}</Alert>}

        <Row className="mt-3">
          <Button className="mx-1" onClick={onClose} color="secondary">
            Cancel
          </Button>

          <Button onClick={() => history.push(`/invoice/${payment.order_id}_${payment.id}`)} className="mx-1" color="secondary">View detailed Invoice</Button>
          {!payment.is_view_mode && (
            <Button color="primary" onClick={onSubmit}>
              Save
            </Button>
          )}
        </Row>
      </div>
    </div>
  );
};

export default PaymentBatchDetail;
