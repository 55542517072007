import { createReducer, persistStorageSelect } from "../../utils/store";
import { removeEmpty } from "../../utils/common";
import { EXHIBITOR_ACTION_FETCHED, ExhibitorActionType } from "./const";
// import deepAssign from "deep-assign";
import update from "react-addons-update";
// import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistReducer } from "redux-persist";
import { USER_ACTION_LOGIN_BEFORE } from "../user/const";
import { produce } from "immer";

function processExhibitorItem(item) {
  // if (typeof item.status[0] === "undefined")
  //   throw new Error("Error expected: exhibitor didn't have any status");
  const status = item.status ? item.status[0].status_id : -2;
  const credit_status = item.credit_rating ? item.credit_rating[0]?.credit_rating : 0;

  return {
    ...item,
    status_all: item.status,
    credit_status_all: item.credit_rating,
    status,
    credit_status
  }
}

const exhibitor = createReducer(
  {
    status: {},
    select: {}
  },
  {
    [ExhibitorActionType.FETCHING]: (state, { user }) =>
      update(state, {
        status: {
          [user.obj.id]: { $set: "fetching" }
        }
      }),
    /**
     * change: SELECT always dispatched after FETCHED as it has been declared at sagas.js
     */
    [EXHIBITOR_ACTION_FETCHED]: (state, { user, list }) => {
      const newList = list.map(processExhibitorItem);
      // console.log("EXHIBITOR_ACTION_FETCHED", list)
      const spec = {
        exhibitors: { $set: newList },
        status: { $set: "fetched" }
      };
      return update(state, spec);
    },
    [ExhibitorActionType.FETCHED_ONE]: (state, { user, item }) => {
      console.log("ExhibitorActionType.FETCHED_ONE", state, item)
      const index = state.exhibitors.findIndex(_item => _item.id === item.id);
      if (index < 0)
        return update(state, {
          $push: [processExhibitorItem(item)]
        });
      return update(state, {
          exhibitors: {
            $splice: [[index, 1, processExhibitorItem(item)]]
          }
      });
    },
    [ExhibitorActionType.SELECT]: (state, { user, item }) =>
      update(state, {
        select: {
          $merge: { [user.obj.id]: typeof item === "object" ? item.id : item }
        }
      }),
    [USER_ACTION_LOGIN_BEFORE]: state =>
      produce(state, draft => {
        for (let key in draft)
          if (draft.hasOwnProperty(key) && key !== "select") delete draft[key];
        draft.status = {};
        return draft;
      })
  }
);

export default persistReducer(
  {
    key: "exhibitorCurrent",
    whitelist: ["select"],
    storage: persistStorageSelect()
  },
  exhibitor
);
