import React from "react";
import { useSnackbar } from "notistack";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import {
  NotiActionsCloseToast,
  NotiActionsEnqueueToast,
  NotiActionsRemoveToast
} from "../store/noti/actions";

let displayed = [];
export function ToastNotifier() {
  const dispatch = useDispatch();
  const noti = useSelector(store => store.noti);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const storeDisplayed = id => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = id => {
    displayed = [...displayed.filter(key => id !== key)];
  };
  React.useEffect(() => {
    // console.log(noti);
    noti.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        console.log("dismissed " + key);
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      // console.log("enqueue");
      // console.log(message);
      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch(NotiActionsRemoveToast(myKey));
          removeDisplayed(myKey);
        }
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [noti, closeSnackbar, enqueueSnackbar, dispatch]);
  return null;
}
export function useToast() {
  const dispatch = useDispatch(),
    toast = React.useRef({});
  // React.useEffect(() => {
  ["success", "info", "warning", "error"].forEach(variant => {
    toast.current[
      `toast${variant[0].toUpperCase() + variant.slice(1)}`
    ] = function(message, title, duration = 2000, toastOptions = {}) {
      const options = { variant, ...toastOptions };
      if (duration > 0) options.autoHideDuration = duration;
      dispatch(
        NotiActionsEnqueueToast({
          key: toastOptions.key,
          message: (
            <div>
              {!isEmpty(title) && <h4>{title}</h4>}
              <span>{message}</span>
            </div>
          ),
          options
        })
      );
    };
  });
  toast.current.toastClose = key => dispatch(NotiActionsCloseToast(key));
  // }, [dispatch]);
  return toast.current;
}
const withToast = Component =>
  function(props) {
    const toast = useToast();
    return <Component {...props} {...toast} />;
  };

export default withToast;
