import React from "react";
import styles from "./FHandlerRegistrationDoc.module.scss";
import { compose, identity } from "../../utils/common";

import { withRouter } from "react-router";
import withProps from "../../components/_helpers/withProps";
import qs from "qs";
import Moment from "react-moment";

const FHandlerRegistrationDocInner = compose(
  process.env.NODE_ENV === "development"
    ? compose(
        withRouter,
        withProps(props => ({
          query: qs.parse(props.location.search, { ignoreQueryPrefix: true })
        })),
        withProps(props => ({
          debug:
            typeof props.query.form === "object" &&
            JSON.stringify(props.query.form) !== "{}",
          form: props.form || props.query.form || {}
        }))
      )
    : identity
)(({ form, debug = false, event, innerRef }) => {
  return (
    <div className={styles.Wrapper} data-debug={debug}>
      <div className={styles.Page} ref={innerRef}>
      <div className="d-flex justify-content-between align-items-start">
          <div>
            <img src={require("./../../images/sfa-logo.png")} alt="SFA logo" style={{ width: 200 }} /> 
          </div>
          <p style={{ padding: 30 }}>www.sfa.gov.sg<br/>
            52 Jurong Gateway Road<br/>
            #14-01 Singapore 608550
          </p>
        </div>
        <h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>
          REGISTRATION OF FOOD HANDLERS
        </h6>
        <div className="d-flex justify-content-between align-items-start">
          <p className="font-weight-bold">
            Temporary Fair Location: {form.temporary_fair_location}
          </p>
          <p className="font-weight-bold">Foodstall No.: {form.foodstall_no}</p>
        </div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th style={{ verticalAlign: "top" }}>NRIC/FIN No.*</th>
              <th style={{ width: 300, verticalAlign: "top" }}>Name of Food Handler <br/>(Name as in NRIC / FIN)</th>
              <th style={{ verticalAlign: "top" }}>Gender<br />(M/F)</th>
              <th style={{ verticalAlign: "top" }}>Date of Birth<br />(DD/MM/YYYY)</th>
              <th style={{ verticalAlign: "top" }}>Work Permit Expiry Date<br />(DD/MM/YYYY)<br/><span className={"font-italic"}>As applicable</span></th>
              <th style={{ verticalAlign: "top" }}>The most recent of the two course pass dates (DD/MM/YYYY)* - <br />(i) Basic Food Hygiene Course<br/>(ii) Refresher Course</th>
            </tr>
          </thead>
          <tbody>
            {(form.handlers || []).map(row => (
              <tr>
                <td>{row.nric_fin}</td>
                <td>{row.name}</td>
                <td>{{ 0: "M", 1: "F" }[row.gender * 1]}</td>
                <td>
                  <Moment format={"DD/MM/YYYY"}>{row.dob}</Moment>
                </td>
                <td>
                  <Moment format={"DD/MM/YYYY"}>
                    {row.work_permit_expiry}
                  </Moment>
                </td>
                <td>
                  <Moment format={"DD/MM/YYYY"}>
                    {row.course_passed_date}
                  </Moment>{' - '}{{ 0: "i", 1: "ii" }[row.course_type === 'BASIC' ? 0 : 1]}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <p className={"font-italic"}>
          <strong>
            * Scanned or photocopies of the following documents must be
            submitted with this form
          </strong>
          <br />
          &nbsp;1) NRIC (for Singaporeans and Permanent Residents) or MOM-issued Work Pass (For Foreigners) – front and back
          <br />
          &nbsp;2) Certificate of Basic Food Hygiene Course / Refresher Course
        </p>
        <p>
          I hereby certify that the above information of the food handler(s) is/are true.
        </p>
        <div className="d-flex justify-content-between">
          <p>
            <strong>Name of licensee: </strong>
            {form.name_of_licensee}
          </p>
          <p>
            <strong>Signature: </strong>
            <span style={{ textDecoration: "underline" }}>
              {Array(20)
                .fill("_")
                .join("")}
            </span>
          </p>
          <p>
            <strong>Date: </strong>
            <Moment format={"DD/MM/YYYY"}>{form.date}</Moment>
          </p>
          <p>
            <strong>Contact no.: </strong>
            {form.tel_no}
          </p>
          <p>
            <strong>Email add.: </strong>
            {form.email}
          </p>
        </div>
      </div>
    </div>
  );
});

const FHandlerRegistrationDoc = React.forwardRef((props, ref) => (
  <FHandlerRegistrationDocInner innerRef={ref} {...props} />
));

export default FHandlerRegistrationDoc;
