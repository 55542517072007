import React from "react";
import { Button, Row, Input, Alert } from "reactstrap";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import _ from "lodash";
import { axiosApi } from "../../utils/api";
import { NotificationManager } from "react-notifications";
import styles from "./BookingOrderDetail.module.scss";
import classnames from "classnames";
import { CardElement, injectStripe } from "react-stripe-elements";

const StripeCheckoutForm = props => {
  console.log(props);
  const { onClose, payment, auth, fetchOrder, stripe } = props;

  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#87bbfd",
        color: "#0e8dce",
        fontWeight: 500,
        fontFamily: "Baloo 2, Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "18px",
        fontSmoothing: "antialiased",
        "::placeholder": { color: "#87bbfd" }
      }
    }
  };

  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [cardData, setCartData] = React.useState();

  const _payByStripe = payload => {
    return axiosApi.auth(auth).post("/v1/payment/purchase", payload);
  };

  const onSuccess = e => {
    if (e) {
      const errorMsg = _.get("data.err.message");
      setError(errorMsg);
    } else {
      setError("");
      onClose();
      NotificationManager.success("Confirmed payment successfully.");
      fetchOrder();
    }
    setLoading(false);
  };

  const onSubmit = async () => {
    setLoading(true);
    if (!stripe) {
      setError("Something went wrong. Please reload the page and try again.");
      setLoading(false);
    } else {
      setError("");
    }

    try {
      stripe
        .createToken()
        .then(async payload => {
          if (!payload.token || !payload.token.id) {
            setError("Invalid card number. Please re-enter and try again.");
            return;
          }
          const { data } = await _payByStripe({
            source: payload.token ? payload.token.id : null,
            description: `Pay for ${payment.order_no}, paid amount ${payment.short_amount_paid}`,
            payment_reminder_id: payment.id
          });

          const { success, error, message } = data;

          if (error || message) {
            onSuccess(error || message);
            return;
          }

          onSuccess();
          // console.log(data);
        })
        .catch(err => {
          onSuccess(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const onCardChange = data => {
    setCartData(data);
    const { error = {} } = data;
    // console.log(data)
    setError(null);
    if (error) {
      setError(error.message);
    } else if (data.complete !== true) {
      setError("Invalid credit card");
    }
  };

  return (
    <div className={classnames(styles.StripeFormDlg, "custom-ui shadow")}>
      <div className="p-5">
        <Row>
          <h1>Checkout</h1>
        </Row>
        <Row>
          <div className="w-100">
            Amount: <b>${payment.short_amount_paid}</b>
          </div>
          <div className="w-100">
            For Order: <b>{payment.order_no}</b>
          </div>
        </Row>

        <Row className={classnames(styles.StripeCardElement, "mt-4 mb-4")}>
          <CardElement
            hidePostalCode={true}
            options={CARD_OPTIONS}
            onChange={onCardChange}
          />
        </Row>

        <Row>
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>
          {"   "}
          <Button
            className="sw-100 ml-2"
            color={"primary"}
            disabled={loading || error || !(cardData && cardData.complete)}
            onClick={onSubmit}
          >
            {!loading && <>{"Confirm"}</>}
            {loading && (
              <Loader type="ThreeDots" color="white" height={15} width={15} />
            )}
          </Button>
        </Row>
        <Row>
          {error && error.length > 0 && (
            <Alert color="danger" className="mt-3 w-100">
              {error}
            </Alert>
          )}
        </Row>
      </div>
    </div>
  );
};

const StripeCheckoutFormWithStripe = injectStripe(StripeCheckoutForm);

export default StripeCheckoutFormWithStripe;
