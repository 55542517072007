import React from "react";

import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

function PasswordInput({
  size,
  children,
  className,
  style = {},
  label,
  variant = "white",
  ...props
}) {
  const [reveal, setReveal] = React.useState(false),
    input = React.useRef(null);

  // React.useEffect(() => {
  //   input.current.type = reveal ? "text" : "password";
  // }, [reveal]);

  return (
    <>
      <FormControl variant="outlined" className={"w-100"}>
        <InputLabel htmlFor="outlined-adornment-password">
          {label ? label : "Password"}
        </InputLabel>
        <OutlinedInput
          label={label ? label : "Password"}
          id="outlined-adornment-password"
          type={reveal ? "text" : "password"}
          ref={input}
          {...props}
          // value={values.password}
          // onChange={handleChange('password')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setReveal(!reveal)}
                // onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {reveal ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />
        {children}
      </FormControl>
    </>
  );
}

export default PasswordInput;
