import React from "react";
import styles from "./UserTopBar.module.scss";
import ExhibitorBadge from "../UI/ExhibitorBadge/ExhibitorBadge";
import classnames from "classnames";
import withTopBarExhibitor from "../../controllers/withTopBarExhibitor/withTopBarExhibitor";
import withExhibitorStatusStyling from "../../HOCs/withExhibitorStatusStyling";
import Dropdown from "react-bootstrap/Dropdown";
// import FontAwesomeCircle from "../UI/FontAwesomeCircle/FontAwesomeCircle";
// import { faEllipsisH } from "@fortawesome/free-solid-svg-icons/faEllipsisH";
// import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
// import { library } from "@fortawesome/fontawesome-svg-core";

// const formatFaSvg = (pathData, width = 512, height = 580) => ({
//     icon: [width, height, [], null, pathData],
//     iconName: "a",
//     prefix: "fac"
//   }),
//   faA = formatFaSvg(
//     "M159.20 69.60L159.20 69.60L159.20 69.60Q172.80 61.60 200.40 54.40L200.40 54.40L200.40 54.40Q228 47.20 256 47.20L256 47.20L256 47.20Q296 47.20 326.80 58.80L326.80 58.80L326.80 58.80Q357.60 70.40 366.40 95.20L366.40 95.20L366.40 95.20Q380.80 136.80 397.60 192L397.60 192L397.60 192Q414.40 247.20 431.20 306L431.20 306L431.20 306Q448 364.80 464 422L464 422L464 422Q480 479.20 492 523.20L492 523.20L492 523.20Q482.40 534.40 463.20 541.20L463.20 541.20L463.20 541.20Q444 548 417.60 548L417.60 548L417.60 548Q398.40 548 385.20 544.80L385.20 544.80L385.20 544.80Q372 541.60 363.60 535.20L363.60 535.20L363.60 535.20Q355.20 528.80 350 518.40L350 518.40L350 518.40Q344.80 508 340.80 494.40L340.80 494.40L326.40 439.20L181.60 439.20L181.60 439.20Q175.20 463.20 168.80 488.80L168.80 488.80L168.80 488.80Q162.40 514.40 156 538.40L156 538.40L156 538.40Q144.80 542.40 132.40 545.20L132.40 545.20L132.40 545.20Q120 548 102.40 548L102.40 548L102.40 548Q61.60 548 42 534L42 534L42 534Q22.40 520 22.40 495.20L22.40 495.20L22.40 495.20Q22.40 484 25.60 472.80L25.60 472.80L25.60 472.80Q28.80 461.60 32.80 446.40L32.80 446.40L32.80 446.40Q38.40 424.80 48.40 392.80L48.40 392.80L48.40 392.80Q58.40 360.80 70.40 323.60L70.40 323.60L70.40 323.60Q82.40 286.40 96 247.20L96 247.20L96 247.20Q109.60 208 121.60 173.20L121.60 173.20L121.60 173.20Q133.60 138.40 143.60 111.20L143.60 111.20L143.60 111.20Q153.60 84 159.20 69.60ZM260 172.80L255.20 172.80L255.20 172.80Q244 206.40 230 247.60L230 247.60L230 247.60Q216 288.80 204.80 331.20L204.80 331.20L304 331.20L304 331.20Q292.80 288 281.20 246.80L281.20 246.80L281.20 246.80Q269.60 205.60 260 172.80L260 172.80Z"
//   ),
//   faB = formatFaSvg(
//     "M192 342.40L192 440L192 440Q203.20 443.20 220.80 444.40L220.80 444.40L220.80 444.40Q238.40 445.60 254.40 445.60L254.40 445.60L254.40 445.60Q286.40 445.60 307.60 432.40L307.60 432.40L307.60 432.40Q328.80 419.20 328.80 391.20L328.80 391.20L328.80 391.20Q328.80 364.80 311.20 353.60L311.20 353.60L311.20 353.60Q293.60 342.40 257.60 342.40L257.60 342.40L192 342.40ZM192 150.40L192 244.80L250.40 244.80L250.40 244.80Q284.80 244.80 300.40 232L300.40 232L300.40 232Q316 219.20 316 194.40L316 194.40L316 194.40Q316 172.80 298 160L298 160L298 160Q280 147.20 244 147.20L244 147.20L244 147.20Q232 147.20 216.40 148L216.40 148L216.40 148Q200.80 148.80 192 150.40L192 150.40ZM244 552.80L244 552.80L244 552.80Q230.40 552.80 212 552L212 552L212 552Q193.60 551.20 174 548.80L174 548.80L174 548.80Q154.40 546.40 135.20 542.80L135.20 542.80L135.20 542.80Q116 539.20 100 532.80L100 532.80L100 532.80Q56 516 56 474.40L56 474.40L56 100.80L56 100.80Q56 84 65.20 74.80L65.20 74.80L65.20 74.80Q74.40 65.60 90.40 60L90.40 60L90.40 60Q117.60 50.40 158.40 46L158.40 46L158.40 46Q199.20 41.60 242.40 41.60L242.40 41.60L242.40 41.60Q344.80 41.60 400 76L400 76L400 76Q455.20 110.40 455.20 182.40L455.20 182.40L455.20 182.40Q455.20 218.40 434.40 244.40L434.40 244.40L434.40 244.40Q413.60 270.40 378.40 282.40L378.40 282.40L378.40 282.40Q418.40 293.60 445.20 323.20L445.20 323.20L445.20 323.20Q472 352.80 472 397.60L472 397.60L472 397.60Q472 476.80 413.20 514.80L413.20 514.80L413.20 514.80Q354.40 552.80 244 552.80Z"
//   ),
//   faC = formatFaSvg(
//     "M323.20 152.80L323.20 152.80L323.20 152.80Q261.60 152.80 228.80 190.40L228.80 190.40L228.80 190.40Q196 228 196 296.80L196 296.80L196 296.80Q196 366.40 232.40 404L232.40 404L232.40 404Q268.80 441.60 330.40 441.60L330.40 441.60L330.40 441.60Q363.20 441.60 386 432.80L386 432.80L386 432.80Q408.80 424 429.60 412.80L429.60 412.80L429.60 412.80Q443.20 424 450.80 439.60L450.80 439.60L450.80 439.60Q458.40 455.20 458.40 476L458.40 476L458.40 476Q458.40 508.80 422 531.60L422 531.60L422 531.60Q385.60 554.40 311.20 554.40L311.20 554.40L311.20 554.40Q257.60 554.40 210.40 539.20L210.40 539.20L210.40 539.20Q163.20 524 128 492.40L128 492.40L128 492.40Q92.80 460.80 72.40 412.40L72.40 412.40L72.40 412.40Q52 364 52 296.80L52 296.80L52 296.80Q52 234.40 71.60 186.80L71.60 186.80L71.60 186.80Q91.20 139.20 125.20 106.40L125.20 106.40L125.20 106.40Q159.20 73.60 204.80 56.80L204.80 56.80L204.80 56.80Q250.40 40 302.40 40L302.40 40L302.40 40Q376 40 416.40 64L416.40 64L416.40 64Q456.80 88 456.80 125.60L456.80 125.60L456.80 125.60Q456.80 146.40 446.40 161.60L446.40 161.60L446.40 161.60Q436 176.80 422.40 185.60L422.40 185.60L422.40 185.60Q401.60 172 378 162.40L378 162.40L378 162.40Q354.40 152.80 323.20 152.80Z"
//   );
//
// library.add(faA, faB, faC);

// const exhibitorStatus = {
//   1: [faEllipsisH],
//   2: [faEllipsisH],
//   3: [faCheck],
//   4: [faA],
//   5: [faB],
//   6: [faC]
// };

const ExhibitorName = withExhibitorStatusStyling(
  ({ exhibitor, exhibitorStatusTextClassName }) => {
    return (
      <>
        <span
          className={classnames(
            styles.StatusIndicator,
            exhibitorStatusTextClassName
          )}
        >
          {exhibitor.newestStatus.id === 1 ? "Pending" : "Activated"}
        </span>
        {exhibitor.brand_name}
      </>
    );
  }
);
const MenuTopBar = React.forwardRef(({ onClick }, ref) => (
  <div 
    ref={ref}
    onClick={(e) => {
    e.preventDefault()
    onClick(e)
  }}>
    <div className={styles.SwitchBtn}>Switch</div>
  </div>
))
const UserTopBarExhibitorList = withTopBarExhibitor(
  ({ exhibitors, noExhibitor, current, onSelect }) => {
    // const userObj = useSelector(state => state.user.obj);
    // NOTE: cause reducer has been dispatched after logged in, we dont need fetch again
    if (noExhibitor)
      return (
        <div className={"mr-3"}>
          <i>No exhibitors</i>
        </div>
      );
    return (
      <Dropdown>
        <Dropdown.Toggle as={MenuTopBar}  />

        <Dropdown.Menu style={{width: "234px", marginTop: "0.7rem"}}>
          <div className={"py-2"}>
            {exhibitors.map((exhibitor) => (
              <div
                className={classnames(
                  styles.ExhibitorItem,
                  exhibitor.id * 1 === current.id * 1 &&
                    styles.ExhibitorItemSelected,
                  !exhibitor.isAvailableToBooking && styles.Unavailable
                )}
                key={exhibitor.id}
                onClick={() => onSelect(exhibitor)}
              >
                <ExhibitorName exhibitor={exhibitor} />
                <ExhibitorBadge role={exhibitor.user_role_on_exh} />
              </div>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
);

export default UserTopBarExhibitorList;
