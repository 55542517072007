import React from "react";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import hoistNonReactStatics from "hoist-non-react-statics";
import { diff } from "deep-object-diff";
import deepAssign from "deep-assign";
import * as PropTypes from "prop-types";
import isEqual from "react-fast-compare";
import pick from "lodash/pick";
import { createValidator } from "./form";
const isFieldDetailDescription = fieldDetail => {
  return (
    typeof fieldDetail === "object" &&
    typeof fieldDetail?.type === "string" &&
    typeof fieldDetail?.value !== "undefined" &&
    fieldDetail.value !== null
  );
};
class SimpleFormHandler extends React.Component {
  /**
   *
   * @type {SimpleReactValidator}
   * @private
   */
  _validator;
  /**
   *
   * @type {{}}
   * @private
   */
  _types = {};
  constructor(props) {
    super(props);
    this._validator = createValidator(this, props.customValidatorProps);
    const entries = props.entries;
    let _entries = false;
    // console.log(entries(props));
    if (typeof entries === "object") _entries = this._extractTypes(entries);
    else if (typeof entries === "function")
      _entries = this._extractTypes(entries(props));
    if (typeof _entries !== "object")
      throw Error("Entries must return object type");
    const _this = this;
    _this.state = {
      form: _entries
    };
    Object.defineProperty(_this._formHandler, "revert", {
      get() {
        return () => {
          if (typeof entries !== "function") {
            console.warn(
              "Not able to revert form, entries must be a function accept prop as first parameter"
            );
          } else _this.setState({ form: entries(_this.props) });
        };
      }
    });
    Object.defineProperties(_this._formBinding, {
      radio: {
        get() {
          return _this._formBindingRadio.bind(_this);
        }
      },
      check: {
        get() {
          return _this._formBindingCheck.bind(_this);
        }
      }
    });
  }

  /**
   *
   * @param entries
   * @returns {{}}
   * @private
   */
  _extractTypes = entries => {
    const newEntries = {};
    for (let field in entries)
      if (entries.hasOwnProperty(field)) {
        if (isFieldDetailDescription(entries[field])) {
          this._types[field] = entries[field].type;
          newEntries[field] = entries[field].value;
        } else newEntries[field] = entries[field];
      }
    // console.log(entries, newEntries);
    // console.log(this._types);
    return newEntries;
  };

  /**
   *
   * @param field
   * @param value
   * @returns {{onChange: (function({target?: *}): *|void), checked: boolean, type: string}}
   * @private
   */
  _formBindingRadio = (field, value) => ({
    type: "radio",
    checked: this.state.form[field] === value,
    onChange: ({ target }) =>
      target &&
      this.setState(state => {
        const form = { ...state.form };
        form[field] = value;
        return { form };
      })
  });

  /**
   *
   * @param field
   * @param value
   * @returns {{onChange: (function({target?: *}): function(*): void), checked: boolean, type: string}}
   * @private
   */
  _formBindingCheck = (field, value) => {
    const isArray = Array.isArray(this.state.form[field]);
    return {
      type: "checkbox",
      checked: isArray
        ? !isEmpty(this.state.form[field].find(o => o === value))
        : this.state.form[field][value] === true,
      onChange: ({ target }) => target =>
        this.setState(state => {
          const form = cloneDeep(state.form);
          if (isArray) {
            if (target.checked) form[field] = [...form[field], value];
            else {
              const index = form[field].find(o => o === value);
              form[field].splice(index, 1);
            }
          } else {
            form[field][value] = target.checked;
          }
          return { form };
        })
    };
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !(isEqual(this.props, nextProps) && isEqual(this.state, nextState));
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const self = this,
      snapshot = {},
      _currentProps = self.props.triggerProps
        ? pick(self.props, self.props.triggerProps)
        : self.props,
      _prevProps = self.props.triggerProps
        ? pick(prevProps, self.props.triggerProps)
        : prevProps;
    const change =
      typeof self.props.entries === "function" &&
      JSON.stringify(diff(_currentProps, _prevProps)) !== "{}";
    // debugger;
    // console.log(self.props.token?.token);
    // console.log(prevProps.token?.token);
    // console.log(change);
    if (change) {
      snapshot.propChangeToForm = self._extractTypes(
        self.props.entries(self.props)
      );
    }
    // if (change) console.log(snapshot.propChangeToForm);
    // console.log(diff(self.state.form, prevState.form));
    if (
      JSON.stringify(
        diff(snapshot.propChangeToForm || self.state.form, prevState.form)
      ) !== "{}"
    ) {
      snapshot.formChange = true;
    }
    return snapshot;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log(Object.keys(snapshot));
    const doChange = () => {
      // console.log(diff(prevState.form, this.state.form));
      if (snapshot.formChange) this.props.onChange(this.state.form);
    };
    if (snapshot.propChangeToForm) {
      process.env.NODE_ENV === "development" &&
        // console.log("withSimpleForm prop changed!", snapshot.propChangeToForm);
      this.setState({ form: snapshot.propChangeToForm }, doChange);
    } else doChange();
  }

  _transformFormField = (field, value) => {
    if (this._types[field]) {
      return {
        number: v => (isNaN(v) ? v : v * 1),
        string: v => v + ""
      }[this._types[field]](value);
    }
    return value;
  };

  /**
   *
   * @param field
   * @param fn
   * @returns {function({target?: *}): *|void}
   * @private
   */
  _formHandler = (field, { fn = null } = {}) => ({ target }) =>
    new Promise(resolve => {
      target &&
        this.setState(state => {
          const form = { ...state.form };
          form[field] =
            typeof fn === "function"
              ? fn(target)
              : this._transformFormField(field, target.value);
          return { form };
        }, resolve);
    });

  /**
   *
   * @param field
   * @param inputField
   * @param inputEvent
   * @param fn
   * @param valueFn
   * @returns {{}}
   * @private
   */
  _formBinding = (
    field,
    {
      inputField = "value",
      inputEvent = "onChange",
      fn = null,
      valueFn = val => val
    } = {}
  ) => ({
    [inputField]: valueFn(this.state.form[field]),
    [inputEvent]: this._formHandler(field, { inputField, inputEvent, fn })
  });

  /**
   *
   * @param obj
   * @private
   */
  _resetForm = obj =>
    this.setState(state => ({
      form: deepAssign({}, state.form, obj)
    }));

  /**
   *
   * @param field
   * @returns {null|undefined|any}
   * @private
   */
  _renderFieldValidator = (field, label = field) => {
    if (typeof this.props.validation?.[field] === "string")
      return this._validator.message(
        label,
        this.state.form[field],
        this.props.validation[field]
      );
    return null;
  };

  /**
   *
   * @private
   */
  _validate = () => {
    if (this._validator.allValid()) return true;
    this._validator.showMessages();
    return false;
  };

  render() {
    const { prefix } = this.props;
    return this.props.children({
      [prefix]: this.state.form,
      [`${prefix}Handler`]: this._formHandler,
      [`${prefix}Binding`]: this._formBinding,
      setForm: this._resetForm,
      validator: this._validator,
      validate: this._validate,
      renderFieldValidator: this._renderFieldValidator
    });
  }
}
SimpleFormHandler.propTypes = {
  entries: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  triggerProps: PropTypes.array,
  validation: PropTypes.object,
  children: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  customValidatorProps: PropTypes.any,
  prefix: PropTypes.string
};
SimpleFormHandler.defaultProps = {
  onChange: function() {},
  triggerProps: null,
  validation: null,
  customValidatorProps: {}
};

const withSimpleForm = ({
  entries,
  triggerProps,
  validation = null,
  customValidatorProps = null,
  prefix = "form"
}) => Component => {
  function WithSimpleForm({ children, ...props }) {
    return (
      <SimpleFormHandler
        {...props}
        entries={entries}
        triggerProps={triggerProps}
        validation={validation}
        customValidatorProps={customValidatorProps}
        prefix={prefix}
      >
        {formProps => (
          <Component {...props} {...formProps}>
            {children}
          </Component>
        )}
      </SimpleFormHandler>
    );
  }

  hoistNonReactStatics(WithSimpleForm, Component);
  return WithSimpleForm;
};

export default withSimpleForm;
export { SimpleFormHandler };

export const entriesTypes = (entries = {}, types = {}) => {
  const newEntries = {};
  for (let field in entries)
    if (entries.hasOwnProperty(field)) {
      const oldInitValue = entries[field];
      if (types[field]) {
        newEntries[field] = {
          type: types[field],
          value: oldInitValue
        };
      } else newEntries[field] = oldInitValue;
    }
  // console.log(newEntries);
  return newEntries;
};
