import React, { useState, useEffect }  from "react";
import styles from "./TopBarDesktop.module.scss";
import styles2 from "./InternalTopBarDesktop.module.scss";
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
// import * as PropTypes from "prop-types";
import Container from '@material-ui/core/Container';
import Navbar from "react-bootstrap/Navbar";
import * as classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import TopBarMenu from "../TopBarMenu";
import UserInfomationTopBar from "../../UserTopBar/UserInfomationTopBar";
import UserTopBar, { InternalUserTopBar } from "../../UserTopBar/UserTopBar";
import { ReactComponent as LogoWhite } from "../../../images/QooeeLogo.svg";
import { ReactComponent as LogoDark } from "../../../images/QooeeLogoAdmin.svg";
import { ReactComponent as HamburgerIconTransparent } from "../../../images/HamburgerIconTransparent.svg";
import { useRole } from "../../../HOCs/withRole";
import { Link } from "react-router-dom";

const Logos = { light: LogoWhite, dark: LogoDark }
const HamburgerIcon = HamburgerIconTransparent

const TopBarDesktop = ({ user, theme, isFullscreen }) => {
  const Logo = Logos[theme],
    [_, urlHelper] = useRole();
  if (isFullscreen === true) return null;
  return (
    <div>
      <div 
        className={styles.supportLine}
        fixed={"top"}
        style={{ zIndex: 100 }}
      >
        <ul className={styles.supportLineLeft}>
          <li className={styles.supportLineItem}>
            <span><PhoneIcon  /></span>
            <span>  Hotline: <u> + 65 123123 12</u></span>
          </li>
          <li className={styles.supportLineItem}>
            <span>Accounting: <u> + 65 123123 11</u></span>
          </li>
          <li className={styles.supportLineItem}>
          <span style={{marginRight: "5px"}}><MailOutlineIcon/></span>
            <span>Email: <u> support@qooee.com</u></span>
          </li>
        </ul>
        <div className={styles.supportLineRight}>
          <UserInfomationTopBar/>
        </div>
      </div>
      <Container maxWidth="xl">
        <Navbar
          className={classnames({
            [styles.NavBar]: true,
            [styles.NavBarDark]: theme === "dark"
          })}
          fixed={"top"}
          style={{ zIndex: 99 , marginTop: "3rem"}}
        >
          <div style={{marginRight: "2rem"}}>
            <Link className={"no-link d-block"} to={urlHelper("/")}>
              <Logo className={styles.Logo} style={{ height: "3rem" }} />
            </Link>
          </div>
          <div className={styles.topBarMenuItem}>
            <ul className={styles.btnHome}>
              <li>
                <Link style={{color :"#333", textDecoration: "none"}} to={"/event/"}>
                  <b className={styles.btnHomeItem}>Home</b>
                </Link>
              </li>
            </ul>
            <TopBarMenu className={styles.TopBarMenu} theme={theme} />
          </div>
          <div className={styles.RightIcon}>
            {user.status.includes("success") && user.obj && <UserTopBar />}
          </div>
        </Navbar>
      </Container>
    </div>
  );
};

export default TopBarDesktop;

export const InternalTopBarDesktop = ({ user, isFullscreen }) => {
  const _NavBar = React.createRef();
  const Logo = Logos[`light`],
    [_, urlHelper] = useRole();

  const setNavBarShadow = () => {
    window.addEventListener('scroll', () => {
      if (_NavBar.current) {
        // console.log(window.pageYOffset, _NavBar.current.offsetHeight)
        // if (window.pageYOffset <= _NavBar.current.offsetHeight) {
        //  1 * 16 == 1rem (paddingTop of appBody)
        if (window.pageYOffset <= (1 * 16)) {
          _NavBar.current.classList.remove(styles2.NavBarShadow)
          return
        }
        _NavBar.current.classList.add(styles2.NavBarShadow)
      }
    })
    // return window.removeEventListener('scroll', () => {})
  }

  useEffect(() => {
    setNavBarShadow()
  })

  if (isFullscreen === true) return null;
  return (
    <Navbar
      className={classnames({
        [styles2.NavBar]: true
      })}
      fixed={"top"}
      style={{ zIndex: 99 }}
      ref={_NavBar}
    >
      <Navbar.Brand>
        <div className={styles2.LeftSection}>
          {/success/.test(user.status) && !isEmpty(user.obj) && (
            <HamburgerIcon className={styles2.HamburgerIcon} />
          )}
          <Link className={"no-link"} to={urlHelper("/")}>
            <Logo className={styles2.Logo} />
          </Link>
        </div>
      </Navbar.Brand>
      <div className={styles2.RightIcon}>
        {user.status.includes("success") && user.obj && <InternalUserTopBar />}
      </div>
    </Navbar>
  )
}