import React from "react";
import styles from "./Selector.module.scss";
import { renderToStaticMarkup } from "react-dom/server";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import Form from "react-bootstrap/Form";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

// reactSvgComponentToMarkupString
export const SelectorSvgIconConverter = (icon = faChevronDown, color = "#ced4da") =>
  `data:image/svg+xml,${encodeURIComponent(
    renderToStaticMarkup(<FontAwesomeIcon icon={icon} color={color || "#ced4da"} />)
  )}`

export const Selector = ({
  className,
  onChange,
  options = [],
  placeholder = `Selector`,
  value,
  ...props
}) => {
  const FormControl = Form.Control,
        [isFocus, setIsFocus] = React.useState(false),
        [anchorEl, setAnchorEl] = React.useState(null),
        [displayedValue, setDisplayedValue] = React.useState(value),
        _Selector = React.useRef(null)

  const openSelector = (e) => {
    setAnchorEl(e.currentTarget)
    setIsFocus(true)
  }
  const setValue = (val, label) => {
    onChange({ target: { value: val } })
    setDisplayedValue(label)
    setAnchorEl(null)
    setIsFocus(false)
  }
  const closeCountrySelector = () => {
    setAnchorEl(null)
    setIsFocus(false)
  }

  React.useEffect(() => {
    setDisplayedValue(() => {
      if (!value) {
        return ``
      }
      return value
    })
  }, [value])

  return (
    <>
      <FormControl
        ref={_Selector}
        className={`${className} ${styles.Selector} ${isFocus && styles.SelectorFocused} form-control`}
        custom
        readOnly
        style={{
            backgroundImage:
                `url(${SelectorSvgIconConverter(faChevronDown, isFocus && "#727476")})`,
            paddingRight: !displayedValue ? `2rem` : ``
        }}
        onFocus={e => {e.target.blur()}}
        // onBlur={closeCountrySelector}
        onClick={e => openSelector(e)}
        value={displayedValue}
        placeholder={placeholder}
        // defaultValue={value}
        {...props}
      >
      </FormControl>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeCountrySelector}
      >
        {options.map((opt, index) => (
            <MenuItem
                key={index}
                value={opt.value}
                selected={opt.value === value}
                onClick={() => setValue(opt.value, opt.label)}
            >
                {opt.label}
            </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default Selector