import React from "react";
import "../../App.scss";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './Footer.module.scss'

// @withTranslation()
const Footer = props => {
  // const items = [
  //   {
  //     title: "Home",
  //     href: "/"
  //   },
  //   {
  //     title: "About",
  //     href: "/about-us"
  //   },
  //   {
  //     title: "Privacy",
  //     href: "/privacy"
  //   },
  //   {
  //     title: "Contact",
  //     href: "/contact-us"
  //   }
  // ];

  return ( null
    // <Container className="mt-4">
    //   {/* <Grid item xs={12}>
    //     <Grid container justify="center" spacing={2}>
    //       {items.map(item => (
    //         <Grid item>
    //           <Link to={item.href} className={"no-link"}>
    //             {" "}
    //             <h6 className={"d-inline-block mr-1 text-bold text-grey"}>
    //               {item.title}
    //             </h6>
    //           </Link>
    //         </Grid>
    //       ))}
    //     </Grid>
    //   </Grid> */}
      
    // </Container>
    // <Grid>
    //   <Grid className={styles.FooterContainer}>
    //     <small className={styles.ContainerItem}>
    //       Copyright ©2020 Adi Media Pte Ltd. All rights reserved.
    //     </small>
    //   </Grid>
    // </Grid>
  );
};

export default Footer;

export const InternalFooter = () => {
  return ( null
    // <div className={`AppFooter`}>
    //   <Row>
    //     <Col xl={12} xs={12}>
    //       <small className="text-grey">
    //         Copyright ©{new Date().getFullYear()} Adi Media Pte Ltd. All rights reserved.
    //       </small>
    //     </Col>
    //   </Row>
    // </div>
  )
}