import React from "react";
import Breadcrumb, { InternalBreadcrumb } from "./Breadcrumb";

const withBreadcrumb = propFn => Component => props => {
  return (
    <>
      <Breadcrumb className={"pt-4 px-5"} breadcrumbs={propFn(props)} />
      <Component {...props} />
    </>
  )
}

export default withBreadcrumb

export const withInternalBreadcrumb = propFn => Component => props => {
  return (
    <>
      <InternalBreadcrumb className={``} breadcrumbs={propFn(props)} />
      <Component {...props} />
    </>
  )
}