import {
  BOOKING_ACTION_ADD_ORDER,
  BOOKING_ACTION_ADD_ORDER_ERROR,
  BOOKING_ACTION_ADD_ORDER_SUCCESS,
  BOOKING_ACTION_ADD_TO_CART,
  BOOKING_ACTION_FETCH_CART,
  BOOKING_ACTION_FETCH_CART_ERROR,
  BOOKING_ACTION_FETCH_ORDER,
  BOOKING_ACTION_FETCH_ORDER_SUCCESS,
  BOOKING_ACTION_FETCHED_CART,
  BOOKING_ACTION_REMOVE_ALL_FROM_CART,
  BOOKING_ACTION_REMOVE_FROM_CART
} from "./const";
import { ERROR_ACTION, WAIT_FOR_ACTION } from "redux-wait-for-action";

export const BookingActionFetchCart = () => ({
  type: BOOKING_ACTION_FETCH_CART,
  // eventId,
  [WAIT_FOR_ACTION]: BOOKING_ACTION_FETCHED_CART
});

export const BookingActionAddToCart = (eventId, boothId) => ({
  type: BOOKING_ACTION_ADD_TO_CART,
  eventId,
  boothId,
  [WAIT_FOR_ACTION]: BOOKING_ACTION_FETCHED_CART,
  [ERROR_ACTION]: BOOKING_ACTION_FETCH_CART_ERROR
});

export const BookingActionRemoveFromCart = (eventId, cartItemId) => ({
  type: BOOKING_ACTION_REMOVE_FROM_CART,
  eventId,
  cartItemId,
  [WAIT_FOR_ACTION]: BOOKING_ACTION_FETCHED_CART
});
export const BookingActionClearCart = (eventId, cartItemIds) => ({
  type: BOOKING_ACTION_REMOVE_ALL_FROM_CART,
  eventId,
  cartItemIds,
  [WAIT_FOR_ACTION]: BOOKING_ACTION_FETCHED_CART
});

export const BookingActionAddOrder = (
  cartId,
  payment_method,
  description = ""
) => ({
  type: BOOKING_ACTION_ADD_ORDER,
  cartId,
  paymentMethod: payment_method,
  description,
  [WAIT_FOR_ACTION]: BOOKING_ACTION_ADD_ORDER_SUCCESS,
  [ERROR_ACTION]: BOOKING_ACTION_ADD_ORDER_ERROR
});

export const BookingActionFetchOrders = () => ({
  type: BOOKING_ACTION_FETCH_ORDER,
  [WAIT_FOR_ACTION]: BOOKING_ACTION_FETCH_ORDER_SUCCESS
});
