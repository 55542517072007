import { combineRootSagas, takeLeading } from "../../utils/store";
import UserActionTypes, {
  USER_ACTION_LOGIN,
  USER_ACTION_LOGIN_SUCCESS,
  USER_ACTION_REG_SUBMIT,
  USER_ACTION_REG_SUBMIT_SUCCESS
} from "./const";
import { axiosApi } from "../../utils/api";
import { call, put, delay } from "@redux-saga/core/effects";

const _loginSuccess = userInfo => ({
    type: USER_ACTION_LOGIN_SUCCESS,
    ...userInfo
  }),
  _loginError = ({ err }) => ({ type: UserActionTypes.LOGIN_ERROR, err });

function* login() {
  yield takeLeading(USER_ACTION_LOGIN, function*({ email, password, isAdmin }) {
    const { status, data } = yield call(
      axiosApi.post,
      "/v1/u/i/login",
      { email, password }
    );
    if (status === 200) {
      yield put(
        _loginSuccess({
          authentication: { email, password, isAdmin },
          obj: data
        })
      );
    } else yield put(_loginError(data));
  });
}
function* registerSubmit() {
  yield takeLeading(USER_ACTION_REG_SUBMIT, function*({ form }) {
    const { status, data } = yield call(axiosApi.post, "/v1/u/account", form);
    if (status === 200 && data.success === true) {
      yield put({ type: USER_ACTION_REG_SUBMIT_SUCCESS });
      yield delay(500);
      yield put({
        type: USER_ACTION_LOGIN,
        email: data.user.email,
        password: form.password,
        isAdmin: false
      });
    }
  });
}

export default function* userSaga() {
  yield combineRootSagas(login, registerSubmit);
}
