import React from "react";
import styles from "./MobileBottomNavigator.module.scss";
// import * as PropTypes from "prop-types";
import classnames from "classnames";

const MobileBottomNavigator = ({ children }) => (
  <div
    className={classnames(
      styles.Container,
      React.Children.count(children) > 1 && styles.Multiple
    )}
  >
    {children}
  </div>
);

export default MobileBottomNavigator;
