import * as propTypes from "prop-types";
import { useSelector } from "react-redux";

const ROLES = ["USER", "FINANCE", "OPERATIONS", "ADMIN"];

const PrivilegedComponent = ({ role, children }) => {
  const user = useSelector(state => state.user);
  let _role = [role];
  if (role.includes(":")) _role = _role[0].split(":");
  if (role.includes("+")) {
    _role = ROLES.slice(
      ROLES.findIndex(r => r === role.substr(0, role.length - 1))
    );
  }
  if (typeof user.obj === "object") {
    if (_role.findIndex(r => r === user.obj.role) >= 0)
      return typeof children === "function" ? children() : children;
    return null;
  }
  return null;
};

PrivilegedComponent.propTypes = {
  role: propTypes.string.isRequired
};

export default PrivilegedComponent;
