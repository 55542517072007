const BoothTypeActions = {
  FETCH: "boothType/fetch",
  ADD_BOOTHTYPE: "boothType/addBoothType"
};
export default BoothTypeActions;

export const BOOTHTYPE_ACTION_ADMIN_FETCH = "boothType/fetch";
export const BOOTHTYPE_ACTION_ADMIN_FETCHED = "boothType/fetched";
export const BOOTHTYPE_ACTION_USER_FETCH = "boothType/user-fetch";
export const BOOTHTYPE_ACTION_USER_FETCHED = "boothType/user-fetched";
export const BOOTHTYPE_ACTION_DELETE = "boothType/delete";
export const BOOTHTYPE_ACTION_DELETED = "boothType/deleted";
export const BOOTHTYPE_ACTION_UPDATE = "boothType/update";
export const BOOTHTYPE_ACTION_EX_STATUS_FETCH = "boothType/ex-status-fetch";
export const BOOTHTYPE_ACTION_EX_STATUS_FETCHED = "boothType/ex-status-fetched";
export const BOOTHTYPE_ACTION_EX_STATUS_EDIT = "boothType/ex-status-edit";
export const BOOTHTYPE_ACTION_ADD = "boothType/add";
export const BOOTHTYPE_ACTION_ADD_SUCCESS = "boothType/add-success";

export const BOOTHTYPE_ACTION_ERROR = "boothType/error";
