import { createReducer } from "../../utils/store";
import BoothTypeActions, {
  BOOTHTYPE_ACTION_DELETED,
  BOOTHTYPE_ACTION_EX_STATUS_FETCHED,
  BOOTHTYPE_ACTION_ADMIN_FETCHED,
  BOOTHTYPE_ACTION_USER_FETCHED,
  BOOTHTYPE_ACTION_ADD_SUCCESS
} from "./const";
import { produce } from "immer";

const postDraft = (draft, list = false) => {
  const setMap = obj => {
    if (list) obj.list = Object.values(obj.map);
    else
      obj.map = obj.list.reduce(
        (acc, val) => Object.assign({}, acc, { [val.id]: val }),
        {}
      );
  };
  setMap(draft.booth);
  setMap(draft.exhibitor);
  return draft;
};

export default createReducer(
  {
    booth: { list: [], map: {} },
    exhibitor: { status: "fetching", list: [], map: {} }
  },
  {
    [BOOTHTYPE_ACTION_EX_STATUS_FETCHED]: (state, { exhibitor }) =>
      produce(state, draft => {
        draft.exhibitor.status = "fetched";
        draft.exhibitor.list = exhibitor;
        return postDraft(draft);
      }),
    [BOOTHTYPE_ACTION_ADMIN_FETCHED]: (state, { booth }) =>
      produce(state, draft => {
        draft.booth.list = booth;
        return postDraft(draft);
      }),
    [BOOTHTYPE_ACTION_USER_FETCHED]: (state, { booth }) =>
      produce(state, draft => {
        draft.booth.list.push(...booth);
        return postDraft(draft);
      }),
    [BOOTHTYPE_ACTION_ADD_SUCCESS]: (state, { boothType }) =>
      produce(state, draft => {
        draft.booth.list.push(boothType);
        return postDraft(draft);
      }),
    [BOOTHTYPE_ACTION_DELETED]: (state, { id }) =>
      produce(state, draft => {
        delete draft.booth.map[id];
        return postDraft(draft, true);
      })
  }
);
