import { createReducer } from "../../utils/store";
import EventActionType, { EVENT_ACTION_ADD, EVENT_ACTION_ADDED } from "./const";
import update from "react-addons-update";
// import zip from "lodash/zip";

const _setStatus = (object, status) => ({ [object]: { $set: status } });

const event = createReducer(
  { status: { list: "empty" }, list: {} },
  {
    [EventActionType.FETCHING]: state =>
      update(state, {
        status: _setStatus("list", "fetching")
      }),
    [EventActionType.FETCHED]: (state, { list = [] }) =>
      update(state, {
        status: {
          $set: {
            list: "fetched",
            ...Object.fromEntries(list.map(item => [item.id, "fetched"]))
          }
        },
        list: { $set: Object.fromEntries(list.map(item => [item.id, item])) }
      }),
    [EVENT_ACTION_ADD]: state =>
      update(state, {
        status: _setStatus("__add", "adding")
      }),
    [EVENT_ACTION_ADDED]: (state, { item }) => {
      const { id } = item;
      return update(state, {
        status: {
          __add: { $set: "added" },
          [id]: { $set: "added" }
        },
        list: { [id]: { $set: item } }
      });
    },
    [EventActionType.UPDATING]: (state, { id }) =>
      update(state, {
        status: _setStatus(id, "updating")
      }),
    [EventActionType.ONE_FETCHING]: (state, { id }) =>
      update(state, { status: _setStatus(id, "fetching") }),
    [EventActionType.ONE_FETCHED]: (state, { item }) => {
      const { id } = item;
      return update(state, {
        status: _setStatus(id, "fetched"),
        list: { [id]: { $set: item } }
      });
    }
  }
);

export default event;
