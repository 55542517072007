import React from "react";
// import { withTopBarContext } from "../../utils/TopBarContext";
import { Redirect, withRouter } from "react-router";
import withSimpleForm from "../../utils/SimpleForm";
import { connect } from "react-redux";
import { createValidator } from "../../utils/form";
import {
  UserActionRegister,
  UserActionRegisterComplete
} from "../../store/user/actions";

const withRegister = Component => {
  @withRouter
  @withSimpleForm({ entries: { email: "" } })
  @connect(state => ({
    user: state.user
  }))
  class WithExternalRegister extends React.Component {
    /**
     * @private
     * @type {SimpleReactValidator}
     */
    _validation = null;
    constructor(props) {
      super(props);
      this.state = {
        agree: false,
        step: props.location.pathname === "/register/continue" ? 2 : 0,
        error: null
      };

      this._validation = createValidator(this);
    }

    /**
     *
     * @returns {Promise<unknown>}
     * @private
     */
    _doRegister = () =>
      new Promise((resolve, reject) => {
        if (!this._validation.allValid()) {
          this._validation.showMessages();
          reject();
          return;
        }
        this.props
          .dispatch(UserActionRegister(this.props.form))
          .then(resolve)
          .catch(reject);
      });

    /**
     *
     * @param form
     * @returns {Promise<unknown>}
     * @private
     */
    _doRegisterComplete = form =>
      new Promise((resolve, reject) => {
        this.props
          .dispatch(UserActionRegisterComplete(form))
          .then(resolve)
          .catch(reject);
      });

    render() {
      if (this.props.user.status === "register-success")
        return <Redirect to={"/login"} />;
      return (
        <Component
          pageActions={{
            validator: this._validation,
            doRegister: this._doRegister,
            doRegisterComplete: this._doRegisterComplete
          }}
          {...this.props}
        />
      );
    }
  }

  return WithExternalRegister;
};

export default withRegister;
