import React from "react";
import styles from "./InternalRegister.module.scss";
import { withTopBarContext } from "../../utils/TopBarContext";
import withSimpleForm from "../../utils/SimpleForm";
import Form from "react-bootstrap/Form";
import ReactSelect from "../../components/UI/ReactSelect/ReactSelect";
import Selector from "../../components/UI/Selector/Selector";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import { axiosApi, createTimestamp } from "../../utils/api";
import { Redirect, withRouter } from "react-router";
import InternalRegisterComplete from "./InternalRegisterComplete";
import { createValidator } from "../../utils/form";
import AlertDismissable from "../../components/AlertDismissable/AlertDismissable";
import withTitle from "../../components/Title/withTitle";
import { withInternalBreadcrumb } from "../../components/Breadcrumb/withBreadcrumb";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageTitle, { CardTitle } from "../../components/UI/PageTitle/PageTitle";

const roles = [
  { value: "ADMIN", label: "Admin" },
  { value: "FINANCE", label: "Finance" },
  { value: "OPERATIONS", label: "Operations" }
]

const _parent = {
  title: `Users Management`,
  to: `/users`
},
  _title = `Register`,
  _header = `Create new user`

@withTopBarContext()
@withSimpleForm({
  entries: {
    first_name: "",
    last_name: "",
    email: "",
    role: null
  }
})
@connect(state => ({
  user: state.user
}))
@withRouter
@withTitle({ title: _title })
@withInternalBreadcrumb(() => [_parent, { title: _title, header: _title }])
class InternalRegister extends React.Component {
  /**
   * @private
   */
  _validator;
  state = {
    consent: false,
    return: false,
    error: null
  };

  constructor(props) {
    super(props);
    this._validator = createValidator(this);
  }

  // componentDidMount() {
  // if (this.props.user.obj)
  // this.props.topBarContext.setRightButton(() => <UserTopBar />);
  // }

  static getDerivedStateFromProps(props) {
    if (props.location.pathname.includes("/continue")) {
      const search = new URLSearchParams(props.location.search),
        token = search.get("token").replace(/\s/g, "+");
      return { continue: true, token };
    }

    return {};
  }

  /**
   * @private
   * */
  doCreateNewUser = async () => {
    // validation
    if (!this._validator.allValid()) {
      this._validator.showMessages();
      return;
    }

    // send request
    const form = { ...this.props.form };
    form.role = form.role.value;

    const { data } = await axiosApi
      .auth(this.props.user)
      .post("/v1/u/i/create-internal-user?ts=" + createTimestamp(), form);
    if (data.err) {
      this.setState({ error: data.err.message });
    } else this.setState({ consent: true });
  };

  /**
   * @private
   * */
  doSetPassword = form => {
    return axiosApi.post("/v1/u/i/account", form);
  };

  /**
   * @private
   * */
  renderInternalRegisterConsent = () => (
    <>
      <div className={styles.Form}>
        <h1 className={"mb-5"} style={{ fontWeight: "600" }}>
          Send email verification completed
        </h1>
        <p>
          Now you can notify user to check his/her email for register
          completion.
        </p>
      </div>
    </>
  );

  /**
   * @private
   * */
  renderInternalRegistrationContinue = () => (
    <InternalRegisterComplete
      token={this.state.token}
      onDoSetPassword={form => this.doSetPassword(form)}
      onComplete={() => this.setState({ return: true })}
    />
  );

  render() {
    if (this.state.return) return <Redirect to={"/internal/login?force"} />;
    if (this.state.continue) return this.renderInternalRegistrationContinue();
    if (this.state.consent === true)
      return this.renderInternalRegisterConsent();
    return (
      <div className={`AppBody`}>
        <Card className={`Card`}>
          <Card.Header className={`CardHeader`}>
            <Row>
              <Col lg={8}>
                <CardTitle>
                  Create new User
                </CardTitle>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className={`BgLight`}>
            <Form style={{ minWidth: "70%" }} className="mx-auto">
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type={"text"}
                    // placeholder={"First Name"}
                    {...this.props.formBinding("first_name")}
                  />
                  {this._validator.message(
                    "First name",
                    this.props.form.first_name,
                    "required"
                  )}
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type={"text"}
                    // placeholder={"Last Name"}
                    {...this.props.formBinding("last_name")}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type={"text"}
                  // placeholder={"Email"}
                  {...this.props.formBinding("email")}
                />
                {this._validator.message(
                  "Email",
                  this.props.form.email,
                  "required|email"
                )}
              </Form.Group>
              <Form.Group>
                <Form.Label>User role</Form.Label>
                <Selector
                  // name={"foo"}
                  placeholder={"User Role"}
                  options={roles}
                  onChange={val =>
                    this.props.formHandler("role")({ target: { value: val } })
                  }
                />
                {this._validator.message(
                  "Role",
                  this.props.form.role,
                  "required"
                )}
              </Form.Group>
              <Form.Group className="mb-0">
                <div className="d-flex flex-row-reverse">
                  <Button
                    size={"lg"}
                    variant={"primary"}
                    onClick={() => this.doCreateNewUser()}
                    className="mt-3"
                  >
                    Create new user
                  </Button>
                </div>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </div>
      // <div className="p-5">
      //   <h2 className={"mb-1"} style={{ fontWeight: "600" }}>
      //     Create new User
      //   </h2>
      //   {this.state.error && (
      //     <AlertDismissable variant={"danger"}>
      //       {this.state.error}
      //     </AlertDismissable>
      //   )}
      //   <div className={"d-flex align-items-center mb-5"}>
          // <Form style={{ minWidth: "70%" }} className="mx-auto">
          //   <Form.Group></Form.Group>
          //   <Form.Group>
          //     <Form.Control
          //       type={"text"}
          //       placeholder={"First Name"}
          //       {...this.props.formBinding("first_name")}
          //       size={"lg"}
          //     />
          //     {this._validator.message(
          //       "First name",
          //       this.props.form.first_name,
          //       "required"
          //     )}
          //   </Form.Group>
          //   <Form.Group>
          //     <Form.Control
          //       type={"text"}
          //       placeholder={"Last Name"}
          //       {...this.props.formBinding("last_name")}
          //       size={"lg"}
          //     />
          //   </Form.Group>
          //   <Form.Group>
          //     <Form.Control
          //       type={"text"}
          //       placeholder={"Email"}
          //       {...this.props.formBinding("email")}
          //       size={"lg"}
          //     />
          //     {this._validator.message(
          //       "Email",
          //       this.props.form.email,
          //       "required|email"
          //     )}
          //   </Form.Group>
          //   <Form.Group>
          //     <ReactSelect
          //       size={"lg"}
          //       name={"foo"}
          //       placeholder={"User Role"}
          //       options={roles}
          //       onChange={val =>
          //         this.props.formHandler("role")({ target: { value: val } })
          //       }
          //     />
          //     {this._validator.message(
          //       "Role",
          //       this.props.form.role,
          //       "required"
          //     )}
          //   </Form.Group>
          //   <Form.Group>
          //     <div className="d-flex flex-row-reverse">
          //       <Button
          //         size={"lg"}
          //         variant={"primary"}
          //         onClick={() => this.doCreateNewUser()}
          //       >
          //         Create new user
          //       </Button>
          //     </div>
          //   </Form.Group>
          // </Form>
      //   </div>
      // </div>
    );
  }
}

export default InternalRegister;
