import React from "react";
import styles from "./LoadingAnimationPage.module.scss";
import * as PropTypes from "prop-types";
import { ReactComponent as Spinning } from "../../../images/anim/Spinning2.svg";
import Transition from "react-transition-group/Transition";

const transitionStates = {
  entering: { opacity: 0, display: "block" },
  entered: { opacity: 1, display: "block" },
  exiting: { opacity: 0, display: "block" },
  exited: { opacity: 0, display: "none" }
};

class LoadingAnimationPage extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired
  };
  state = {
    el: null
  };
  /**
   *
   * @private
   */
  _attachRelativeToParent = () => {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.el.parentElement.style.position = "relative";
  };
  shouldComponentUpdate(nextProps) {
    return nextProps.loading !== this.props.loading;
  }

  render() {
    const self = this;
    return (
      <Transition in={self.props.loading} timeout={125}>
        {animState => (
          <div
            data-state={animState}
            style={transitionStates[animState]}
            className={styles.LoadingAnimationPage}
            ref={el =>
              self.setState({ el }, self._attachRelativeToParent.bind(self))
            }
          >
            <div className="d-flex align-items-center justify-content-center">
              <Spinning />
            </div>
          </div>
        )}
      </Transition>
    );
  }
}

export default LoadingAnimationPage;
