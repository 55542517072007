import React from "react";
import styles from "./ExternalUsersManagement.module.scss";
import * as classnames from "classnames";
import moment from "moment";
import get from "lodash/get";
import { withTopBarContext } from "../../utils/TopBarContext";
// import UserTopBar from "../../components/UserTopBar/UserTopBar";
import Table from "../../components/Table/Table";
import BsNavPills from "../../components/UI/BsNavPills/BsNavPills";
import { connect } from "react-redux";
import { adminFetchIUsers } from "../../store/admin/actions";
import Switch from "react-switch";
import { USER_STATUS } from "../../utils/constant";
import { axiosApi } from "../../utils/api";
import PageTitle, { CardTitle } from "../../components/UI/PageTitle/PageTitle";
// import Button from "react-bootstrap/Button";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router";
// import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons/faCloudDownloadAlt";
import { parseUserStatus } from "../../utils/common";
import withToast from "../../HOCs/withToast";
import { ExhSelectorGetExhibitorOfUser } from "../../store/exhibitor/selectors";
import groupBy from "lodash/groupBy";
import { Link } from "react-router-dom";
import Select from "react-select";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import withTitle from "../../components/Title/withTitle";
import { withInternalBreadcrumb } from "../../components/Breadcrumb/withBreadcrumb";

const _title = `Users Management`

@withTopBarContext()
@connect(state => ({
  adminData: state.admin,
  user: state.user,
  exhibitors: groupBy(ExhSelectorGetExhibitorOfUser(state), "email")
}))
@withRouter
@withToast
@withTitle({ title: _title })
@withInternalBreadcrumb(() => [{ title: _title }])
class ExternalUsersManagement extends React.Component {
  state = {
    role: "a"
  };

  componentDidMount() {
    // this.props.topBarContext.setRightButton(() => <UserTopBar />);
    this.props.dispatch(adminFetchIUsers);
  }

  /**
   *
   * @param id
   * @param status
   * @private
   */
  _onModifyUserStatus(id, status) {
    const endpointApp = status === USER_STATUS.ACTIVE ? "disable" : "enable";

    axiosApi
      .auth()
      .put(`/v1/a/u/${id}/${endpointApp}`, {})
      .then(updatedStatusRes => {
        if (updatedStatusRes.data && updatedStatusRes.data.success) {
          this.props.toastSuccess(
            `Enable/Disable user successfully. Primary User ID ${updatedStatusRes.data?.user}`
          );
          this.props.dispatch(adminFetchIUsers);
        } else {
          const msg = get(updatedStatusRes, "data.err.message");
          this.props.toastError(
            `Enable/Disable user unsuccessfully. Message: ${msg}`
          );
        }
      });
  }

  setThisState = (value) => {
    this.setState({ role: value })
  }

  /**
   *
   * @returns {({name: string, label: string}|{name: string, options: {customBodyRender: (function(*): *)}, label: string}|{name: string, label: string}|{name: string, label: string}|{name: string, options: {customBodyRender: (function(*=): string)}, label: string})[]}
   * @private
   */
  get _columns() {
    const columns = [
      {
        name: "id",
        label: "User Id"
      },
      {
        name: "email",
        label: "Email",
        options: {
          customBodyRender: email => (
            <span
              className={classnames(styles.PointerCursor, "text-primary")}
              onClick={() =>
                this.props.history.push(`/internal/user-info/${email}`)
              }
            >
              <u>{email}</u>
            </span>
          )
        }
      },
      {
        name: "last_login",
        label: "Last Login",
        options: {
          customBodyRender: v => v ? moment(v).format("DD-MM-YYYY HH:mm") : ""
        }
      },
      {
        name: "name",
        label: "Name"
      },
      {
        name: "mobile",
        label: "Mobile Number"
      },
      {
        name: "email",
        label: "Exhibitors",
        options: {
          customBodyRender: email => (
            <div>
              {this.props.exhibitors[email]?.map(exhibitor => (
                <Link
                  to={`/internal/exhibitor/detail/${exhibitor.id}?tab=0`}
                  className={"d-block"}
                >
                  {exhibitor.brand_name}
                </Link>
              ))}
            </div>
          )
        }
      },
      {
        name: "role",
        label: "Role"
      },
      {
        name: "status_value",
        label: "Status",
        options: {
          customBodyRender: status => parseUserStatus(status)
        }
      },
      {
        name: "created_at",
        label: "Created Date",
        options: {
          customBodyRender: v => moment(v).format("DD/MM/YYYY")
        }
      },
      {
        name: "updated_at",
        label: "Modified Date",
        options: {
          customBodyRender: v => moment(v).format("DD/MM/YYYY")
        }
      },
      {
        name: "status",
        label: "Disable",
        options: {
          customBodyRender: ({ id, status }) => (
            <Switch
              checkedIcon={false}
              uncheckedIcon={false}
              onChange={() => this._onModifyUserStatus(id, status)}
              checked={status === USER_STATUS.ACTIVE}
            />
          )
        }
      }
    ];

    return columns;
  }

  render() {
    const {
      adminData: { eUsers, status }
    } = this.props;

    const options = [
      { value: "a", label: "All Users" },
      { value: "i", label: "Internal" },
      { value: "e", label: "External" }
    ];
    // console.log(eUsers);
    const jsUsers = Array.isArray(eUsers)
      ? eUsers
          .filter(({ role }) => {
            if (this.state.role === "i") return role !== "USER";
            if (this.state.role === "e") return role === "USER";
            return true;
          })
          .map(u => ({
            ...u,
            name: `${u.first_name} ${u.last_name}`,
            status: { id: u.id, status: u.status },
            status_value: u.status,
            mobile: `${u.mobile}`
          }))
      : [];

    const isLoading = status === "fetch-internal-user-pending";
    //console.log("eUsers", isLoading, eUsers);

    return (
      <div className={`AppBody`}>
        <Card className={`Card`}>
          <Card.Header className={`CardHeader`}>
            {/* <Row>
              <Col lg={8}>
                <CardTitle>
                  List of users
                </CardTitle>
              </Col>
            </Row> */}
            <Row className={`justify-content-end`}>
              <Col xl={`auto`} xs={`auto`}>
                <BsNavPills
                  options={options}
                  defaultValue={options[0]}
                  value={options[0]}
                  setActiveState={this.setThisState}
                />
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col lg={12}>
                <Table isLoading={isLoading} data={jsUsers} columns={this._columns} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default ExternalUsersManagement;
