import React from "react";
import * as PropTypes from "prop-types";
import styles from "./InternalRegister.module.scss";
import Form from "react-bootstrap/Form";
import PasswordInput from "../../components/UI/PasswordInput/PasswordInput";
import withSimpleForm from "../../utils/SimpleForm";
import { appValidators, createValidator } from "../../utils/form";
// import Button from "react-bootstrap/Button";
import LoginBackground from "../../fragments/LoginBackground/LoginBackground";
import { ReactComponent as QooeeLogo } from "../../images/QooeeLogoAdminDark.svg";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

@withSimpleForm({
  entries: props => ({
    token: props.token,
    password: "",
    confirmPassword: ""
  })
})
class InternalRegisterComplete extends React.Component {
  /**
   * @private
   */
  _validator;
  state = {
    pwdSet: false
  };

  constructor(props) {
    super(props);

    this._validator = createValidator(this, {
      validators: {
        password: appValidators.password
      }
    });
  }

  /**
   * @private
   * */
  doSetPassword = () => {
    if (this._validator.allValid()) {
      this.props
        .onDoSetPassword(this.props.form)
        .then(() => this.setState({ pwdSet: true }));
    } else {
      this._validator.showMessages();
    }
  };

  render() {
    return (
      <div>
        <div
          className="mx-auto mt-5"
          style={{ backgroundColor: "white !important" }}
        >
          <Container>
            <Grid container spacing={0}>
              <Grid item xs>
                <QooeeLogo style={{ margin: "3rem", marginTop: "10rem" }} />
              </Grid>
              <Grid item xs>
                <Form className={styles.Form}>
                  <Form.Group>
                    <h1 className={"mb-1"} style={{ fontWeight: "600" }}>
                      {"Final step."}
                    </h1>
                    <h5 className={"text-grey"} style={{ marginBottom: 40 }}>
                      Complete your registration below.
                    </h5>
                  </Form.Group>

                  <Form.Group>
                    <PasswordInput
                      size={"lg"}
                      placeholder={"Password"}
                      {...this.props.formBinding("password")}
                    >
                      {this._validator.message(
                        "Password",
                        this.props.form.password,
                        "required|password"
                      )}
                    </PasswordInput>
                  </Form.Group>
                  <Form.Group>
                    <PasswordInput
                      size={"lg"}
                      placeholder={"Confirm Password"}
                      {...this.props.formBinding("confirmPassword")}
                    >
                      {[
                        this._validator.message(
                          "Confirm Password",
                          this.props.form.confirmPassword,
                          "required|password"
                        ),
                        this._validator.message(
                          "",
                          this.props.form.confirmPassword ===
                            this.props.form.password,
                          "accepted",
                          {
                            messages: {
                              default:
                                "Confirm password doesn't match password."
                            }
                          }
                        )
                      ]}
                    </PasswordInput>
                  </Form.Group>
                  <Form.Group>
                    <div className="d-flex flex-row-reverse">
                      <Button
                        size={"lg"}
                        variant={"primary"}
                        className="w-100"
                        onClick={() =>
                          this.state.pwdSet
                            ? this.props.onComplete()
                            : this.doSetPassword()
                        }
                      >
                        {this.state.pwdSet
                          ? "Password has been set. Return to login page"
                          : "Set my password"}
                      </Button>
                    </div>
                  </Form.Group>
                </Form>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    );
  }
}

InternalRegisterComplete.propTypes = {
  token: PropTypes.string.isRequired,
  onDoSetPassword: PropTypes.func.isRequired,
  onComplete: PropTypes.func
};

export default InternalRegisterComplete;
