import {
  all,
  fork,
  take,
  getContext,
  setContext
} from "@redux-saga/core/effects";
import userSaga from "./user/sagas";
import boothTypeSagas from "./boothType/sagas";
import eventSagas from "./event/sagas";
import boothSagas from "./booth/sagas";
import exhibitorSagas from "./exhibitor/sagas";
import { USER_ACTION_LOGIN_SUCCESS } from "./user/const";
import { REHYDRATE } from "redux-persist/lib/constants";
import licencesSaga from "./licences/sagas";
// import memoize from "lodash/memoize";
// import apiSaga from "./api/sagas";

export default function* rootSagas() {
  yield all([
    // apiSaga(),
    userSaga(),
    boothTypeSagas(),
    eventSagas(),
    boothSagas(),
    exhibitorSagas(),
    licencesSaga()
  ]);
}

export function createSagaInjector(runSaga, rootSaga) {
  // Create a dictionary to keep track of injected sagas
  const injectedSagas = new Map();

  const isInjected = key => {
    if (injectedSagas.has(key)) return injectedSagas.get(key);
    return null;
  };

  const injectSaga = (key, saga) => {
    process.env.NODE_ENV === "development" &&
      console.log(`saga ${key} injected and runned`);
    // We won't run saga if it is already injected
    const oldTask = isInjected(key);
    if (oldTask) oldTask.cancel();

    // Sagas return task when they executed, which can be used
    // to cancel them
    const task = runSaga(saga);

    // Save the task if we want to cancel it in the future
    injectedSagas.set(key, task);
  };

  // Inject the root saga as it a statically loaded file,
  injectSaga("root", rootSaga);

  return injectSaga;
}

export const takeLoginAction = (isAdmin, saga, ...args) =>
  fork(function*() {
    while (true) {
      const action = yield take([
        action =>
          action.type === USER_ACTION_LOGIN_SUCCESS &&
          (isAdmin !== null ? action.authentication.isAdmin === isAdmin : true),
        action =>
          action.type === REHYDRATE &&
          action.key === "user" &&
          action.payload?.status === "login-success" &&
          (isAdmin !== null
            ? action.payload?.authentication.isAdmin === isAdmin
            : true)
      ]);
      const p = isAdmin === null ? "all" : isAdmin === true ? "admin" : "user",
        isPersisted = yield getContext(p);
      if (!(isPersisted && action.type === REHYDRATE)) {
        yield setContext({ [p]: true });
        yield fork(saga, ...args.concat(action));
      }
    }
  });
