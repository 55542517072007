import React from "react";
import { GetUserRoles } from "../store/user/selectors";
import { connect, useSelector } from "react-redux";

const withRole = Component =>
  connect(state =>
    state.user.status === "login-success"
      ? { role: GetUserRoles(state), user: state.user.obj, loginSuccess: true }
      : { role: null, user: null, loginSuccess: false }
  )(function _WithRole({ role, ...props }) {
    return (
      <Component
        role={role}
        urlHelper={url => (role?.isNotClient ? "/internal" + url : url)}
        {...props}
      />
    );
  });

export default withRole;

export const useRole = () => {
  const role = useSelector(GetUserRoles);
  if (role === null) return [null, url => url];
  return [role, url => (role?.isNotClient ? "/internal" + url : url)];
};
