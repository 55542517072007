import { createReducer, persistStorageSelect } from "../../utils/store";
import UserActionTypes, {
  USER_ACTION_LOGIN_BEFORE,
  USER_ACTION_LOGIN_SUCCESS,
  USER_ACTION_REG_SUBMIT_SUCCESS
} from "./const";
import { persistReducer, createTransform } from "redux-persist";
import AES from "crypto-js/aes";
import CryptoJSCore from "crypto-js/core";

const secretKey = "whaire";

const user = createReducer(
  { status: "not-logged-in" },
  {
    [USER_ACTION_LOGIN_BEFORE]: () => ({ status: "not-logged-in" }),
    [UserActionTypes.LOGIN_ERROR]: (state, action) => ({
      status: "login-error",
      error: action.err
    }),
    [USER_ACTION_LOGIN_SUCCESS]: (state, { authentication, obj }) => ({
      status: "login-success",
      authentication,
      obj
    }),
    [UserActionTypes.LOGOUT]: () => ({ status: "not-logged-in" }),
    [USER_ACTION_REG_SUBMIT_SUCCESS]: () => ({ status: "register-success" }),
    [UserActionTypes.REGISTER_SUCCESS]: () => ({ status: "register-success" })
  }
);

export default persistReducer(
  {
    key: "user",
    storage: persistStorageSelect(),
    transforms: [
      createTransform(
        inboundState => {
          return AES.encrypt(
            JSON.stringify(inboundState),
            secretKey
          ).toString();
        },
        (outboundState, key) => {
          const decoded = JSON.parse(
            AES.decrypt(outboundState, secretKey).toString(
              CryptoJSCore.enc.Utf8
            )
          );
          // console.log(key, decoded);
          if (key === "status" && decoded !== "login-success")
            return "not-logged-in";
          if (key === "error") return undefined;
          return decoded;
        }
      )
      // process.env.NODE_ENV === "production" &&
    ].filter(Boolean)
  },
  user
);
