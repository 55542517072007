import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { ReactComponent as HamburgerIconWhite } from "../../images/HamburgerIconWhite.svg";
import { ReactComponent as HamburgerIconDark } from "../../images/HamburgerIconDark.svg";
import parentStyles from "./TopBar.module.scss";
import styles from "./TopBarMenu.module.scss";
import AuthLink from "../AuthLink/AuthLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withTopBar from "./withTopBar";
import menuStyles from "./Menu.module.scss";

const HamburgerIcons = { light: HamburgerIconWhite, dark: HamburgerIconDark };

@withTopBar
class TopBarMenu extends React.Component {
  /**
   *
   * @type {React.RefObject<HTMLElement>}
   * @private
   */
  _menu = React.createRef();
  /**
   *
   * @type {React.RefObject<HTMLElement>}
   * @private
   */
  _menuContent = React.createRef();
  /**
   *
   * @type {React.RefObject<HTMLElement>}
   * @private
   */
  _menuToggleButton = React.createRef();
  /**
   *
   * @type {React.RefObject<HTMLElement>}
   * @private
   */
  _menuTitle = React.createRef();
  /**
   *
   * @type {null}
   * @private
   */
  _collapsed = {};

  state = {
    active: false,
    expanded: true
  };

  constructor(props) {
    super(props);
    this.state = {a: "Events"};
    // this._expanded = true;
    this._animate = false;
    this._duration = 200;
    this._frameTime = 1000 / 60;
    this._nFrames = Math.round(this._duration / this._frameTime);
  }

  componentDidMount() {
    this._calculateScales();
    this._createEaseAnimations();
    this._addEventListeners();

    this._collapse();
    this._activate();
  }

  /**
   *
   * @param arg
   * @returns {Promise<unknown>}
   * @private
   */
  _asyncSetState = arg => new Promise(resolve => this.setState(arg, resolve));

  /**
   *
   * @private
   */
  _activate = () => {
    // this._menu.current.classList.add(styles.MenuActive);
    this.setState({ active: true }, () => {
      this._animate = true;
    });
  };

  /**
   *
   * @private
   */
  _collapse = async () => {
    if (!this.state.expanded) {
      return;
    }
    // this._expanded = false;
    await this._asyncSetState({ expanded: false });

    const { x, y } = this._collapsed;
    const invX = 1 / x;
    const invY = 1 / y;

    if (this._menu.current)
      this._menu.current.style.transform = `scale(${x}, ${y})`;

    if (this._menuContent.current)
      this._menuContent.current.style.transform = `scale(${invX}, ${invY})`;

    if (!this._animate) {
      return;
    }

    this._applyAnimation({ expand: false });
  };

  /**
   *
   * @private
   */
  _expand = async () => {
    if (this.state.expanded) {
      return;
    }
    // this._expanded = true;
    await this._asyncSetState({ expanded: true });

    this._menu.current.style.transform = `scale(1, 1)`;
    this._menuContent.current.style.transform = `scale(1, 1)`;

    if (!this._animate) {
      return;
    }

    this._applyAnimation({ expand: true });
  };

  /**
   *
   * @private
   */
  _toggle = () => {
    if (this.state.expanded) {
      this._collapse();
      return;
    }

    this._expand();
  };

  _addEventListeners = () => {
    this._menuToggleButton.current.addEventListener("click", this._toggle);
  };

  /**
   *
   * @param expand
   * @private
   */
  _applyAnimation = ({ expand }) => {
    this._menu.current.classList.remove(styles.MenuExpanded);
    this._menu.current.classList.remove(styles.MenuCollapses);
    this._menuContent.current.classList.remove(styles.MenuContentsExpanded);
    this._menuContent.current.classList.remove(styles.MenuContentsCollapsed);

    // Force a recalc styles here so the classes take hold.
    // eslint-disable-next-line no-unused-expressions
    window.getComputedStyle(this._menu.current).transform;

    if (expand) {
      this._menu.current.classList.add(styles.MenuExpanded);
      this._menuContent.current.classList.add(styles.MenuContentsExpanded);
      return;
    }

    this._menu.current.classList.add(styles.MenuCollapses);
    this._menuContent.current.classList.add(styles.MenuContentsCollapsed);
  };

  /**
   *
   * @private
   */
   _calculateScales = () => {
    const collapsed = this._menuTitle.current.getBoundingClientRect();
    // console.log(collapsed);
    const expanded = this._menu.current.getBoundingClientRect();

    this._collapsed = {
      x: collapsed.width / expanded.width,
      y: collapsed.height / expanded.height
    };
  };

  /**
   *
   * @returns {Element}
   * @private
   */
  _createEaseAnimations = () => {
    const self = this;
    let menuEase = document.querySelector(".menu-ease");
    if (menuEase) {
      return menuEase;
    }

    menuEase = document.createElement("style");
    menuEase.classList.add("menu-ease");

    const menuExpandAnimation = [];
    const menuExpandContentsAnimation = [];
    const menuCollapseAnimation = [];
    const menuCollapseContentsAnimation = [];

    const percentIncrement = 100 / self._nFrames;

    for (let i = 0; i <= self._nFrames; i++) {
      const step = self._ease(i / self._nFrames).toFixed(5);
      const percentage = (i * percentIncrement).toFixed(5);
      const startX = self._collapsed.x;
      const startY = self._collapsed.y;
      const endX = 1;
      const endY = 1;

      // Expand animation.
      self._append({
        percentage,
        step,
        startX,
        startY,
        endX,
        endY,
        outerAnimation: menuExpandAnimation,
        innerAnimation: menuExpandContentsAnimation
      });

      // Collapse animation.
      self._append({
        percentage,
        step,
        startX: 1,
        startY: 1,
        endX: self._collapsed.x,
        endY: self._collapsed.y,
        outerAnimation: menuCollapseAnimation,
        innerAnimation: menuCollapseContentsAnimation
      });
    }

    menuEase.textContent = `
    @keyframes ${styles.menuExpandAnimation} {
      ${menuExpandAnimation.join("")}
    }
    @keyframes ${styles.menuExpandContentsAnimation} {
      ${menuExpandContentsAnimation.join("")}
    }
    @keyframes ${styles.menuCollapseAnimation} {
      ${menuCollapseAnimation.join("")}
    }
    @keyframes ${styles.menuCollapseContentsAnimation} {
      ${menuCollapseContentsAnimation.join("")}
    }`;

    document.head.appendChild(menuEase);
    return menuEase;
  };

  /**
   *
   * @param percentage
   * @param step
   * @param startX
   * @param startY
   * @param endX
   * @param endY
   * @param outerAnimation
   * @param innerAnimation
   * @private
   */
  _append = ({
    percentage,
    step,
    startX,
    startY,
    endX,
    endY,
    outerAnimation,
    innerAnimation
  }) => {
    const xScale = (startX + (endX - startX) * step).toFixed(5);
    const yScale = (startY + (endY - startY) * step).toFixed(5);

    const invScaleX = (1 / xScale).toFixed(5);
    const invScaleY = (1 / yScale).toFixed(5);


    outerAnimation.push(`
      ${percentage}% {
        transform: scale(${xScale}, ${yScale});
      }`);

    innerAnimation.push(`
      ${percentage}% {
        transform: scale(${invScaleX}, ${invScaleY});
      }`);
  };

  /**
   *
   * @param value
   * @param min
   * @param max
   * @returns {number}
   * @private
   */
  _clamp = (value, min, max) => {
    return Math.max(min, Math.min(max, value));
  };

  /**
   *
   * @param v
   * @param pow
   * @returns {number}
   * @private
   */
  _ease = (v, pow = 4) => {
    v = this._clamp(v, 0, 1);

    return 1 - Math.pow(1 - v, pow);
  };
  handleClick = (title, link) => {
    if(title === "Home") {
      this.setState({a: "Home"});
    }else if(title === "FAQ") {
      this.setState({a: "FAQ"});
    }else if(title === "About us") {
      this.setState({a: "About us"});
    }
    else {
      this.setState({a: "Events"});
    }
  }
  render() {
    const self = this,
      { theme, children, topBarMenu, ...props } = self.props
    return (
      <>
        <ul className={menuStyles.MenuItems}>
              {topBarMenu.filter(([link, activeFn, title, icon, opts = {}]) => {
                if(title === "Booking" || title === "Exhibitors") {
                  return false
                } return true
              }).map(([link, activeFn, title, icon, opts = {}]) => (
                <li
                  key={link}
                  className={classnames(
                    styles.MenuItem,
                    opts.child && [
                      menuStyles.Child,
                      [null, menuStyles.Child1][opts.child]
                    ],
                    opts.enabled === false && menuStyles.Disabled
                  )}
                  onClick={() => self._toggle()}
                >
                  <AuthLink
                    className={"no-link"}
                    activeClassName={menuStyles.Active}
                    to={link}
                    isActive={activeFn}
                  >
                    <b onClick={() => {this.handleClick(title, link)}}
                      // style = {{ color: this.state.a === title ? "#e10f76" : '#414042'}} 
                      // boxShadow: this.state.a === title? "0 3px #e10f76" : undefined}} 
                      className={styles.menuItemTitle}
                    >{title}</b>
                  </AuthLink>
                </li>
              ))}
            </ul>
            <nav
              ref={self._menu}
              {...props}
              className={classnames(
                theme === "light" ? styles.MenuThemeLight : styles.MenuThemeDark,
                self.state.active && styles.MenuActive
              )}
            >
              <div ref={self._menuContent}>
                <div ref={self._menuToggleButton} className={styles.MenuToggle}>
                  <div
                    ref={self._menuTitle}
                  >
                  </div>
                </div>
              </div>
            </nav>
      </>
    );
  }
}
TopBarMenu.propTypes = {
  theme: PropTypes.string.isRequired
};

export default TopBarMenu;
