import React from "react";
import withTopBarExhibitor from "../../../controllers/withTopBarExhibitor/withTopBarExhibitor";
import Drawer from "@material-ui/core/Drawer";
import styles from "./TopBarMobile.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { AppActionToggleExhibitor } from "../../../store/app/actions";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { Link } from "react-router-dom";
import LockIcon from "@material-ui/icons/Lock";
// import {matchPath, useHistory, useLocation} from "react-router";

const useStyles = makeStyles({
  paper: {
    borderTopLeftRadius: "1rem",
    borderTopRightRadius: "1rem"
  }
});

const TopBarMobileExhibitorList = withTopBarExhibitor(
  ({ exhibitors, noExhibitor, current, onSelect }) => {
    const open = useSelector(state => state.app.toggle.exhibitorList),
      dispatch = useDispatch(),
      // history = useHistory(),
      // location=useLocation(),
      setOpen = toggle => dispatch(AppActionToggleExhibitor(toggle)),
      classes = useStyles();
    // isExhibitorPage=matchPath(location.pathname,{path:'/exhibitor'})!==null
    if (noExhibitor)
      return (
        <i>
          <Link className={"no-link"} to={"/exhibitor/add"}>
            No exhibitor
          </Link>
        </i>
      );
    return (
      <>
        <strong className={styles.ExhCurrent} onClick={() => setOpen(true)}>
          {current.brand_name}
        </strong>
        <Drawer
          classes={classes}
          anchor={"bottom"}
          open={open}
          onClose={() => setOpen(false)}
        >
          <div className={styles.Exh}>
            <h4 className="text-primary pl-3">Choose your exhibitor</h4>
            {exhibitors.map(exhibitor => (
              <div
                className={classnames(
                  styles.ExhItem,
                  exhibitor.id * 1 === current.id * 1 && styles.ExhSelected,
                  !exhibitor.isAvailableToBooking && styles.ExhUnavailable
                )}
                key={exhibitor.id}
                onClick={() => {
                  onSelect(exhibitor);
                  // if(isExhibitorPage)
                  // history.replace("/exhibitor/detail");
                  setOpen(false);
                }}
              >
                {exhibitor.brand_name}
                {!exhibitor.isAvailableToBooking && (
                  <LockIcon fontSize={"small"} className={"ml-2"} />
                )}
              </div>
            ))}
            <Link
              className={classnames(styles.ExhItem, styles.ExhAdd)}
              key={"__add"}
              to={"/exhibitor/add"}
              onClick={() => setOpen(false)}
            >
              <FontAwesomeIcon icon={faPlusCircle} />
              Add new...
            </Link>
          </div>
        </Drawer>
      </>
    );
  }
);

if (process.env.NODE_ENV === "development")
  TopBarMobileExhibitorList.displayName = "TopBarMobileExhibitorList";

export default TopBarMobileExhibitorList;
