import "./utils/bootstrap";
// import "react-hot-loader";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "./store/store";
import { Provider, ReactReduxContext } from "react-redux";
import * as Sentry from "@sentry/browser";
import { BrowserRouter } from "react-router-dom";

// window.process = {};

const { store, persistor } = configureStore();

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DNS });

ReactDOM.render(
  <Provider store={store} context={ReactReduxContext}>
    <PersistGate loading={null} persistor={persistor}>
      {/*<ConnectedRouter history={history} context={ReactReduxContext}>*/}
      <BrowserRouter>
        <App />
      </BrowserRouter>
      {/*</ConnectedRouter>*/}
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
