import React, { useEffect } from "react";
import { matchPath, useLocation } from "react-router";
import withRole from "../../HOCs/withRole";
import { compose } from "../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import {
  BookingSelectorGetOrders
} from "../../store/booking/selector";
import { BookingActionFetchOrders } from "../../store/booking/actions";

const withOrders = Component =>
  compose(withRole)(({ urlHelper, ...props }) => {
    const dispatch = useDispatch(),
      objectList = useSelector(state => (BookingSelectorGetOrders)(state));
    // console.log("objectList", objectList)
    useEffect(() => {
      dispatch(BookingActionFetchOrders())
    }, [])
    return <Component {...props} orders={objectList} />;
  });

export default withOrders;
