import { ExhSelectorGetCurrent } from "../exhibitor/selectors";
import isEmpty from "lodash/isEmpty";
import BoothActionType, {
  BOOTH_ACTION_ERROR,
  BOOTH_ACTION_UPDATE,
  BOOTH_ACTION_UPDATE_SUCCESS,
  BOOTH_ACTIONS_UPDATE_PRICE,
  BOOTH_ACTIONS_UPDATE_PRICE_SUCCESS,
  BOOTH_ACTIONS_UPDATE_PRICES,
  BOOTH_ACTIONS_UPDATE_PRICES_SUCCESS
} from "./const";
import {
  takeEvery,
  take,
  select,
  all,
  call,
  put
} from "@redux-saga/core/effects";
import { ExhibitorActionType } from "../exhibitor/const";
import { APIExternal, APIInternal, APIReplParams } from "../../utils/constant";
import { axiosApi } from "../../utils/api";
import { combineRootSagas, takeLeading } from "../../utils/store";
import { GetUserRoles } from "../user/selectors";

const _fetched = (id, booths, pricing) => ({
  type: BoothActionType.FETCHED,
  id,
  booths,
  pricing
});
function* spawnErr(err) {
  yield put({ type: BOOTH_ACTION_ERROR, err });
}

function* fetchAdmin(eventId) {
  const [{ data: booths }, { data: pricing }] = yield all(
    [APIInternal.GET_ALL_BOOTHS, APIInternal.GET_ALL_PRICING].map(api =>
      call(axiosApi.auth().get, APIReplParams(api, { eventId }))
    )
  );
  yield put(_fetched(eventId, booths, pricing));
}
function* fetchUser(eventId) {
  const exhibitor = select(ExhSelectorGetCurrent);
  if (isEmpty(yield exhibitor)) yield take(ExhibitorActionType.FETCHED);
  const { data: booths } = yield call(
    axiosApi.auth().get,
    APIReplParams(APIExternal.GET_ALL_BOOTHS, {
      exhId: (yield exhibitor).id,
      eventId
    })
  );
  yield put(_fetched(eventId, booths, []));
}
function* fetch() {
  yield takeEvery(BoothActionType.FETCHING, function*({ id }) {
    const role = yield select(GetUserRoles);
    yield call(role.isNotClient ? fetchAdmin : fetchUser, id);
  });
}
function* updateBoothPrice() {
  //update single
  yield takeEvery(BOOTH_ACTIONS_UPDATE_PRICE, function*({
    eventId,
    id,
    price
  }) {
    try {
      const { data } = yield call(
          axiosApi.auth().put,
          APIReplParams("/v1/b/a/event/:eventId/pricing/:id", { eventId, id }),
          { price }
        ),
        { err } = data;
      if (!err)
        yield put({ type: BOOTH_ACTIONS_UPDATE_PRICE_SUCCESS, eventId, id });
      else throw new Error(err);
    } catch (e) {
      throw e;
    }
  });

  //update multiple
  yield takeLeading(BOOTH_ACTIONS_UPDATE_PRICES, function*({
    eventId,
    prices = []
  }) {
    try {
      yield all(
        prices.map(({ id, price }) =>
          put({ type: BOOTH_ACTIONS_UPDATE_PRICE, eventId, id, price })
        )
      );
      yield all(
        prices.map(({ id }) =>
          take(
            action =>
              action.type === BOOTH_ACTIONS_UPDATE_PRICE_SUCCESS &&
              action.eventId === eventId &&
              action.id === id
          )
        )
      );
      yield put({
        type: BOOTH_ACTIONS_UPDATE_PRICES_SUCCESS,
        eventId
      });
      yield put({ type: BoothActionType.FETCHING, id: eventId });
    } catch (e) {
      yield spawnErr(e);
    }
  });
}
function* updateBooth() {
  yield takeLeading(BOOTH_ACTION_UPDATE, function*({ eventId, id, form }) {
    try {
      const { data } = yield call(
          axiosApi.auth().put,
          `/v1/b/a/booths/${id}`,
          form
        ),
        { err } = data;
      if (!err) {
        yield put({ type: BOOTH_ACTION_UPDATE_SUCCESS, id });
        yield put({ type: BoothActionType.FETCHING, id: eventId });
      } else {
      }
    } catch (e) {
      yield spawnErr(e);
    }
  });
}
export default function* boothSagas() {
  yield combineRootSagas(fetch, updateBoothPrice, updateBooth);
}
