import React from "react";
import styles from "./SimpleTable.module.scss";
import { withSimpleTableContext } from "./utils";
import { compose } from "../../../utils/common";
import withProps from "../../_helpers/withProps";
import { Input } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import TableCell from "@material-ui/core/TableCell";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import CheckIcon from "@material-ui/icons/Check";

const useInputClasses = makeStyles({
  input: {
    "input&": {
      padding: "0.3em .7em 0.5em 0 !important"
    }
  }
});

const SimpleTableCell = compose(
  withSimpleTableContext,
  withProps(props => {
    let { fieldData, header } = props;
    let InputComponent = Input,
      inputMeta = { field: "value" },
      inputProps = () => ({ [inputMeta.field]: fieldData });
    if (header.editComponent) InputComponent = header.editComponent;
    if (header.type === "boolean") {
      InputComponent = Checkbox;
      inputMeta = {
        field: "checked"
      };
      if (typeof header.display !== "function")
        header = {
          ...header,
          display: () => (
            <span className={"ml-1"}>
              {fieldData ? <CheckIcon /> : <RemoveIcon />}
            </span>
          )
        };
    }

    return {
      InputComponent,
      inputProps: inputProps(),
      inputMeta,
      header
    };
  })
)(function SimpleTableCell({
  row,
  fieldData,
  header,
  field,
  isEditingRow,
  InputComponent,
  inputProps,
  inputMeta,
  setRowData,
  updateField
}) {
  const classes = useInputClasses();
  if (header.hidden) return null;
  const display = () => {
    let value = fieldData;
    if (!isEditingRow)
      if (typeof header.display === "function")
        value = header.display(value, row);
    if (isEditingRow)
      if (typeof header.editValueDisplay === "function")
        value = header.editValueDisplay(value, row);
    return value;
  };
  return (
    <TableCell
      className={classnames({
        [styles.TableHead]: header.nowrap === true
      })}
    >
      {!isEditingRow || header.lockEdit ? (
        display()
      ) : (
        <InputComponent
          classes={classes}
          onChange={e => {
            if (e.target) {
              updateField(field, e.target[inputMeta.field]);
              if (
                typeof header.autoFill === "function" &&
                header.autoFillImmediately === true
              ) {
                setRowData(header.autoFill(e.target[inputMeta.field]), row);
              }
            }
          }}
          onBlur={e => {
            if (e.target && typeof header.autoFill === "function") {
              setRowData(header.autoFill(e.target[inputMeta.field], row));
            }
          }}
          {...inputProps}
        />
      )}
    </TableCell>
  );
});

export default SimpleTableCell;
