import React from "react";
import { Button, Row, Input, Alert } from "reactstrap";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import _ from "lodash";
import { axiosApi } from "../../utils/api";
import { NotificationManager } from "react-notifications";

const ApprovePaymentDialog = props => {
  const { onClose, payment, auth, fetchOrder } = props;
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const _approvePayment = payload => {
    return axiosApi.auth(auth).post("/v1/booking/a/approve-payment", payload);
  };

  const onSuccess = e => {
    if (e) {
      console.log(JSON.stringify(e));
      const errorMsg = _.get(e, "message");
      setError(errorMsg);
    } else {
      setError("");
      onClose();
      NotificationManager.success("Approved payment successfully.");
      fetchOrder();
    }
    setLoading(false);
  };

  const onSubmit = async () => {
    setLoading(true);
    const { data } = await _approvePayment({
      payment_reminder_id: payment.id,
      exhibitor_id: payment.exhibitor_id
    });
    console.log(data);
    const { success, err } = data;
    if (success) {
      return onSuccess();
    } else return onSuccess(err);
  };

  return (
    <div className="custom-ui shadow">
      <div className="p-5">
        <Row>
          <h1>Approve the payment</h1>
        </Row>
        <Row>
          <div className="w-100">
            Amount Paid: <b>${payment.short_amount_paid}</b>
          </div>
          <div className="w-100">
            Invoice No.: <b>{payment.invoice_no}</b>
          </div>
          <div className="w-100">
            Payment method: <b>{payment.payment_method}</b>
          </div>
        </Row>

        <Row className="mt-3">
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>
          {"   "}
          <Button
            className="sw-100"
            color={"primary"}
            disabled={loading}
            onClick={onSubmit}
          >
            {!loading && <>{"Confirm"}</>}
            {loading && (
              <Loader type="ThreeDots" color="white" height={15} width={15} />
            )}
          </Button>
        </Row>
        <Row>
          {error && error.length > 0 && (
            <Alert color="danger" className="mt-3 w-100">
              {error}
            </Alert>
          )}
        </Row>
      </div>
    </div>
  );
};

export default ApprovePaymentDialog;
