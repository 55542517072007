import React from "react";
import { useHistory, useLocation } from "react-router";
import qs from "qs";

const withAppModal = Component => {
  function WithAppModal(props) {
    const history = useHistory(),
      location = useLocation(),
      navigateToModal = (dialog = "/") => {
        const query = Object.assign(
          {},
          qs.parse(location.search || "", {
            ignoreQueryPrefix: true
          }),
          { dialog }
        );
        history.push(
          location.pathname + qs.stringify(query, { addQueryPrefix: true })
        );
      };

    return <Component navigateToModal={navigateToModal} {...props} />;
  }
  return WithAppModal;
};

export default withAppModal;

export const AppModalContext = React.createContext({
  parentRouter: {},
  close: () => {}
});

export const withParentRouter = Component => props => (
  <AppModalContext.Consumer>
    {context => <Component {...props} {...context} />}
  </AppModalContext.Consumer>
);
