const countries = [
  {
    name: "(+65) Singapore",
    phone: "65"
  },
  {
    name: "(+60) Malaysia",
    phone: "60"
  },
  {
    name: "(+84) Vietnam",
    phone: "84"
  },
  {
    name: "(+66) Thailand",
    phone: "66"
  },
  {
    name: "(+62) Indonesia",
    phone: "62"
  },
  {
    name: "(+63) Philippines",
    phone: "63"
  },
  {
    name: "(+91) India",
    phone: "91"
  },
  {
    name: "(+86) China",
    phone: "86"
  },
  {
    name: "(+376) Andorra",
    phone: "376"
  },
  {
    name: "(+971) United Arab Emirates",
    phone: "971"
  },
  {
    name: "(+93) Afghanistan",
    phone: "93"
  },
  {
    name: "(+1268) Antigua and Barbuda",
    phone: "1268"
  },
  {
    name: "(+1264) Anguilla",
    phone: "1264"
  },
  {
    name: "(+355) Albania",
    phone: "355"
  },
  {
    name: "(+374) Armenia",
    phone: "374"
  },
  {
    name: "(+244) Angola",
    phone: "244"
  },
  {
    name: "(+672) Antarctica",
    phone: "672"
  },
  {
    name: "(+54) Argentina",
    phone: "54"
  },
  {
    name: "(+1684) American Samoa",
    phone: "1684"
  },
  {
    name: "(+43) Austria",
    phone: "43"
  },
  {
    name: "(+61) Australia",
    phone: "61"
  },
  {
    name: "(+297) Aruba",
    phone: "297"
  },
  {
    name: "(+358) Åland",
    phone: "358"
  },
  {
    name: "(+994) Azerbaijan",
    phone: "994"
  },
  {
    name: "(+387) Bosnia and Herzegovina",
    phone: "387"
  },
  {
    name: "(+1246) Barbados",
    phone: "1246"
  },
  {
    name: "(+880) Bangladesh",
    phone: "880"
  },
  {
    name: "(+32) Belgium",
    phone: "32"
  },
  {
    name: "(+226) Burkina Faso",
    phone: "226"
  },
  {
    name: "(+359) Bulgaria",
    phone: "359"
  },
  {
    name: "(+973) Bahrain",
    phone: "973"
  },
  {
    name: "(+257) Burundi",
    phone: "257"
  },
  {
    name: "(+229) Benin",
    phone: "229"
  },
  {
    name: "(+590) Saint Barthélemy",
    phone: "590"
  },
  {
    name: "(+1441) Bermuda",
    phone: "1441"
  },
  {
    name: "(+673) Brunei",
    phone: "673"
  },
  {
    name: "(+591) Bolivia",
    phone: "591"
  },
  {
    name: "(+5997) Bonaire",
    phone: "5997"
  },
  {
    name: "(+55) Brazil",
    phone: "55"
  },
  {
    name: "(+1242) Bahamas",
    phone: "1242"
  },
  {
    name: "(+975) Bhutan",
    phone: "975"
  },
  {
    name: "(+47) Bouvet Island",
    phone: "47"
  },
  {
    name: "(+267) Botswana",
    phone: "267"
  },
  {
    name: "(+375) Belarus",
    phone: "375"
  },
  {
    name: "(+501) Belize",
    phone: "501"
  },
  {
    name: "(+1) Canada",
    phone: "1"
  },
  {
    name: "(+61) Cocos [Keeling] Islands",
    phone: "61"
  },
  {
    name: "(+243) Democratic Republic of the Congo",
    phone: "243"
  },
  {
    name: "(+236) Central African Republic",
    phone: "236"
  },
  {
    name: "(+242) Republic of the Congo",
    phone: "242"
  },
  {
    name: "(+41) Switzerland",
    phone: "41"
  },
  {
    name: "(+225) Ivory Coast",
    phone: "225"
  },
  {
    name: "(+682) Cook Islands",
    phone: "682"
  },
  {
    name: "(+56) Chile",
    phone: "56"
  },
  {
    name: "(+237) Cameroon",
    phone: "237"
  },
  {
    name: "(+57) Colombia",
    phone: "57"
  },
  {
    name: "(+506) Costa Rica",
    phone: "506"
  },
  {
    name: "(+53) Cuba",
    phone: "53"
  },
  {
    name: "(+238) Cape Verde",
    phone: "238"
  },
  {
    name: "(+5999) Curacao",
    phone: "5999"
  },
  {
    name: "(+61) Christmas Island",
    phone: "61"
  },
  {
    name: "(+357) Cyprus",
    phone: "357"
  },
  {
    name: "(+420) Czech Republic",
    phone: "420"
  },
  {
    name: "(+49) Germany",
    phone: "49"
  },
  {
    name: "(+253) Djibouti",
    phone: "253"
  },
  {
    name: "(+45) Denmark",
    phone: "45"
  },
  {
    name: "(+1767) Dominica",
    phone: "1767"
  },
  {
    name: "(+1809,1829,1849) Dominican Republic",
    phone: "1809,1829,1849"
  },
  {
    name: "(+213) Algeria",
    phone: "213"
  },
  {
    name: "(+593) Ecuador",
    phone: "593"
  },
  {
    name: "(+372) Estonia",
    phone: "372"
  },
  {
    name: "(+20) Egypt",
    phone: "20"
  },
  {
    name: "(+212) Western Sahara",
    phone: "212"
  },
  {
    name: "(+291) Eritrea",
    phone: "291"
  },
  {
    name: "(+34) Spain",
    phone: "34"
  },
  {
    name: "(+251) Ethiopia",
    phone: "251"
  },
  {
    name: "(+358) Finland",
    phone: "358"
  },
  {
    name: "(+679) Fiji",
    phone: "679"
  },
  {
    name: "(+500) Falkland Islands",
    phone: "500"
  },
  {
    name: "(+691) Micronesia",
    phone: "691"
  },
  {
    name: "(+298) Faroe Islands",
    phone: "298"
  },
  {
    name: "(+33) France",
    phone: "33"
  },
  {
    name: "(+241) Gabon",
    phone: "241"
  },
  {
    name: "(+44) United Kingdom",
    phone: "44"
  },
  {
    name: "(+1473) Grenada",
    phone: "1473"
  },
  {
    name: "(+995) Georgia",
    phone: "995"
  },
  {
    name: "(+594) French Guiana",
    phone: "594"
  },
  {
    name: "(+44) Guernsey",
    phone: "44"
  },
  {
    name: "(+233) Ghana",
    phone: "233"
  },
  {
    name: "(+350) Gibraltar",
    phone: "350"
  },
  {
    name: "(+299) Greenland",
    phone: "299"
  },
  {
    name: "(+220) Gambia",
    phone: "220"
  },
  {
    name: "(+224) Guinea",
    phone: "224"
  },
  {
    name: "(+590) Guadeloupe",
    phone: "590"
  },
  {
    name: "(+240) Equatorial Guinea",
    phone: "240"
  },
  {
    name: "(+30) Greece",
    phone: "30"
  },
  {
    name: "(+500) South Georgia and the South Sandwich Islands",
    phone: "500"
  },
  {
    name: "(+502) Guatemala",
    phone: "502"
  },
  {
    name: "(+1671) Guam",
    phone: "1671"
  },
  {
    name: "(+245) Guinea-Bissau",
    phone: "245"
  },
  {
    name: "(+592) Guyana",
    phone: "592"
  },
  {
    name: "(+852) Hong Kong",
    phone: "852"
  },
  {
    name: "(+61) Heard Island and McDonald Islands",
    phone: "61"
  },
  {
    name: "(+504) Honduras",
    phone: "504"
  },
  {
    name: "(+385) Croatia",
    phone: "385"
  },
  {
    name: "(+509) Haiti",
    phone: "509"
  },
  {
    name: "(+36) Hungary",
    phone: "36"
  },
  {
    name: "(+353) Ireland",
    phone: "353"
  },
  {
    name: "(+972) Israel",
    phone: "972"
  },
  {
    name: "(+44) Isle of Man",
    phone: "44"
  },
  {
    name: "(+246) British Indian Ocean Territory",
    phone: "246"
  },
  {
    name: "(+964) Iraq",
    phone: "964"
  },
  {
    name: "(+98) Iran",
    phone: "98"
  },
  {
    name: "(+354) Iceland",
    phone: "354"
  },
  {
    name: "(+39) Italy",
    phone: "39"
  },
  {
    name: "(+44) Jersey",
    phone: "44"
  },
  {
    name: "(+1876) Jamaica",
    phone: "1876"
  },
  {
    name: "(+962) Jordan",
    phone: "962"
  },
  {
    name: "(+81) Japan",
    phone: "81"
  },
  {
    name: "(+254) Kenya",
    phone: "254"
  },
  {
    name: "(+996) Kyrgyzstan",
    phone: "996"
  },
  {
    name: "(+855) Cambodia",
    phone: "855"
  },
  {
    name: "(+686) Kiribati",
    phone: "686"
  },
  {
    name: "(+269) Comoros",
    phone: "269"
  },
  {
    name: "(+1869) Saint Kitts and Nevis",
    phone: "1869"
  },
  {
    name: "(+850) North Korea",
    phone: "850"
  },
  {
    name: "(+82) South Korea",
    phone: "82"
  },
  {
    name: "(+965) Kuwait",
    phone: "965"
  },
  {
    name: "(+1345) Cayman Islands",
    phone: "1345"
  },
  {
    name: "(+76,77) Kazakhstan",
    phone: "76,77"
  },
  {
    name: "(+856) Laos",
    phone: "856"
  },
  {
    name: "(+961) Lebanon",
    phone: "961"
  },
  {
    name: "(+1758) Saint Lucia",
    phone: "1758"
  },
  {
    name: "(+423) Liechtenstein",
    phone: "423"
  },
  {
    name: "(+94) Sri Lanka",
    phone: "94"
  },
  {
    name: "(+231) Liberia",
    phone: "231"
  },
  {
    name: "(+266) Lesotho",
    phone: "266"
  },
  {
    name: "(+370) Lithuania",
    phone: "370"
  },
  {
    name: "(+352) Luxembourg",
    phone: "352"
  },
  {
    name: "(+371) Latvia",
    phone: "371"
  },
  {
    name: "(+218) Libya",
    phone: "218"
  },
  {
    name: "(+212) Morocco",
    phone: "212"
  },
  {
    name: "(+377) Monaco",
    phone: "377"
  },
  {
    name: "(+373) Moldova",
    phone: "373"
  },
  {
    name: "(+382) Montenegro",
    phone: "382"
  },
  {
    name: "(+590) Saint Martin",
    phone: "590"
  },
  {
    name: "(+261) Madagascar",
    phone: "261"
  },
  {
    name: "(+692) Marshall Islands",
    phone: "692"
  },
  {
    name: "(+389) North Macedonia",
    phone: "389"
  },
  {
    name: "(+223) Mali",
    phone: "223"
  },
  {
    name: "(+95) Myanmar [Burma]",
    phone: "95"
  },
  {
    name: "(+976) Mongolia",
    phone: "976"
  },
  {
    name: "(+853) Macao",
    phone: "853"
  },
  {
    name: "(+1670) Northern Mariana Islands",
    phone: "1670"
  },
  {
    name: "(+596) Martinique",
    phone: "596"
  },
  {
    name: "(+222) Mauritania",
    phone: "222"
  },
  {
    name: "(+1664) Montserrat",
    phone: "1664"
  },
  {
    name: "(+356) Malta",
    phone: "356"
  },
  {
    name: "(+230) Mauritius",
    phone: "230"
  },
  {
    name: "(+960) Maldives",
    phone: "960"
  },
  {
    name: "(+265) Malawi",
    phone: "265"
  },
  {
    name: "(+52) Mexico",
    phone: "52"
  },
  {
    name: "(+258) Mozambique",
    phone: "258"
  },
  {
    name: "(+264) Namibia",
    phone: "264"
  },
  {
    name: "(+687) New Caledonia",
    phone: "687"
  },
  {
    name: "(+227) Niger",
    phone: "227"
  },
  {
    name: "(+672) Norfolk Island",
    phone: "672"
  },
  {
    name: "(+234) Nigeria",
    phone: "234"
  },
  {
    name: "(+505) Nicaragua",
    phone: "505"
  },
  {
    name: "(+31) Netherlands",
    phone: "31"
  },
  {
    name: "(+47) Norway",
    phone: "47"
  },
  {
    name: "(+977) Nepal",
    phone: "977"
  },
  {
    name: "(+674) Nauru",
    phone: "674"
  },
  {
    name: "(+683) Niue",
    phone: "683"
  },
  {
    name: "(+64) New Zealand",
    phone: "64"
  },
  {
    name: "(+968) Oman",
    phone: "968"
  },
  {
    name: "(+507) Panama",
    phone: "507"
  },
  {
    name: "(+51) Peru",
    phone: "51"
  },
  {
    name: "(+689) French Polynesia",
    phone: "689"
  },
  {
    name: "(+675) Papua New Guinea",
    phone: "675"
  },
  {
    name: "(+92) Pakistan",
    phone: "92"
  },
  {
    name: "(+48) Poland",
    phone: "48"
  },
  {
    name: "(+508) Saint Pierre and Miquelon",
    phone: "508"
  },
  {
    name: "(+64) Pitcairn Islands",
    phone: "64"
  },
  {
    name: "(+1787,1939) Puerto Rico",
    phone: "1787,1939"
  },
  {
    name: "(+970) Palestine",
    phone: "970"
  },
  {
    name: "(+351) Portugal",
    phone: "351"
  },
  {
    name: "(+680) Palau",
    phone: "680"
  },
  {
    name: "(+595) Paraguay",
    phone: "595"
  },
  {
    name: "(+974) Qatar",
    phone: "974"
  },
  {
    name: "(+262) Réunion",
    phone: "262"
  },
  {
    name: "(+40) Romania",
    phone: "40"
  },
  {
    name: "(+381) Serbia",
    phone: "381"
  },
  {
    name: "(+7) Russia",
    phone: "7"
  },
  {
    name: "(+250) Rwanda",
    phone: "250"
  },
  {
    name: "(+966) Saudi Arabia",
    phone: "966"
  },
  {
    name: "(+677) Solomon Islands",
    phone: "677"
  },
  {
    name: "(+248) Seychelles",
    phone: "248"
  },
  {
    name: "(+249) Sudan",
    phone: "249"
  },
  {
    name: "(+46) Sweden",
    phone: "46"
  },
  {
    name: "(+290) Saint Helena",
    phone: "290"
  },
  {
    name: "(+386) Slovenia",
    phone: "386"
  },
  {
    name: "(+4779) Svalbard and Jan Mayen",
    phone: "4779"
  },
  {
    name: "(+421) Slovakia",
    phone: "421"
  },
  {
    name: "(+232) Sierra Leone",
    phone: "232"
  },
  {
    name: "(+378) San Marino",
    phone: "378"
  },
  {
    name: "(+221) Senegal",
    phone: "221"
  },
  {
    name: "(+252) Somalia",
    phone: "252"
  },
  {
    name: "(+597) Suriname",
    phone: "597"
  },
  {
    name: "(+211) South Sudan",
    phone: "211"
  },
  {
    name: "(+239) São Tomé and Príncipe",
    phone: "239"
  },
  {
    name: "(+503) El Salvador",
    phone: "503"
  },
  {
    name: "(+1721) Sint Maarten",
    phone: "1721"
  },
  {
    name: "(+963) Syria",
    phone: "963"
  },
  {
    name: "(+268) Swaziland",
    phone: "268"
  },
  {
    name: "(+1649) Turks and Caicos Islands",
    phone: "1649"
  },
  {
    name: "(+235) Chad",
    phone: "235"
  },
  {
    name: "(+262) French Southern Territories",
    phone: "262"
  },
  {
    name: "(+228) Togo",
    phone: "228"
  },
  {
    name: "(+992) Tajikistan",
    phone: "992"
  },
  {
    name: "(+690) Tokelau",
    phone: "690"
  },
  {
    name: "(+670) East Timor",
    phone: "670"
  },
  {
    name: "(+993) Turkmenistan",
    phone: "993"
  },
  {
    name: "(+216) Tunisia",
    phone: "216"
  },
  {
    name: "(+676) Tonga",
    phone: "676"
  },
  {
    name: "(+90) Turkey",
    phone: "90"
  },
  {
    name: "(+1868) Trinidad and Tobago",
    phone: "1868"
  },
  {
    name: "(+688) Tuvalu",
    phone: "688"
  },
  {
    name: "(+886) Taiwan",
    phone: "886"
  },
  {
    name: "(+255) Tanzania",
    phone: "255"
  },
  {
    name: "(+380) Ukraine",
    phone: "380"
  },
  {
    name: "(+256) Uganda",
    phone: "256"
  },
  {
    name: "(+1) U.S. Minor Outlying Islands",
    phone: "1"
  },
  {
    name: "(+1) United States",
    phone: "1"
  },
  {
    name: "(+598) Uruguay",
    phone: "598"
  },
  {
    name: "(+998) Uzbekistan",
    phone: "998"
  },
  {
    name: "(+379) Vatican City",
    phone: "379"
  },
  {
    name: "(+1784) Saint Vincent and the Grenadines",
    phone: "1784"
  },
  {
    name: "(+58) Venezuela",
    phone: "58"
  },
  {
    name: "(+1284) British Virgin Islands",
    phone: "1284"
  },
  {
    name: "(+1340) U.S. Virgin Islands",
    phone: "1340"
  },
  {
    name: "(+678) Vanuatu",
    phone: "678"
  },
  {
    name: "(+681) Wallis and Futuna",
    phone: "681"
  },
  {
    name: "(+685) Samoa",
    phone: "685"
  },
  {
    name: "(+377,381,383,386) Kosovo",
    phone: "377,381,383,386"
  },
  {
    name: "(+967) Yemen",
    phone: "967"
  },
  {
    name: "(+262) Mayotte",
    phone: "262"
  },
  {
    name: "(+27) South Africa",
    phone: "27"
  },
  {
    name: "(+260) Zambia",
    phone: "260"
  },
  {
    name: "(+263) Zimbabwe",
    phone: "263"
  }
];

export default countries;
