import React from "react";
import {
  BOOTHTYPE_ACTION_DELETE,
  BOOTHTYPE_ACTION_DELETED,
  BOOTHTYPE_ACTION_EX_STATUS_FETCH,
  BOOTHTYPE_ACTION_EX_STATUS_FETCHED,
  BOOTHTYPE_ACTION_ADMIN_FETCH,
  BOOTHTYPE_ACTION_ADMIN_FETCHED,
  BOOTHTYPE_ACTION_UPDATE,
  BOOTHTYPE_ACTION_USER_FETCH,
  BOOTHTYPE_ACTION_USER_FETCHED,
  BOOTHTYPE_ACTION_ADD,
  BOOTHTYPE_ACTION_ERROR,
  BOOTHTYPE_ACTION_ADD_SUCCESS
} from "./const";
import {
  takeLatest,
  takeLeading,
  takeEvery,
  call,
  select,
  put
} from "@redux-saga/core/effects";
import { axiosApi } from "../../utils/api";
import {
  APIExternal,
  APIInternal,
  APIMisc,
  APIReplParams
} from "../../utils/constant";
import { combineRootSagas } from "../../utils/store";
import EventActionType from "../event/const";
import { takeLoginAction } from "../sagas";
import { NotiActionsEnqueueToast } from "../noti/actions";
import { refineObject } from "../../utils/common";

function* spawnErr(error) {
  yield put({ type: BOOTHTYPE_ACTION_ERROR, error });
}

function* fetch() {
  // fetch exhibitor status
  yield takeLatest(BOOTHTYPE_ACTION_EX_STATUS_FETCH, function*() {
    const { data: exhibitor } = yield call(
      axiosApi.auth().get,
      APIMisc.GET_ALL_EXHIBITOR_STATUS
    );
    yield put({ type: BOOTHTYPE_ACTION_EX_STATUS_FETCHED, exhibitor });
  });

  // admin fetch boothType
  yield takeLatest(BOOTHTYPE_ACTION_ADMIN_FETCH, function*() {
    const { data: booth } = yield call(
      axiosApi.auth().get,
      APIInternal.BOOTH_TYPES_ALL
    );
    yield put({ type: BOOTHTYPE_ACTION_ADMIN_FETCHED, booth });
  });

  // user fetch boothType
  yield takeEvery(BOOTHTYPE_ACTION_USER_FETCH, function*({ eventId }) {
    const { data: booth } = yield call(
      axiosApi.auth().get,
      APIReplParams(APIExternal.BOOTH_TYPES_ALL, {
        eventId
      })
    );
    // console.log(booth);
    yield put({ type: BOOTHTYPE_ACTION_USER_FETCHED, booth });
  });

  // handle error
  yield takeEvery(BOOTHTYPE_ACTION_ERROR, function*({ error }) {
    yield put(
      NotiActionsEnqueueToast({
        message: (
          <div>
            <h5>BoothType error</h5>
            <span>{error?.message}</span>
          </div>
        ),
        options: { variant: "error", autoHideDuration: 2000 }
      })
    );
  });
}

function* addBoothType() {
  yield takeLeading(BOOTHTYPE_ACTION_ADD, function*({ eventId, form }) {
    try {
      const { data } = yield call(
          axiosApi.auth().post,
          APIReplParams(APIInternal.BOOTH_ADD_TYPE, { eventId }),
          refineObject(form)
        ),
        { event, err } = data;
      if (err) yield spawnErr(err);
      else {
        yield put({ type: BOOTHTYPE_ACTION_ADD_SUCCESS, boothType: event });
      }
    } catch (e) {
      yield spawnErr(e);
    }
  });
}

function* deleteBoothType() {
  yield takeLeading(BOOTHTYPE_ACTION_DELETE, function*({ id }) {
    try {
      yield call(
        axiosApi.auth().delete,
        APIReplParams(APIInternal.DEL_BOOTH_TYPE, { id })
      );
      yield put({ type: BOOTHTYPE_ACTION_DELETED, id });
    } catch (e) {
      console.error(e);
    }
  });
}

function* updateBoothType() {
  yield takeLeading(BOOTHTYPE_ACTION_UPDATE, function*({ id, eventId, data }) {
    yield call(
      axiosApi.auth().put,
      APIReplParams(APIInternal.BOOTH_EDIT_TYPE, { eventId, id }),
      refineObject(data)
    );
    yield put({ type: BOOTHTYPE_ACTION_ADMIN_FETCH });
  });
}

function* hooks() {
  yield takeLoginAction(null, function*() {
    yield put({ type: BOOTHTYPE_ACTION_EX_STATUS_FETCH });
  });
  /**
   * After admin login success
   */
  yield takeLoginAction(true, function*() {
    yield put({ type: BOOTHTYPE_ACTION_ADMIN_FETCH });
  });

  /**
   * After user loaded every event
   */
  yield takeLoginAction(false, function*() {
    yield takeLatest(EventActionType.FETCHED, function*() {
      const eventIds = Object.keys(yield select(state => state.event.list));
      for (let eventId of eventIds) {
        yield put({ type: BOOTHTYPE_ACTION_USER_FETCH, eventId });
      }
    });
  });
}

export default function* boothTypeSagas() {
  yield combineRootSagas(
    hooks,
    fetch,
    addBoothType,
    deleteBoothType,
    updateBoothType
  );
}
