import * as moment from "moment";
import React from "react";

export default class SimpleReactValidator {
  static locales = { en: {} };

  static addLocale(lang, messages) {
    this.locales[lang] = messages;
  }

  /**
   *
   * @type {{}}
   * @private
   */
  _fields = {};

  /**
   *
   * @type {*[]}
   * @private
   */
  _visibleFields = [];

  /**
   *
   * @type {{}}
   * @private
   */
  _errorMessages = {};

  /**
   *
   * @type {boolean}
   * @private
   */
  _messagesShown = false;

  /**
   *
   * @type {{}}
   * @private
   */
  _rules = {};

  constructor(options = {}) {
    const self = this;
    self.helpers = self._helpers;
    self._rules = {
      accepted: {
        message: "The :attribute must be accepted.",
        rule: val => val === true,
        required: true
      },
      after: {
        message: "The :attribute must be after :date.",
        rule: (val, params) =>
          moment.isMoment(val) && val.isAfter(params[0], "day"),
        messageReplace: (message, params) =>
          message.replace(":date", params[0].format("MM/DD/YYYY"))
      },
      after_or_equal: {
        message: "The :attribute must be after or on :date.",
        rule: (val, params) =>
          moment.isMoment(val) && val.isSameOrAfter(params[0], "day"),
        messageReplace: (message, params) =>
          message.replace(":date", params[0].format("MM/DD/YYYY"))
      },
      alpha: {
        message: "The :attribute may only contain letters.",
        rule: val => self._helpers.testRegex(val, /^[A-Z]*$/i)
      },
      alpha_space: {
        message: "The :attribute may only contain letters and spaces.",
        rule: val => self._helpers.testRegex(val, /^[A-Z\s]*$/i)
      },
      alpha_num: {
        message: "The :attribute may only contain letters and numbers.",
        rule: val => self._helpers.testRegex(val, /^[A-Z0-9]*$/i)
      },
      alpha_num_space: {
        message:
          "The :attribute may only contain letters, numbers, and spaces.",
        rule: val => self._helpers.testRegex(val, /^[A-Z0-9\s]*$/i)
      },
      alpha_num_dash: {
        message:
          "The :attribute may only contain letters, numbers, and dashes.",
        rule: val => self._helpers.testRegex(val, /^[A-Z0-9_-]*$/i)
      },
      alpha_num_dash_space: {
        message:
          "The :attribute may only contain letters, numbers, dashes, and spaces.",
        rule: val => self._helpers.testRegex(val, /^[A-Z0-9_-\s]*$/i)
      },
      array: {
        message: "The :attribute must be an array.",
        rule: val => Array.isArray(val)
      },
      before: {
        message: "The :attribute must be before :date.",
        rule: (val, params) =>
          moment.isMoment(val) && val.isBefore(params[0], "day"),
        messageReplace: (message, params) =>
          message.replace(":date", params[0].format("MM/DD/YYYY"))
      },
      before_or_equal: {
        message: "The :attribute must be before or on :date.",
        rule: (val, params) =>
          moment.isMoment(val) && val.isSameOrBefore(params[0], "day"),
        messageReplace: (message, params) =>
          message.replace(":date", params[0].format("MM/DD/YYYY"))
      },
      between: {
        message: "The :attribute must be between :min and :max:type.",
        rule: (val, params) =>
          self._helpers.size(val, params[2]) >= parseFloat(params[0]) &&
          self._helpers.size(val, params[2]) <= parseFloat(params[1]),
        messageReplace: (message, params) =>
          message
            .replace(":min", params[0])
            .replace(":max", params[1])
            .replace(":type", self._helpers.sizeText(params[2]))
      },
      boolean: {
        message: "The :attribute must be a boolean.",
        rule: val => val === false || val === true
      },
      card_exp: {
        message: "The :attribute must be a valid expiration date.",
        rule: val =>
          self._helpers.testRegex(
            val,
            /^(([0]?[1-9]{1})|([1]{1}[0-2]{1}))\s?\/\s?(\d{2}|\d{4})$/
          )
      },
      card_num: {
        message: "The :attribute must be a valid credit card number.",
        rule: val =>
          self._helpers.testRegex(val, /^\d{4}\s?\d{4,6}\s?\d{4,5}\s?\d{0,8}$/)
      },
      currency: {
        message: "The :attribute must be a valid currency.",
        rule: val =>
          self._helpers.testRegex(val, /^\$?(\d{1,3})(\,?\d{3})*\.?\d{0,2}$/)
      },
      date: {
        message: "The :attribute must be a date.",
        rule: val => moment.isMoment(val)
      },
      date_equals: {
        message: "The :attribute must be on :date.",
        rule: (val, params) =>
          moment.isMoment(val) && val.isSame(params[0], "day"),
        messageReplace: (message, params) =>
          message.replace(":date", params[0].format("MM/DD/YYYY"))
      },
      email: {
        message: "The :attribute must be a valid email address.",
        rule: val =>
          self._helpers.testRegex(
            val,
            /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
          )
      },
      in: {
        message: "The selected :attribute must be :values.",
        rule: (val, params) => params.includes(val),
        messageReplace: (message, params) =>
          message.replace(":values", self._helpers.toSentence(params))
      },
      integer: {
        message: "The :attribute must be an integer.",
        rule: val => self._helpers.testRegex(val, /^\d*$/)
      },
      max: {
        message: "The :attribute may not be greater than :max:type.",
        rule: (val, params) =>
          self._helpers.size(val, params[1]) <= parseFloat(params[0]),
        messageReplace: (message, params) =>
          message
            .replace(":max", params[0])
            .replace(":type", self._helpers.sizeText(params[1]))
      },
      min: {
        message: "The :attribute must be at least :min:type.",
        rule: (val, params) =>
          self._helpers.size(val, params[1]) >= parseFloat(params[0]),
        messageReplace: (message, params) =>
          message
            .replace(":min", params[0])
            .replace(":type", self._helpers.sizeText(params[1]))
      },
      not_in: {
        message: "The selected :attribute must not be :values.",
        rule: (val, params) => !params.includes(val),
        messageReplace: (message, params) =>
          message.replace(":values", self._helpers.toSentence(params))
      },
      not_regex: {
        message: "The :attribute must not match the required pattern.",
        rule: (val, params) =>
          !self._helpers.testRegex(
            val,
            typeof params[0] === "string" || params[0] instanceof String
              ? new RegExp(params[0])
              : params[0]
          )
      },
      numeric: {
        message: "The :attribute must be a number.",
        rule: val => self._helpers.testRegex(val, /^\-?\d*\.?\d+$/)
      },
      phone: {
        message: "The :attribute must be a valid phone number.",
        rule: val =>
          self._helpers.testRegex(
            val,
            /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)$/
          ) && !self._helpers.testRegex(val, /^\b(\d)\1{8,}\b$/)
      },
      regex: {
        message: "The :attribute must match the required pattern.",
        rule: (val, params) =>
          self._helpers.testRegex(
            val,
            typeof params[0] === "string" || params[0] instanceof String
              ? new RegExp(params[0])
              : params[0]
          )
      },
      required: {
        message: "The :attribute field is required.",
        rule: val => !self._helpers.isBlank(val),
        required: true
      },
      size: {
        message: "The :attribute must be :size:type.",
        rule: (val, params) =>
          self._helpers.size(val, params[1]) == parseFloat(params[0]),
        messageReplace: (message, params) =>
          message
            .replace(":size", params[0])
            .replace(":type", self._helpers.sizeText(params[1]))
      },
      string: {
        message: "The :attribute must be a string.",
        rule: val => typeof val === typeof "string"
      },
      typeof: {
        message: "The :attribute is not the correct type of :type.",
        rule: (val, params) => typeof val === typeof params[0],
        messageReplace: (message, params) =>
          message.replace(":type", typeof params[0])
      },
      url: {
        message: "The :attribute must be a url.",
        rule: val =>
          self._helpers.testRegex(
            val,
            /^https?:\/\/[-a-z0-9@:%._\+~#=]{1,256}\.[a-z0-9()]{2,6}\b([-a-z0-9()@:%_\+.~#?&//=]*)$/i
          )
      },
      ...(options.validators || {})
    };

    // apply language
    if (
      options.locale &&
      !SimpleReactValidator.locales.hasOwnProperty(options.locale)
    ) {
      console.warn(
        "Locale not found! Make sure it is spelled correctly and the locale file is loaded."
      );
    }
    const locale = SimpleReactValidator.locales[options.locale] || {};
    Object.keys(self._rules).forEach(key => {
      self._rules[key].message = locale[key] || self._rules[key].message;
    });

    // apply default options
    self.messages = options.messages || {};
    self.className = options.className;
    self.autoForceUpdate = options.autoForceUpdate || false;

    // apply default element
    if (options.element === false) {
      self.element = message => message;
    } else if (options.hasOwnProperty("element")) {
      self.element = options.element;
    } else if (
      typeof navigator === "object" &&
      navigator.product === "ReactNative"
    ) {
      self.element = message => message;
    } else {
      self.element = (message, className) =>
        React.createElement(
          "div",
          {
            className: className || self.className || "srv-validation-message"
          },
          message
        );
    }
  }

  getErrorMessages() {
    return this._errorMessages;
  }

  showMessages() {
    this._messagesShown = true;
    this._helpers.forceUpdateIfNeeded();
  }

  hideMessages() {
    this._messagesShown = false;
    this._helpers.forceUpdateIfNeeded();
  }

  showMessageFor = field => {
    if (!this._visibleFields.includes(field)) {
      this._visibleFields.push(field);
    }
    this._helpers.forceUpdateIfNeeded();
  };

  hideMessageFor = field => {
    const index = this._visibleFields.indexOf(field);
    if (index > -1) {
      this._visibleFields.splice(index, 1);
    }
    this._helpers.forceUpdateIfNeeded();
  };

  allValid() {
    for (let key in this._fields) {
      if (this.fieldValid(key) === false) {
        return false;
      }
    }
    return true;
  }

  fieldValid(field) {
    return this._fields.hasOwnProperty(field) && this._fields[field] === true;
  }

  purgeFields() {
    this._fields = {};
    this._errorMessages = {};
  }

  messageWhenPresent(message, options = {}) {
    if (!this._helpers.isBlank(message) && this._messagesShown) {
      return this._helpers.element(message, options);
    }
  }

  messageAlways(field, message, options = {}) {
    console.warn(
      "The messageAlways() method is deprecated in SimpleReactValidator. Please see the documentation and switch to the messageWhenPresent() method."
    );
    if (message && this._messagesShown) {
      return this._helpers.element(message, options);
    }
  }

  check(inputValue, validations, options = {}) {
    if (!Array.isArray(validations)) {
      validations = validations.split("|");
    }
    var rules = options.validators
      ? { ...this._rules, ...options.validators }
      : this._rules;
    for (let validation of validations) {
      let [value, rule, params] = this._helpers.normalizeValues(
        inputValue,
        validation
      );
      if (!this._helpers.passes(rule, value, params, rules)) {
        return false;
      }
    }
    return true;
  }

  message(field, inputValue, validations, options = {}) {
    this._errorMessages[field] = null;
    this._fields[field] = true;
    if (!Array.isArray(validations)) {
      validations = validations.split("|");
    }
    var rules = options.validators
      ? { ...this._rules, ...options.validators }
      : this._rules;
    for (let validation of validations) {
      let [value, rule, params] = this._helpers.normalizeValues(
        inputValue,
        validation
      );
      if (!this._helpers.passes(rule, value, params, rules)) {
        this._fields[field] = false;
        let message = this._helpers.message(rule, field, options, rules);

        if (params.length > 0 && rules[rule].hasOwnProperty("messageReplace")) {
          message = rules[rule].messageReplace(message, params);
        }

        this._errorMessages[field] = message;
        if (this._messagesShown || this._visibleFields.includes(field)) {
          return this._helpers.element(message, options);
        }
      }
    }
  }

  /**
   *
   * @type {{parent: SimpleReactValidator, isRequired(*, *): *, humanizeFieldName(*): *, getValidation(*=): (string), isBlank(*=): *, valueOrEmptyString(*=): (string|*), message(*, *=, *, *): *, toSentence(*): *, forceUpdateIfNeeded(): void, passes(*=, *=, *=, *=): boolean, size(*=, *=): (*|undefined), sizeText(*=): (string), getOptions(*=): (*|[*]|[]), normalizeValues(*=, *=): [*, *, *], testRegex(*, *=): boolean, element(*=, *): *}}
   * @private
   */
  _helpers = {
    parent: this,

    passes(rule, value, params, rules) {
      if (!rules.hasOwnProperty(rule)) {
        console.error(
          `Rule Not Found: There is no rule with the name ${rule}.`
        );
        return true;
      }
      if (!this.isRequired(rule, rules) && this.isBlank(value)) {
        return true;
      }
      return rules[rule].rule(value, params, this.parent) !== false;
    },

    isRequired(rule, rules) {
      return rules[rule].hasOwnProperty("required") && rules[rule].required;
    },

    isBlank(value) {
      return (
        typeof value === "undefined" ||
        value === null ||
        this.testRegex(value, /^[\s]*$/)
      );
    },

    normalizeValues(value, validation) {
      return [
        this.valueOrEmptyString(value),
        this.getValidation(validation),
        this.getOptions(validation)
      ];
    },

    getValidation(validation) {
      if (
        validation === Object(validation) &&
        !!Object.keys(validation).length
      ) {
        return Object.keys(validation)[0];
      } else {
        return validation.split(":")[0];
      }
    },

    getOptions(validation) {
      if (
        validation === Object(validation) &&
        !!Object.values(validation).length
      ) {
        var params = Object.values(validation)[0];
        return Array.isArray(params) ? params : [params];
      } else {
        var params = validation.split(":");
        return params.length > 1 ? params[1].split(",") : [];
      }
    },

    valueOrEmptyString(value) {
      return typeof value === "undefined" || value === null ? "" : value;
    },

    toSentence(arr) {
      return (
        arr.slice(0, -2).join(", ") +
        (arr.slice(0, -2).length ? ", " : "") +
        arr.slice(-2).join(arr.length > 2 ? ", or " : " or ")
      );
    },

    testRegex(value, regex) {
      return value.toString().match(regex) !== null;
    },

    forceUpdateIfNeeded() {
      if (this.parent.autoForceUpdate) {
        this.parent.autoForceUpdate.forceUpdate();
      }
    },

    message(rule, field, options, rules) {
      options.messages = options.messages || {};
      var message =
        options.messages[rule] ||
        options.messages.default ||
        this.parent.messages[rule] ||
        this.parent.messages.default ||
        rules[rule].message;
      return message.replace(":attribute", this.humanizeFieldName(field));
    },

    humanizeFieldName(field) {
      // supports snake_case or camelCase
      return field
        .replace(/([A-Z])/g, " $1")
        .replace(/_/g, " ")
        .toLowerCase();
    },

    element(message, options) {
      var element = options.element || this.parent.element;
      return element(message, options.className);
    },

    size(val, type) {
      // if an array or string get the length, else return the value.
      if (type === "string" || type === undefined || type === "array") {
        return val.length;
      } else if (type === "num") {
        return parseFloat(val);
      }
    },

    sizeText(type) {
      if (type === "string" || type === undefined) {
        return " characters";
      } else if (type === "array") {
        return " elements";
      } else {
        return "";
      }
    }
  };
}
