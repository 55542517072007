import React from "react";
import styles from "./UserTopBar.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as UserAvatarWhite } from "../../images/UserIcon.svg";
import { ReactComponent as UserAvatarDark } from "../../images/UserIconMono.svg";
import TopBarContext from "../../utils/TopBarContext";
import Dropdown from "../UI/Dropdown/Dropdown";
import { default as Dropdown2 } from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from "react-bootstrap/Button";
import { logout } from "../../store/user/actions";
import PrivilegedComponent from "../PrivilegedComponent/PrivilegedComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons/faUserPlus";
import UserTopBarExhibitorList from "./UserTopBarExhibitorList";
import UserTopBarExhibitorName from "./UserTopBarExhibitorName";
import { useHistory } from "react-router";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Home from "@material-ui/icons/Home";

const DefaultUserAvatar = {
  light: UserAvatarWhite,
  dark: UserAvatarDark
};

function UserTopBar() {
  const userObj = useSelector(state => state.user.obj),
    dispatch = useDispatch(),
    history = useHistory();
  if (!userObj) return null;
  return (
    <TopBarContext.Consumer>
      {({ theme }) => {
        const DefaultUser = DefaultUserAvatar[theme];

        return (
          <div
            className={`d-flex flex-row-reverse align-items-center top-bar-theme-${theme}`}
          >
            <span className={styles.Logout}><UserTopBarExhibitorName /></span>
            <PrivilegedComponent role={"USER"}>
              <b><UserTopBarExhibitorList /></b>
              <span className="text-primary mr-2">|</span>
            </PrivilegedComponent>
            <div className={styles.UserName}>
              <b>{userObj.first_name} {userObj.last_name}</b>
            </div>
            <PrivilegedComponent role={"ADMIN"}>
              <Button
                className={"mr-3"}
                onClick={() => {
                  history.push("/internal/register");
                }}
              >
                <FontAwesomeIcon icon={faUserPlus} className={"mr-3"} />
                Create new user
              </Button>
            </PrivilegedComponent>
          </div>
        );
      }}
    </TopBarContext.Consumer>
  );
}

export default UserTopBar;

export const InternalUserTopBar = () => {
  const userObj = useSelector(state => state.user.obj),
        dispatch = useDispatch(),
        history = useHistory();
  
  const UserTopBar = React.forwardRef(({ onClick }, ref) => (
    <div
      className={styles.Icon}
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      <UserAvatarWhite id={"UserTopBar__avatar"} />
    </div>
  ))
  
  if (!userObj) return null;
  return (
    <div
      className={`d-flex flex-row-reverse align-items-center top-bar-theme-light`}
    >
      <Dropdown2>
        <Dropdown2.Toggle as={UserTopBar} />
        <Dropdown2.Menu align={'right'} className={styles.Dropdown}>
          <div className={styles.DropdownBg}>
            <Dropdown2.Header className={styles.DropdownHeader}>
              <strong>
                {userObj.first_name} {userObj.last_name}
              </strong>
              <div>{userObj.email}</div>
            </Dropdown2.Header>
            <Dropdown2.Divider />
            {/* <Dropdown2.Item href="#/Profile" align={'end'}>Profile</Dropdown2.Item> */}
            <Dropdown2.Item className={`d-flex align-items-end ${styles.DropdownItem}`} onClick={() => dispatch(logout())}>
              <ExitToAppIcon />&ensp;Logout
            </Dropdown2.Item>
          </div>
        </Dropdown2.Menu>
      </Dropdown2>
      <div style={{marginRight: `1rem`}}>
        <strong>{userObj.first_name} {userObj.last_name}</strong>
      </div>
      <span className={`text-primary`} style={{marginRight: `1rem`}}>|</span>
      <DragIndicatorIcon className={styles.AdminTools} />
      <NotificationsIcon className={styles.AdminTools} />
      <Home
        className={styles.AdminTools}
        onClick={() => {
          history.push("/")
        }}
      />
      {/* <PersonAddIcon
        className={styles.AdminTools}
        onClick={() => {
          history.push("/internal/register")
        }}
      /> */}
    </div>
  )
}