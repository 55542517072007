import React from "react";
import { withParentRouter } from "../../HOCs/withAppModal";
import { branch, compose, renderNothing } from "../../utils/common";
import withProps from "../../components/_helpers/withProps";
import isEmpty from "lodash/isEmpty";
import { withRouter } from "react-router";
import { connect, useDispatch } from "react-redux";
import SimpleTable from "../../components/UI/SimpleTable/SimpleTable";
import { formatter } from "../../utils/datetime";
import sortBy from "lodash/sortBy";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { confirmAlert } from "react-confirm-alert";
import ConfirmPaymentDialog from "../../fragments/BookingOrderDetail/ConfirmPaymentDialog";
import { BOOKING_ACTION_FETCH_ORDER } from "../../store/booking/const";
import { Button, Table } from "reactstrap";
import ApprovePaymentDialog from "../../fragments/BookingOrderDetail/ApprovePaymentDialog";
import PageTitle from "../../components/UI/PageTitle/PageTitle";
import withRole from "../../HOCs/withRole";
import { BookingSelectorGetOrders } from "../../store/booking/selector";
import PrivilegedComponent from "../../components/PrivilegedComponent/PrivilegedComponent";
import moment from "moment";
import { Link } from "react-router-dom";
import CancelPaymentDialog from "./../../fragments/BookingOrderDetail/CancelPaymentDialog.js";
import StripeCheckoutForm from "./../../fragments/BookingOrderDetail/StripeCheckoutForm.js";
import PaynowCheckoutForm from "./../../fragments/BookingOrderDetail/PaynowCheckoutForm.js";
import { Elements, StripeProvider } from "react-stripe-elements";
import styles from "./BookingPaymentManagement.module.scss";
import * as Icons from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

import PaymentBatchDetail from "./PaymentBatchDetail";
import { Select, Input, InputLabel, FormControl, MenuItem, Badge } from "@material-ui/core";
import withOrders from "../../controllers/withOrders/withOrders";
import { axiosApi } from "../../utils/api";
import { APIInternal, APIReplParams } from "../../utils/constant";

const payment_methods = [
  { value: "CASH", label: "Cash" },
  { value: "CREDIT_CARD", label: "Credit Card" },
  { value: "PAYNOW", label: "PayNow" },
  { value: "BANK_TRANSFER", label: "Bank Transfer" }
];

const payment_status = [
  { value: 1, label: "Enabled" },
  { value: 0, label: "Disabled" }
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
  },
}));

const AdminDetail = compose(
  withParentRouter,
  withRouter,
  withProps(props => ({ orderId: props.match.params.id * 1 })),
  withRole,
  withOrders,
  connect((state, props) => ({
    order: props.role.isNotClient
      ? state.booking?.order[props.orderId]
      : BookingSelectorGetOrders(state)[props.orderId],
    user: state.user
  })),
  branch(props => isEmpty(props.order), renderNothing)
)(function AdminDetail({ order, user, close }) {
  const classes = useStyles();
  
  const [selectedPayment, selectPayment] = React.useState(null);
  const [isOpenStripeDlg, openStripeDlg] = React.useState(false);
  const [isOpenPaynowDlg, openPaynowDlg] = React.useState(false);
  const [isOpenBatchDetail, openBatchDetail] = React.useState(false);
  const [value, setValue] = React.useState("1");
  const [accessoryMap, setAccessoryMap] = React.useState([{}]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();
  let payments = order.payments;
  if (!Array.isArray(payments)) payments = [];
  const getStatus = (isPaid, isApproved) => {
    return isApproved
      ? "Approved"
      : isPaid
      ? "Confirmed"
      : "Waiting for payment";
  };

  const appendNewRow = () =>  {
    setAccessoryMap([
      ...accessoryMap,
      {}
    ])
  }

  const removeRow = index => {
    setAccessoryMap(accessoryMap.filter((v, i) => i !== index))
  }

  const fetchOrder = () => {
    dispatch({ type: BOOKING_ACTION_FETCH_ORDER });
  };
  const onConfirm = payment => {
    confirmAlert({
      customUI: dlgpPops => {
        return (
          <ConfirmPaymentDialog
            payment={payment}
            {...dlgpPops}
            auth={user}
            fetchOrder={fetchOrder}
          />
        );
      }
    });
  };

  const onApprove = (order) => {
    confirmAlert({
      title: "Confirm?",
      message: `Would you want to approve this booking?`,
      buttons: [
          {
              label: 'Confirm',
              onClick: () => {
                axiosApi
                .auth(user)
                .put(APIReplParams(APIInternal.APPROVE_BOOKING, { id: order.id }))
                .then(({ data, err }) => {
                  if (err) {
                    confirmAlert({
                      title: "Error",
                      message: `Failed to approve this booking`,
                      buttons: [
                        {
                          label: 'Close'
                        }
                      ]
                    });
                    // console.log("Cannot get ewallet logs")
                  } else {
                    confirmAlert({
                      title: "Successfully",
                      message: `Approved this booking`,
                      buttons: [
                        {
                          label: 'Close'
                        }
                      ]
                    });
                    fetchOrder();
                  }
                });
              }
          }
      ]
    });
  },
    onPay = payment => {
      selectPayment(payment);
      openStripeDlg(true);
    },
    onPayNow = payment => {
      selectPayment(payment);
      openPaynowDlg(true);
    },
    onDetail = payment => {
      selectPayment(payment);
      openBatchDetail(true);
    },
    onCancel = order => {
      confirmAlert({
        customUI: dlgpPops => {
          return (
            <CancelPaymentDialog
              order={order}
              {...dlgpPops}
              auth={user}
              fetchOrder={fetchOrder}
            />
          );
        }
      });
    },
    headers = {
      id: { title: "Batch Id", hidden: true },
      is_paid: { title: "", hidden: true },
      is_approved: { title: "", hidden: true },
      payment_no: { title: "Payment No." },
      invoice_no: { title: "Invoice No." },
      short_amount_paid: {
        title: "Amount Paid",
        display: v => formatter.format(v)
      },
      payment_date: { title: "Payment Date" },
      payment_due: { title: "Payment Due" },
      payment_method: {
        title: "Payment Method",
        display: v => <>{ v }</>
      },
      payable: {
        title: "Payment Status",
        display: (v, row) =>
          v === true ? (
            <span className="text-primary">
              <strong>{getStatus(row.is_paid, row.is_approved)}</strong>
            </span>
          ) : (
            <span>--</span>
          )
      },
      invoice_url: {
        title: "Attachments",
        display: v =>
          v && (
            <a href={`${process.env.REACT_APP_API_HOST_SHORT}${v}`}>
              <Icons.AttachFile></Icons.AttachFile>
              <u>E-invoice</u>
            </a>
          )
      },
      ...(user.obj?.role !== "USER" ? {
        status: {
          title: "Status",
          
          display: v =>
            v === 0 ? (
              <span className="text-secondary">
                <strong>Disabled</strong>
              </span>
            ) : (
              <span className="text-primary">
                <strong>Enabled</strong>
              </span>
            )
        },
        message: { 
          title: "Note",
          display: v => <Row style={{ width: '200px'}}>{v}</Row>
        }
      } : {}),
      action: {
        title: "Actions",
        hideTitle: true,
        display: (_, payment) =>
          <>
            <PrivilegedComponent role={"FINANCE+"}>
              <Row style={{ width: '300px'}}>
                  <Button
                    className="mr-4"
                    onClick={() =>
                      onConfirm({
                        ...payment,
                        order_no: order.order_no
                      })
                    }
                    disabled={
                      order.status === -1 ||
                      // !payment.payable ||
                      payment.is_paid ||
                      payment.is_approved
                    }
                    color={"primary"}
                  >
                    {"Confirm"}
                  </Button>
                  {/* <Button
                    onClick={() =>
                      onApprove({
                        ...payment,
                        order_no: order.order_no
                      })
                    }
                    disabled={
                      order.status === -1 ||
                      // !payment.payable ||
                      !(payment.is_paid === 1) ||
                      payment.is_approved
                    }
                    color={"primary"}
                    className="mx-1"
                  >
                    {"Approve"}
                  </Button> */}
                  <Button
                    onClick={() =>
                      onDetail({
                        ...payment,
                        order_no: order.order_no,
                        is_view_mode: payment.is_paid || payment.is_approved
                      })
                    }
                    color={"primary"}
                  >
                    {"Detail"}
                  </Button>
              </Row>
            </PrivilegedComponent>
            {
              order.status !== -1 && <PrivilegedComponent role={"USER"}>
              <Row>
                <Col>
                  {payment.payable === true &&
                    !payment.is_paid &&
                    !payment.is_approved &&
                    payment.payment_method === "CREDIT_CARD" && (
                      <Button
                        onClick={() =>
                          onPay({
                            ...payment,
                            order_no: order.order_no
                          })
                        }
                        color={"primary"}
                        className="mr-2 sw-100"
                      >
                        {"Pay"}
                      </Button>
                    )} 

                  {payment.payable === true &&
                    !payment.is_paid &&
                    !payment.is_approved &&
                    payment.payment_method === "PAYNOW" && (
                      <Button
                        onClick={() =>
                          onPayNow({
                            ...payment,
                            order_no: order.order_no
                          })
                        }
                        color={"primary"}
                        className="mr-2 sw-100"
                      >
                        {"PayNow"}
                      </Button>
                    )}
                </Col>
              </Row>
            </PrivilegedComponent>
            }
          </>
      }
    };
    const contact = isEmpty(order.description)  ? {} : JSON.parse(order.description);

  return (
    <div className={"px-5 pt-4"}>
      <PageTitle className={"h2 mb-2 pb-0"}>Order {order.order_no} <Button disabled className="ml-4" color={order.status === -1 ? "secondary" : "primary" }>{order.status === -1 ? "CANCELLED" : order.status === 1 ? "ACTIVE" : "PENDING APPROVAL"}</Button></PageTitle>
      
      <span>
        Ordered at: <i>{moment(order.created_at).format("DD-MM-YYYY HH:mm")}</i>
      </span>
      { order.status === -1 && 
        <div>
          Status: <strong>Cancelled</strong>
        </div>
      }
      <div className="row mt-3">
        <div className="col-6">
          <div>
            <strong>Event:</strong>
          </div>
          <div>
            <Link to={`/event/${order.event_id}`}>
              <u>{order.event_name}</u>
            </Link>
          </div>
          <div>{order.event_address}</div>
          <div>
            {moment(order.starting_datetime).format("DD/MM/YYYY")} -{" "}
            {moment(order.end_datetime).format("DD/MM/YYYY")}
          </div>
        </div>
        <div style={{ float: "right" }} className="col-6">
          <div>
            <strong>Exhibitor:</strong>
          </div>
          <PrivilegedComponent role={"USER"}>
            <div>
              <Link to={`/exhibitor/detail/?tab=0`}>
                <u>{order.brand_name}</u>
              </Link>
            </div>
          </PrivilegedComponent>
          <PrivilegedComponent role={"FINANCE+"}>
            <div>
              <Link
                to={`/internal/exhibitor/detail/${order.exhibitor_id}?tab=0`}
              >
                <u>{order.brand_name}</u>
              </Link>
            </div>
          </PrivilegedComponent>
          <div>Address: {order.exhibitor_address}</div>
          <div>Mobile: {order.exhibitor_mobile}</div>
          <div>Email: {order.exhibitor_email}</div>
        </div>
      </div>
      <div className="col-6 mt-3">
          <strong>Contact information:</strong>
          <div>Contact: {contact.contact} - Email: {contact.contactEmail} - {contact.firstName} {contact.lastName}</div>
          <strong>Billing information:</strong>
          <div>Address: {contact.address}</div>
          <div>Postal Code: {contact.postal}</div>
          <div>Mobile: {contact.phoneNumber}</div>
          
        </div>
      <hr />

    

      <div className={classes.root}>
      <TabContext value={value}>
        <AppBar position="static">
          <TabList onChange={handleChange} aria-label="Booking details">
            <Tab label="Payment Details" value="1" />
            <Tab label="Booking Booths" value="2" indicatorColor="primary"
          textColor="primary"/>
            {/* <Tab label="Accessories" value="2" /> */}
          </TabList>
        </AppBar>
        <TabPanel value="1">
          <h5 className={"mt-4"}>
            Payment batches
            <PrivilegedComponent role={"FINANCE+"}>
              <Button
                onClick={onDetail}
                color={"primary"}
                style={{ float: "right" }}
                className="mb-3"
              >
                {"New payment batch"}
              </Button>
            </PrivilegedComponent>
          </h5>
          <SimpleTable
            data={sortBy(payments, "payment_no")}
            headers={headers}
          />
        </TabPanel>
        <TabPanel value="2">
         <h5>Booking details</h5>
          <Table style={{ border: "1px solid #dee2e6" }}>
            <thead>
              <tr>
                <th>No</th>
                <th>Booth</th>
                <th>Type</th>
                <th>Description</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {order?.booking_booths?.map((booth, index) => (
                <tr style={{ backgroundColor: "#aad6f440" }}>
                  <td>{index + 1}</td>
                  <td>
                    {booth.booth_name} ({booth.booth_no})
                  </td>
                  <td>{booth.booth_type_name}</td>
                  <td>{booth.booth_description}</td>
                  <td>${booth.snapshot_price.toFixed(2)}</td>
                </tr>
              ))}
              <tr>
                <td>
                  <strong>Subtotal</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <strong>${order.subtotal.toFixed(2)}</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Credit Amount</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <strong>${order?.credit?.toFixed(2) || "0.00"}</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Promotion</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <strong>-${order?.promotion_value?.toFixed(2) || "0.00"}</strong>
                </td>
              </tr>
              {/* <tr>
                  <td>
                    <strong>Promotion</strong>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <strong>-${order.promotion_value?.toFixed(2) || "0.00"}</strong>
                  </td>
                </tr> */}
              <tr>
                <td>
                  <strong>GST</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <strong>${order.tax.toFixed(2)}</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Grand Total</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <strong>
                    <h4 className="text-primary">
                      ${order.grand_total.toFixed(2)}
                    </h4>
                  </strong>
                </td>
              </tr>
            </tbody>
          </Table>
          {/* <Button  
          onClick={appendNewRow}
          // disabled={order.status === -1}
          color={"secondary"}
          className="mt-2 mb-4"
          style={{ float: "right", width: 200 }}>
            New accessory
          </Button> */}

        </TabPanel>
        <TabPanel value="3">
          <h5>Accessories</h5>
          <Table style={{ border: "1px solid #dee2e6" }}>
            <thead>
              <tr>
                <th>No</th>
                <th style={{ width: 200 }}>Booth No</th>
                <th style={{ width: 200 }}>Accessory</th>
                <th>Price</th>
                <th>Qty</th>
                <th>Note</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {accessoryMap.map((row, index) => (
                <tr style={{ backgroundColor: "#aad6f440" }}>
                  <td style={{ paddingTop: '1.5rem' }}>{index + 1}</td>
                  <td >
                    <FormControl
                      variant="outlined"
                      // style={{ width: "30%", marginRight: "4%" }}
                    >
                      <InputLabel id="country-select-outlined-label">Booth No.</InputLabel>
                      <Select
                        labelId="country-select-outlined-label"
                        label="Booth No."
                        labelWidth={80}
                        style={{ width: 200 }}
                      >
                        {order?.booking_booths?.map(b => (
                          <MenuItem value={b.booth_no}>{b.booth_name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                 
                  </td>
                  <td>
                    <FormControl
                        variant="outlined"
                        // style={{ width: "30%", marginRight: "4%" }}
                      >
                        <InputLabel id="country-select-outlined-label">Accessory</InputLabel>
                        <Select
                          labelId="country-select-outlined-label"
                          label="Accessory"
                          labelWidth={80}
                          style={{ width: 200 }}
                        >
                          {/* {order?.booking_booths?.map(b => (
                            <MenuItem value={b.booth_no}>{b.booth_name}</MenuItem>
                          ))} */}
                        </Select>
                      </FormControl>
                  </td>
                  <td style={{ paddingTop: '1.5rem' }}>$10.00</td>
                  <td>
                    <FormControl
                          variant="outlined"
                          // style={{ width: "30%", marginRight: "4%" }}
                        >
                      <Input type="number" placeholder="Qty."  variant="outlined" />
                    </FormControl>
                  </td>
                  <td>
                    <FormControl
                          variant="outlined"
                          // style={{ width: "30%", marginRight: "4%" }}
                        >
                      <Input type="text" placeholder="Notes" />
                    </FormControl>

                  </td>
                  <td>
                  <Button  
                    onClick={() => removeRow(index)}
                    // disabled={order.status === -1}
                    color={"secondary"}
                    // className="mt-2"
                    style={{ width: 40, height: 40, borderRadius: 20 }}>
                      -
                    </Button>
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <strong>Subtotal</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <strong>$0.00</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>GST</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <strong>$0.00</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Grand Total</strong>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <strong>
                    <h4 className="text-primary">
                      $0.00
                    </h4>
                  </strong>
                </td>
              </tr>
            </tbody>
          </Table>
          <Button  
          onClick={appendNewRow}
          // disabled={order.status === -1}
          color={"secondary"}
          className="mt-2 mb-4"
          style={{ float: "right", width: 200 }}>
            New accessory
          </Button>

        </TabPanel>

      </TabContext>
    </div>
      {/* <hr /> */}

  
     
      <PrivilegedComponent role={"FINANCE+"}>
 
        <Button
          onClick={() => onCancel(order)}
          disabled={order.status === -1}
          color={"secondary"}
          className="mt-3 mb-5 mr-5"
          style={{ width: 200 }}
        >
          {"Cancel booking"}
        </Button>
        <Button
          onClick={() => onApprove(order)}
          disabled={order.status !== 2}
          color={"primary"}
          className="mt-3 mb-5 mr-5"
          style={{ width: 200 }}
        >
          {"Approve"}
        </Button>
      </PrivilegedComponent>
      {isOpenStripeDlg && (
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PK}>
          <Elements>
            <div className={styles.StripeFormBg}>
              <StripeCheckoutForm
                onClose={() => openStripeDlg(false)}
                payment={{
                  ...selectedPayment,
                  exhibitor_id: order.exhibitor_id
                }}
                auth={user}
                fetchOrder={fetchOrder}
              />
            </div>
          </Elements>
        </StripeProvider>
      )}

      {isOpenPaynowDlg && (
        <div className={styles.StripeFormBg}>
          <PaynowCheckoutForm
            onClose={() => openPaynowDlg(false)}
            payment={{
              ...selectedPayment,
              exhibitor_id: order.exhibitor_id
            }}
          />
        </div>
      )}

      {isOpenBatchDetail && (
        <div className={styles.StripeFormBg}>
          <PaymentBatchDetail
            onClose={() => openBatchDetail(false)}
            payment={{
              order_id: order.id,
              ...selectedPayment,
              exhibitor_id: order.exhibitor_id
            }}
            auth={user}
            fetchOrder={fetchOrder}
            paymentMethods={payment_methods}
            paymentStatus={payment_status}
          />
        </div>
      )}
    </div>
  );
});

export default AdminDetail;
