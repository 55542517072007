import React from "react";
import { compose } from "../../../utils/common";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { withSimpleTableContext } from "./utils";
import withProps from "../../_helpers/withProps";
import CreateIcon from "@material-ui/icons/Create";
import CheckIcon from "@material-ui/icons/Check";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import SimpleTableCell from "./SimpleTableCell";
const SimpleTableRow = compose(
  withSimpleTableContext,
  withProps(props => ({
    isEditingRow: props.index === props.editRow
  }))
)(function SimpleTableRow({
  data,
  row,
  index,
  headers,
  display,
  editable,
  editRow,
  setEditRow,
  isEditingRow,
  options,
  forEachHeader,
  callbacks,
  setLoading
}) {
  const [rowData, setRowData] = React.useState({});

  React.useEffect(() => {
    const _rowData = {};
    forEachHeader((field, header) => {
      _rowData[field] = display(field, row, !header.lockEdit && !header.hidden);
    });
    setRowData(_rowData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingRow, row]);

  const updateField = (field, value) => {
    const _rowData = { ...rowData };
    _rowData[field] = value;
    setRowData(_rowData);
  };

  const displayEditHeader = editable && (
    <TableCell>
      {!isEditingRow ? (
        <CreateIcon fontSize={"small"} onClick={() => setEditRow(index)} />
      ) : (
        <CheckIcon
          fontSize={"small"}
          onClick={async () => {
            if (typeof callbacks.onRowEdited === "function") {
              try {
                setLoading(true);
                await callbacks.onRowEdited(rowData, editRow);
                setEditRow(-1);
              } catch (e) {
                console.error(e);
              } finally {
                setLoading(false);
              }
            }
          }}
        />
      )}
      {typeof callbacks.onRowDelete === "function" && (
        <DeleteOutlineIcon
          fontSize={"small"}
          className={"ml-2"}
          onClick={async () => {
            try {
              setLoading(true);
              await callbacks.onRowDelete(rowData);
              setEditRow(-1);
            } catch (e) {
              console.error(e);
            } finally {
              setLoading(false);
            }
          }}
        />
      )}
    </TableCell>
  );
  return (
    <TableRow>
      {options.rowEditAtStart === true && displayEditHeader}
      {Object.keys(headers).map(field => (
        <SimpleTableCell
          row={rowData}
          fieldData={rowData[field]}
          header={headers[field]}
          field={field}
          isEditingRow={isEditingRow}
          setRowData={setRowData}
          updateField={updateField}
        />
      ))}
      {options.rowEditAtStart !== true && displayEditHeader}
    </TableRow>
  );
});

export default SimpleTableRow;
