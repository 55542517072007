import React from "react";
import styles from "./UserProfile.module.scss";
import * as classnames from "classnames";
import PageTitle from "../../components/UI/PageTitle/PageTitle";
import PrivilegedComponent from "../../components/PrivilegedComponent/PrivilegedComponent";
import { withTopBarContext } from "../../utils/TopBarContext";
import UserTopBar from "../../components/UserTopBar/UserTopBar";
import { connect } from "react-redux";
import { ExhibitorActionFetchAll } from "../../store/exhibitor/actions";
import { withRouter } from "react-router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import withProps from "../../components/_helpers/withProps";
import FontAwesomeCircle from "../../components/UI/FontAwesomeCircle/FontAwesomeCircle";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import Form from "react-bootstrap/Form";
import withSimpleForm from "../../utils/SimpleForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import ReactSelect from "../../components/UI/ReactSelect/ReactSelect";
import { diff } from "deep-object-diff";
import cloneDeep from "lodash/cloneDeep";
import { datetime } from "../../utils/datetime";
import { parseUserStatus } from "../../utils/common";
import { axiosApi } from "../../utils/api";
import { adminFetchIUsers } from "../../store/admin/actions";
import { APIReplParams } from "../../utils/constant";
import { APIInternal } from "../../utils/constant";
import withToast from "../../HOCs/withToast";

@withTopBarContext()
@connect(state => ({
  users: state.admin?.eUsers,
  user: state.user
}))
@withRouter
@withProps(props => ({
  currentUser:
    Array.isArray(props.users) &&
    props.users.length > 0 &&
    props.match.params.email
      ? props.users.find(u => u.email === props.match.params.email)
      : null
}))
@withSimpleForm({
  entries: props => (props.currentUser ? cloneDeep(props.currentUser) : {})
})
@withToast
class UserProfile extends React.Component {
  state = {
    loading: true,
    toggleField: "",
    addUser: false,
    currentUser: {}
  };
  // noinspection JSCheckFunctionSignatures
  componentDidMount() {
    const { dispatch, users = [] } = this.props;
    // topBarContext.setRightButton(() => <UserTopBar />);
    if (users.length === 0) {
      dispatch(ExhibitorActionFetchAll());
    }
    this.setState({ loading: false });
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.currentUser !== null &&
      JSON.stringify(diff(state.currentUser, props.currentUser)) !== "{}"
    )
      return { currentUser: props.currentUser };
    return {};
  }

  /**
   *
   * @private
   */
  _onSubmit() {
    const { form, dispatch, history } = this.props;
    axiosApi
      .auth()
      .put(
        APIReplParams(APIInternal.MODIFY_USER_PROFILE, {
          id: form.id
        }),
        form
      )
      .then(({ data }) => {
        if (data && data.success) {
          dispatch(adminFetchIUsers);
          history.push(`/internal/user-info/${form.email}`);
          this.setState({ toggleField: "" });
          this.props.toastSuccess("Modify user profile successfully");
        } else {
          this.props.toastError("There was a problem. Please try again.");
        }
      });
  }

  get diff() {
    if (
      JSON.stringify(this.props.form) === "{}" ||
      JSON.stringify(this.state.currentUser) === "{}"
    )
      return false;
    return (
      JSON.stringify(diff(this.props.form, this.state.currentUser)) !== "{}"
    );
  }

  /**
   *
   * @param label
   * @param value
   * @param editable
   * @param field
   * @param inputType
   * @param data
   * @returns {*}
   * @private
   */
  _renderEditField = (
    label,
    value,
    { editable = false, field = "", inputType = "text", data = [] } = {}
  ) => {
    const toggleField = this.state.toggleField;
    let valueDisplay = <div className={styles.EditFieldControl}>{value}</div>;
    if (editable && (toggleField === field || toggleField === "all")) {
      if (inputType === "select") {
        valueDisplay = (
          <div className={styles.EditFieldControl}>
            <ReactSelect
              options={data}
              value={data.find(x => x.value === this.props.form[field])}
              onChange={target => this.props.formHandler(field)({ target })}
            />
          </div>
        );
      } else {
        valueDisplay = (
          <div className={styles.EditFieldControl}>
            <Form.Control type={inputType} {...this.props.formBinding(field)} />
          </div>
        );
      }
    }

    return (
      <div>
        <div
          className={classnames("py-3", styles.EditField, {
            [styles.Toggle]: field === this.state.toggleField
          })}
        >
          {editable && (
            <FontAwesomeCircle
              className={styles.EditFieldButton}
              icon={faPencilAlt}
              onClick={() => {
                if (!editable) return;
                if (toggleField === "all") return;
                this.setState(state => ({
                  toggleField: state.toggleField === field ? "" : field
                }));
              }}
            />
          )}
          <div className={styles.EditFieldLabel}>
            <strong className="mr-3">{label}:</strong>
          </div>
          {valueDisplay}
        </div>
      </div>
    );
  };

  /**
   *
   * @returns {null|*}
   * @private
   */
  _renderEditPanel = () => {
    const { form: currentUser } = this.props;
    if (JSON.stringify(currentUser) === "{}") return null;
    return (
      <Row className={styles.EditPanelOuter}>
        <Col md={12}>
          <Form className={styles.EditPanel}>
            <h4 className="text-primary mb-4">
              {currentUser.email}
              <PrivilegedComponent role={"USER"}>
                <small
                  className="text-secondary ml-3"
                  style={{
                    fontSize: "70%",
                    fontWeight: "500",
                    cursor: "pointer"
                  }}
                  onClick={() =>
                    this.setState(({ toggleField: old }) => ({
                      toggleField: old === "all" ? "" : "all"
                    }))
                  }
                >
                  <FontAwesomeIcon icon={faPencilAlt} className={"mr-1"} /> Edit
                  user
                </small>
              </PrivilegedComponent>
            </h4>
            <Row>
              <Col>
                {this._renderEditField("Email", currentUser.email, {
                  editable: true,
                  field: "email"
                })}
              </Col>
              <Col>
                {this._renderEditField(
                  "Status",
                  parseUserStatus(currentUser.status),
                  {
                    editable: true,
                    field: "status",
                    inputType: "select",
                    data: [
                      { value: 1, label: "Active" },
                      { value: 0, label: "Unactive" }
                    ]
                  }
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {this._renderEditField("First name", currentUser.first_name, {
                  editable: true,
                  field: "first_name"
                })}
              </Col>
              <Col>
                {this._renderEditField("Last name", currentUser.last_name, {
                  editable: true,
                  field: "last_name"
                })}
              </Col>
            </Row>

            <Row>
              <Col>
                {this._renderEditField("Mobile", currentUser.mobile, {
                  editable: true,
                  field: "mobile"
                })}
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                {this._renderEditField(
                  "Created at",
                  datetime(currentUser.created_at),
                  {
                    editable: false
                  }
                )}
              </Col>
              <Col>
                {this._renderEditField(
                  "Modified at",
                  datetime(currentUser.updated_at),
                  {
                    editable: false
                  }
                )}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  };

  render() {
    if (this.props.loading) return null;
    return (
      <div
        className={classnames(
          styles.ExhibitorDetail,
          /internal/.test(this.props.location.pathname) ? "" : styles.External
        )}
      >
        <div className={"d-flex align-items-top"}>
          <PageTitle className={"h2 mr-3"}>Edit user profile</PageTitle>
        </div>

        {this._renderEditPanel()}
        <div className={styles.BottomPanel}>
          <div className="d-flex flex-row-reverse">
            <Button disabled={!this.diff} onClick={this._onSubmit.bind(this)}>
              Save changes
            </Button>
            <Button
              variant={"secondary"}
              className={"mr-2"}
              onClick={() => this.props.history.push("/internal/users")}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default UserProfile;
