import React from "react";
import hoistNonReactStatics from "hoist-non-react-statics";
import { setDisplayName } from "./setDisplayName";

const withProps = propsFn => Component => {
  const withPropsWrapper = React.memo(function(props) {
    function throwError() {
      throw new Error(
        "propsFn must be valid javascript pure object or function which accept props as first parameter"
      );
    }
    //validate propFn must be valid object or function
    if (typeof propsFn === "undefined" || propsFn === null) throwError();
    if (typeof propsFn !== "function" && typeof propsFn !== "object")
      throwError();
    const newProps = Object.assign(
      {},
      props,
      typeof propsFn === "object" ? propsFn : propsFn(props)
    );
    return <Component {...newProps} />;
  });
  hoistNonReactStatics(withPropsWrapper, Component);
  setDisplayName(withPropsWrapper, "withProps", Component);
  return withPropsWrapper;
};

export default withProps;
