import React, { useState, useEffect } from "react";
import styles from "./LoginBackgroundMobile.module.scss";
// import * as PropTypes from "prop-types";
import { ReactComponent as QooeeLogo } from "../../images/QooeeLogo.svg";
import { ReactComponent as Background } from "./LoginBackgroundSecondaryMobile.svg";
import { ReactComponent as BackgroundAdmin } from "./LoginBackgroundAdminMobile.svg";
import { ReactComponent as QooeeLogoAdmin } from "../../images/QooeeLogoAdminDark.svg";
import { Link } from "react-router-dom";

const LoginBackgroundMobile = ({ children, hasLogo = true, hasBgImg = false, isAdmin = false }) => {

  const [bgImgHidden, setBgImgHidden] = useState(styles.BgImgHidden);

  useEffect(() => {

    const toggleBgImg = () => {
      let bgImg = document.querySelector("#lbmBgImg"),
          Img = document.querySelector("#lbmImg"),
          Body = document.querySelector("#lbmBody");
      if (Img.offsetWidth < bgImg.offsetHeight && (Body.offsetHeight + 32) < (window.innerHeight * .55)) {
        setBgImgHidden('')
      } else {
        setBgImgHidden(`${styles.BgImgHidden}`)
      }
    }

    if (hasBgImg) {
      toggleBgImg()
      //  new ResizeObserver(toggleBgImg).observe(document.getElementById("LBM:Body"))
      window.addEventListener('resize', toggleBgImg)
      return () => { window.removeEventListener('resize', toggleBgImg)
      }
    }
  }, [hasBgImg])

  return (
    <div className={`${styles.LoginBackgroundMobile} ${bgImgHidden}`}>
      {isAdmin ? (<BackgroundAdmin className={styles.Bg} />) : (<Background className={styles.Bg} />)}
      {hasLogo && (
        <Link to={"/"} className={styles.Logo}>
          {isAdmin ? (<QooeeLogoAdmin/>) : (<QooeeLogo/>)}
        </Link>
      )}
      {hasBgImg && (
        <div className={styles.BgImg} id={"lbmBgImg"}>
          <div className={styles.Img}>
            <img src={hasBgImg} alt={"QooeeLoginBackgroundMobile"} id={"lbmImg"} />
          </div>
        </div>
      )}
      <div className={styles.Body} id={"lbmBody"}>{children}</div>
    </div>
  )
};

export default LoginBackgroundMobile;
