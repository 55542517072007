import React from "react";
import { createFactory } from "react";
import _ from "lodash";

export const parseUserStatus = status => {
  switch (status) {
    case 0:
      return "Inactive";
    case 1:
      return "Active";
    default:
      return "";
  }
};

export function stringToSlug(str) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
}

export const timeout = (ms, fn = null) =>
  new Promise(res =>
    setTimeout(async function() {
      let rs = undefined;
      if (typeof fn === "function") rs = await fn();
      res(rs);
    }, ms)
  );

export const removeEmpty = (obj, fn = null) => {
  Object.keys(obj).forEach(key => {
    if (typeof fn === "function") fn(obj, key);
    if (obj[key] && typeof obj[key] === "object") removeEmpty(obj[key]);
    else if (typeof obj[key] === "undefined" || obj[key] === null)
      delete obj[key];
  });
  return obj;
};

export const compose = (...funcs) =>
  funcs.filter(Boolean).reduce(
    (a, b) => (...args) => a(b(...args)),
    arg => arg
  );

export const identity = Component => Component;

export const branch = (test, left, right = identity) => BaseComponent => {
  let leftFactory;
  let rightFactory;
  return props => {
    if (test(props)) {
      leftFactory = leftFactory || createFactory(left(BaseComponent));
      return leftFactory(props);
    }
    rightFactory = rightFactory || createFactory(right(BaseComponent));
    return rightFactory(props);
  };
};

export const renderNothing = () => () => null;

export const NullComponent = () => null;

export function createCancellablePromise(executor) {
  let _reject = null;
  const cancellablePromise = new Promise((resolve, reject) => {
    _reject = reject;
    return executor.then(resolve).catch(reject);
  });
  cancellablePromise.cancel = _reject;
  return cancellablePromise;
}
//
// export const useDebounce = (value, timeout) => {
//   const [state, setState] = React.useState(value);
//
//   React.useEffect(() => {
//     const handler = setTimeout(() => setState(value), timeout);
//
//     return () => clearTimeout(handler);
//   }, [value, timeout]);
//
//   return state;
// };

export function refineObject(obj) {
  for (const key in obj)
    if (obj.hasOwnProperty(key))
      if (typeof obj[key] === "undefined" || obj[key] === null) delete obj[key];
  return obj;
}

export function wcHexIsLight(color) {
  const hex = color.replace('#', '');
  const c_r = parseInt(hex.substring(0, 0 + 2), 16);
  const c_g = parseInt(hex.substring(2, 2 + 2), 16);
  const c_b = parseInt(hex.substring(4, 4 + 2), 16);
  const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
  return brightness > 155;
}

export function isWhite(color) {
  return ["#FFF", "#fff", "#ffffff", "#FFFFFF", "white"].indexOf(color) > -1
}


export function getCategories(exhibitor) {
  console.log("getCategories", exhibitor);
  if (_.isEmpty(exhibitor)) return "";
  const { categories, specify } = exhibitor;
  return _.compact([...Object.values(specify), categories]).join(", ").replace(/,*(food-others|drink-others|retail-others),*/g, "");
  
}

export function stringReplaceAt(str, index, replacement) {
  return str.substring(0, index) + replacement + str.substring(index + replacement.length);
}