import React from "react";
// import * as classnames from "classnames";
import styles from "./Dropdown.module.scss";
import Popover from "@material-ui/core/Popover";
import classnames from "classnames";

function Dropdown({ className = "", header, children, tag = "div", ...props }) {
  const Tag = tag;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Tag {...props}>
      {React.cloneElement(React.Children.only(header), {
        onClick: e => setAnchorEl(e.currentTarget)
      })}
      <Popover
        className={classnames(styles.Popover, className)}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <div>{children}</div>
      </Popover>
    </Tag>
  );
}

export default Dropdown;
