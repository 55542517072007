import { createReducer } from "../../utils/store";
import {
  NOTI_ACTION_CLOSE_TOAST,
  NOTI_ACTION_ENQUEUE_TOAST,
  NOTI_ACTION_REMOVE_TOAST
} from "./consts";
import { produce } from "immer";

const noti = createReducer([], {
  [NOTI_ACTION_ENQUEUE_TOAST]: (state, notiSpec) =>
    produce(state, draft => {
      if (Boolean(notiSpec.key)) {
        const index = draft.findIndex(noti => noti.key === notiSpec.key);
        if (index >= 0) {
          draft.splice(index, 1, notiSpec);
        } else draft.push(notiSpec);
      } else draft.push(notiSpec);
      return draft;
    }),
  [NOTI_ACTION_CLOSE_TOAST]: (state, { dismissAll, key }) =>
    produce(state, draft =>
      draft.map(noti =>
        dismissAll || noti.key === key
          ? { ...noti, dismissed: true }
          : { ...noti }
      )
    ),
  [NOTI_ACTION_REMOVE_TOAST]: (state, { key }) =>
    produce(state, draft => draft.filter(noti => noti.key !== key))
});

export default noti;
