import React from "react";
import styles from "./LoginBackground.module.scss";
import classnames from "classnames";
import Footer from "../../components/Footer/Footer";
import TopBar from "../../components/TopBar/TopBar";
import TopBarContext, { TopBarContextProvider } from "../../utils/TopBarContext";
import SideBar from "../../components/SideBar/SideBar";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import { Switch, withRouter, useLocation, useHistory } from "react-router";
import { SnackbarProvider } from "notistack";
import { isMobile } from "react-device-detect";

const LoginBackground = ({ className, children, logo: Logo }) => {
  return (
    <div className={classnames(styles.LoginPage, className)}>
      <TopBarContextProvider>
        <TopBarContext.Consumer>
          {({ isFullscreen }) => (
            <div
              className={classnames(
                styles.App,
                isFullscreen === true && styles.Fullscreen
              )}
            >
              <ThemeProvider>
                <TopBar/>
              </ThemeProvider>
            </div>
          )}
        </TopBarContext.Consumer>
      </TopBarContextProvider>
      <div className={styles.RegisterContainer}>
        <div className={styles.Content}>{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default LoginBackground;
