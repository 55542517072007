import "../utils/store";
import { createStore, compose, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { persistStore } from "redux-persist";
import { createBrowserHistory } from "history";
import waitForAction from "redux-wait-for-action";
import createSagaMiddleware from "redux-saga";
import createRootReducer from "./reducers";
import rootSagas, { createSagaInjector } from "./sagas";

let sagaMonitor = null;
if (process.env.NODE_ENV === "development")
  sagaMonitor = require("./reactotron").sagaMonitor;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const history = createBrowserHistory();

let instance = null;

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(persist = true) {
  if (!instance) {
    const middlewares = [
      // routerMiddleware(history),
      thunkMiddleware,
      sagaMiddleware,
      waitForAction()
      // ActionHooks.middleware,
    ].filter(Boolean);

    let store = createStore(
      createRootReducer(history),
      // {},
      composeEnhancers(applyMiddleware(...middlewares))
    );

    // Add a dictionary to keep track of the registered async reducers
    store.asyncReducers = {};

    // Create an inject reducer function
    // This function adds the async reducer, and creates a new combined reducer
    store.injectReducer = (key, asyncReducer) => {
      if (!store.asyncReducers[key]) {
        store.asyncReducers[key] = asyncReducer;
        console.log("replace reducer");
        store.replaceReducer(createRootReducer(history, store.asyncReducers));
        return true;
      }
      return false;
    };
    store.injectSaga = createSagaInjector(sagaMiddleware.run, rootSagas);
    store.injectModule = (key, asyncModule) => {
      const injectable = store.injectReducer(key, asyncModule.reducer);
      injectable && store.injectSaga(key, asyncModule.saga || function*() {});
    };

    let persistor = persist ? persistStore(store, null) : null;

    window.store = store;
    instance = { store, persistor };
  }

  return instance;
}
