import React from "react";
import styles from "./InternalLogin.module.scss";
import Form from "react-bootstrap/Form";
import { Link, withRouter } from "react-router-dom";
import withSimpleForm from "../../utils/SimpleForm";
import { connect } from "react-redux";
import { beforeLogin, login } from "../../store/user/actions";
import { Redirect } from "react-router";
import AlertDismissable from "../../components/AlertDismissable/AlertDismissable";
import { createValidator } from "../../utils/form";
import PasswordInput from "../../components/UI/PasswordInput/PasswordInput";
import InputGroup from "react-bootstrap/InputGroup";
import commonStyles from "../../styles/common.module.scss";
import { ReactComponent as UserIcon } from "../../images/UserIcon.svg";
import { withTopBarContext } from "../../utils/TopBarContext";
import { ReactComponent as QooeeLogo } from "../../images/QooeeLogoAdminDark.svg";
import LoginBackground from "../../fragments/LoginBackground/LoginBackground";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import classnames from "classnames";
import withToast from "../../HOCs/withToast";

@withTopBarContext({ isFullscreen: true })
@withRouter
@withSimpleForm({
  entries: props => ({
    email: new URLSearchParams(props.location.search).get("email") || "",
    password: "",
    isAdmin: true
  })
})
@connect(state => ({
  user: state.user
}))
@withToast
class InternalLogin extends React.Component {
  /**
   * @private
   */
  _validator;
  constructor(props) {
    super(props);

    this._validator = createValidator(this);
  }
  componentDidMount() {
    const { user } = this.props;
    if (user.status !== "login-success" || user.obj.role === "USER") {
      this.props.dispatch(beforeLogin());
    }
  }

  /**
   *
   * @param e
   * @private
   */
  doLogin = e => {
    if (e) e.preventDefault();
    if (!this._validator.allValid()) {
      this._validator.showMessages();
      return;
    }

    this.props.dispatch(login(this.props.form)).catch(e => {});
  };
  render() {
    const self = this;
    if (self.props.user.status === "login-success") {
      return (
        <Redirect
          to={self.props.location.state?.from?.pathname || "/winners"}
        />
      );
    }
    return (
      <div>
        <div
          className="mx-auto mt-5"
          style={{ backgroundColor: "white !important" }}
        >
          <Container>
            <Grid container spacing={0}>
              <Grid item xs>
                <QooeeLogo style={{ margin: "3rem", marginTop: "10rem" }} />
              </Grid>
              <Grid item xs>
                <Form
                  onSubmit={self.doLogin.bind(self)}
                  style={{ padding: "5rem", paddingBottom: "0" }}
                >
                  <Form.Group>
                    <h1 className={"mb-1"} style={{ fontWeight: "600" }}>
                      {"Internal login."}
                    </h1>
                    <h5 className={"text-grey"} style={{ marginBottom: 60 }}>
                      Please sign in to continue
                    </h5>
                    {self.props.user.status === "login-error" && (
                      <AlertDismissable
                        heading={self.props.user.error?.title}
                        variant={"danger"}
                      >
                        {/* {self.props.user.error?.message} */}
                        {'Invalid Emaill Address / Password.'}
                      </AlertDismissable>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <TextField
                      className={"w-100"}
                      id={"email"}
                      type={"email"}
                      placeholder={"Email Address"}
                      {...self.props.formBinding("email")}
                      label="Email Address"
                      helperText={self.props.renderFieldValidator(
                        "email",
                        "E-mail"
                      )}
                      variant="outlined"
                    />
                    {self._validator.message(
                      "Email",
                      self.props.form.email,
                      "required"
                    )}
                  </Form.Group>
                  <PasswordInput
                    id={"password"}
                    type={"password"}
                    name={"password"}
                    placeholder={"Password"}
                    size={"lg"}
                    {...self.props.formBinding("password")}
                  >
                    {[
                      self._validator.message(
                        "Password",
                        self.props.form.password,
                        "required"
                      )
                    ]}
                  </PasswordInput>
                  <Form.Group>
                    <Form.Text
                      className={classnames(styles.FormText, "mt-4 mb-4")}
                    >
                      <Link to={"/login/forgot"} className={"no-link"}>
                        <h6 className={"href-link"}>
                          <u>Forgot password?</u>
                        </h6>
                      </Link>
                    </Form.Text>
                  </Form.Group>
                  <Form.Group>
                    <div className="d-flex justify-content-end">
                      <Button
                        id={"submit"}
                        variant={"primary"}
                        size={"lg"}
                        className={"align-self-end w-100"}
                        style={{ paddingLeft: "3rem", paddingRight: "3rem" }}
                        type={"submit"}
                      >
                        Login
                      </Button>
                    </div>
                  </Form.Group>
                </Form>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    );
  }
}

export default InternalLogin;
