import React from "react";
import { Button, Row, Input, Alert } from "reactstrap";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import _ from "lodash";
import { axiosApi } from "../../utils/api";
import { NotificationManager } from "react-notifications";

const ConfirmPaymentDialog = props => {
  const { onClose, payment, auth, fetchOrder } = props;
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const _confirmPayment = payload => {
    return axiosApi.auth(auth).post("/v1/booking/a/verify-payment", payload);
  };

  const onSuccess = e => {
    if (e) {
      const errorMsg = _.get("data.err.message");
      setError(errorMsg);
    } else {
      setError("");
      onClose();
      NotificationManager.success("Confirmed payment successfully.");
      fetchOrder();
    }
    setLoading(false);
  };

  const onSubmit = async () => {
    setLoading(true);
    const { data } = await _confirmPayment({
      message,
      notify_from_am: message,
      payment_reminder_id: payment.id
    });
    console.log(data);
    const { success, err } = data;
    if (success) {
      return onSuccess();
    } else return onSuccess(err);
  };

  return (
    <div className="custom-ui shadow">
      <div className="p-5">
        <Row>
          <h1>Confirm the payment</h1>
        </Row>
        <Row>
          <div className="w-100">
            Amount: <b>${payment.short_amount_paid}</b>
          </div>
          <div className="w-100">
            For Order: <b>{payment.order_no}</b>
          </div>
          <div className="w-100 mt-3">
            Message from <b>Adi Media</b>
          </div>
        </Row>
        <Row className="mb-3">
          <Input
            onChange={e => setMessage(e.target.value)}
            placeholder={"Message"}
          />
        </Row>
        <Row>
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>
          {"   "}
          <Button
            className="sw-100"
            color={"primary"}
            disabled={loading}
            onClick={onSubmit}
          >
            {!loading && <>{"Confirm"}</>}
            {loading && (
              <Loader type="ThreeDots" color="white" height={15} width={15} />
            )}
          </Button>
        </Row>
        <Row>
          {error && error.length > 0 && (
            <Alert color="danger" className="mt-3 w-100">
              {error}
            </Alert>
          )}
        </Row>
      </div>
    </div>
  );
};

export default ConfirmPaymentDialog;
