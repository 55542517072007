import { createSelector } from "reselect";
// import deepClone from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";

function aware(user) {
  const userId = user?.id;
  if (typeof userId === "undefined"){
    return userId;
  }
    // throw new Error("Exhibitor: not logged in");
  return userId;
}
function prepare(exh, exhStatusObj) {
  const exhStatus = exh.status_all[0],
    newestStatus = exhStatusObj.map[exhStatus.status_id] || null;
  return Object.assign({}, exh, {
    newestStatus,
    isAvailableToBooking:
      typeof newestStatus === "object" &&
      newestStatus !== null &&
      ["created", "suspended", "error"].findIndex(i => i === newestStatus.slug) === -1
  });
}

export const ExhSelectorGetExhibitorOfUser = createSelector(
  [
    state => state.user.obj,
    state => state.exhibitor.exhibitors,
    state => state.boothType.exhibitor
  ],
  (user, exhibitor, exhibitorStatus) => {
    const userId = aware(user);
    return (exhibitor || []);
  }
);

export const GetExhibitorOfUserStatus = createSelector(
  [state => state.user.obj, state => state.exhibitor],
  (user, exhibitor) => {
    const userId = aware(user);
    const status = exhibitor.status[userId];
    if (typeof status !== "string") return "not-fetched";
    return status;
  }
);

export const ExhSelectorIsUserHasNoExhibitor = createSelector(
  [ExhSelectorGetExhibitorOfUser, GetExhibitorOfUserStatus],
  (exhibitors, status) => exhibitors.length === 0 && status === "fetched"
);

export const ExhSelectorGetCurrent = createSelector(
  [
    state => state.user.obj,
    state => state.exhibitor,
    state => state.boothType.exhibitor
  ],
  (user, exhibitor, exhibitorStatus) => {
    const userId = aware(user);
    const selectId = exhibitor.select[userId];
    if (typeof selectId !== "number") return null;
    if (isEmpty(exhibitor[userId])) return null;
    const exh = exhibitor[userId].find(exh => exh.id * 1 === selectId * 1);
    return prepare(exh, exhibitorStatus);
  }
);

export const ExhSelectorGetSelected = createSelector(
  [
    state => state.exhibitor
  ],
  (obj) => {
    return obj?.exhibitors?.find(o => o.id === obj?.select[1]);
  }
);


// export const ExhibitorSelectorGetCurrentStatus = createSelector(
//   [ExhibitorSelectorGetCurrent,state=>state.boothType.exhibitor.map],
//   (currentExhibitor,statusListObj)=>{
//     const status = currentExhibitor.newestStatus?.value;
//     if(isEmpty(status)) return null
//
//   }
// )
