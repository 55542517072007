import React from "react";
import styles from "./InternalLoginMobile.module.scss";
import Form from "react-bootstrap/Form";
import { Link, withRouter } from "react-router-dom";
import withSimpleForm from "../../utils/SimpleForm";
import { connect } from "react-redux";
import { beforeLogin, login } from "../../store/user/actions";
import { Redirect } from "react-router";
import AlertDismissable from "../../components/AlertDismissable/AlertDismissable";
import { appValidators, withToken } from "../../utils/form";
import { createValidator } from "../../utils/form";
import PasswordInput from "../../components/UI/PasswordInput/PasswordInput";
import InputGroup from "react-bootstrap/InputGroup";
import commonStyles from "../../styles/common.module.scss";
import { ReactComponent as UserIcon } from "../../images/UserIcon.svg";
import { withTopBarContext } from "../../utils/TopBarContext";
import { ReactComponent as QooeeLogo } from "../../images/QooeeLogoAdminDark.svg";
import LoginBackground from "../../fragments/LoginBackground/LoginBackground";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "react-bootstrap/Button";
import classnames from "classnames";
import withToast from "../../HOCs/withToast";
import withLoginPage from "../../controllers/withLoginPage/withLoginPage";
import { compose } from "../../utils/common";
import TextInput from "../../components/UI/TextInput/TextInput";
import LoginBackgroundMobile from "../../fragments/LoginBackgroundMobile/LoginBackgroundMobile";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { entries } from "lodash";

const bgLoginTransparent = require("../../images/bg-login-no-fill-ext.png");
const bgForgotPwdTransparent = require("../../images/bg-forgot-pwd-no-fill.png");
const bgUrlSentTransparent = require("../../images/bg-url-sent-no-fill.png");

const InternalLoginMobile = compose(
  withTopBarContext({ isFullscreen: true }),
  withRouter,
  withSimpleForm({
    entries: props => ({
      email: new URLSearchParams(props.location.search).get("email") || "",
      password: "",
      isAdmin: true
    }),
    validation:  {
      email: "required|email",
      password: "required|password"
    }
  }),
  connect(state => ({
    user: state.user
  })),
  withToast
)(
  ({
    user,
    formBinding,
    form,
    validator,
    validate,
    location,
    renderFieldValidator,
    ...props
  }) => {
  const dispatch = useDispatch(),
        [isValidate, setIsValidate] = React.useState(false)
  
  const doLoginMobile = e => {
    if (!validate()) {
      setIsValidate(true)
      return
    }
    dispatch(login(form)).catch(e => {})
  }
  React.useEffect(() => {
    if (user.status !== "login-success" || user.obj.role === "USER") {
      dispatch(beforeLogin())
    }
  }, [])

  return (
    <LoginBackgroundMobile isAdmin={true} >
      {user.status === "login-success" && (
        <Redirect to={location.state?.from?.pathname || "/internal/users"} />
      )}
      <div>
        <h2 className="">
          {"Internal login"}
        </h2>
        <Form.Group>
            <div style={{ width: "80%" }} className={"text-muted"}>
              Please sign in to continue
            </div>
        </Form.Group>
        <Form>
          {(user.status === "login-error") && (
            <Form.Group>
              <AlertDismissable variant={"danger"}>
                {/* {user.error?.message} */}
                {'Invalid Emaill Address / Password.'}
              </AlertDismissable>
            </Form.Group>
          )}
          <Form.Group>      
            <TextInput
              id={"email"}
              label="Email"
              placeholder={"Email Address"}
              type="email"
              size={"lg"}
              variant={"grey"}
              {...formBinding("email")}
            />
            {renderFieldValidator("email", "Email")}
          </Form.Group>

            <PasswordInput
              id={"password"}
              placeholder={"Password"}
              size={"lg"}
              {...formBinding("password")}
              variant={"grey"}
              style={{ marginBottom: "0.7rem" }}
            >
              {renderFieldValidator("password", "Password")}
            </PasswordInput>
              <div className={"mb-4 text-right"}>
                <Link to={"/login/forgot"} className={"no-link text-muted"}>
                  Forgot password?
                </Link>
              </div>
              <Form.Group>
                <Button
                  id={"submit"}
                  variant={"primary"}
                  className={`w-100 ${styles.Uppercase}`}
                  style={{ paddingLeft: "3rem", paddingRight: "3rem", marginBottom: "2rem"}}
                  type={"button"}
                  onClick={() => doLoginMobile()}
                >
                  Login
                </Button>
              </Form.Group>
              <div className={styles.Register}>
                <span className={"text-muted"}>No account yet? </span>
                <Link to={"/register"} className={"no-link font-weight-bold"}>
                  Register here
                </Link>
              </div>
        </Form>
      </div>
    </LoginBackgroundMobile>
  )
  
})
export default InternalLoginMobile;
