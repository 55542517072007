// export const BOOKING_ACTION_FETCHING_CART = "booking/cart-fetching";
export const BOOKING_ACTION_FETCH_CART = "booking/cart-fetch";
export const BOOKING_ACTION_FETCHED_CART = "booking/cart-fetched";
export const BOOKING_ACTION_REFETCH_CART = "booking/cart-refetch";
export const BOOKING_ACTION_FETCH_CART_ERROR = "booking/cart-fetch-error";
export const BOOKING_ACTION_ADD_TO_CART = "booking/add-to-cart";
export const BOOKING_ACTION_ADD_ORDER = "booking/order-add";
export const BOOKING_ACTION_ADD_ORDER_SUCCESS = "booking/order-add-success";
export const BOOKING_ACTION_ADD_ORDER_ERROR = "booking/order-add-error";
export const BOOKING_ACTION_CART_ERROR = "booking/cart-error";
export const BOOKING_ACTION_REMOVE_FROM_CART = "booking/remove-from-cart";
export const BOOKING_ACTION_REMOVE_ALL_FROM_CART =
  "booking/remove-all-from-cart";
export const BOOKING_ACTION_FETCH_ORDER = "booking/fetch-order";
export const BOOKING_ACTION_FETCH_ORDER_SUCCESS = "booking/fetch-order-success";
