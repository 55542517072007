// import memoize from "lodash/memoize";
// import { createSelector } from "reselect";
import { createSelector } from "reselect";

const isObjectEmpty = obj => ({ event }) => {
  // console.log(obj, event);
  return (
    typeof event.status[obj] === "undefined" ||
    event.status[obj] === null ||
    event.status[obj] === "empty"
  );
};

const isObjectFetching = obj => ({ event }) => {
  return /ing$/.test(event.status[obj]);
};

const getObject = obj => ({ event }) =>
  ({
    list: Object.values(event.list).sort((a, b) => b.id - a.id),
    map: event.list
  }[obj] || event.list[obj]);

const EventSelectors = {
  isObjectEmpty,
  isObjectFetching,
  getObject
};
export default EventSelectors;

export const EventSelectorGetEventCreator = () =>
  createSelector(
    state => state.event.list,
    (_, { eventId }) => eventId,
    (eventList, eventId) => (eventId === null ? null : eventList[eventId])
  );
