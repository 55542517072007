import {
  NOTI_ACTION_CLOSE_TOAST,
  NOTI_ACTION_ENQUEUE_TOAST,
  NOTI_ACTION_REMOVE_TOAST
} from "./consts";

export const NotiActionsEnqueueToast = noti => {
  const key = noti.options?.key;
  return {
    type: NOTI_ACTION_ENQUEUE_TOAST,
    ...noti,
    key: key || new Date().getTime() + Math.random()
  };
};

export const NotiActionsCloseToast = key => ({
  type: NOTI_ACTION_CLOSE_TOAST,
  dismissAll: !Boolean(key),
  key
});

export const NotiActionsRemoveToast = key => ({
  type: NOTI_ACTION_REMOVE_TOAST,
  key
});
