import { put, call } from "@redux-saga/core/effects";
import EventActions from "./actions";
import { combineRootSagas, takeLeading } from "../../utils/store";
import { takeLoginAction } from "../sagas";
import {
  EVENT_ACTION_ADD,
  EVENT_ACTION_ADDED,
  EVENT_ACTION_ERROR,
  EVENT_ACTION_UPDATE
} from "./const";
import { axiosApi } from "../../utils/api";
import { APIInternal } from "../../utils/constant";

function* _spawnError(error) {
  yield put({ type: EVENT_ACTION_ERROR, error });
}

function* watchLoginAndFetchAll() {
  yield takeLoginAction(null, function*() {
    yield put(EventActions.fetchAll());
  });
}
function* addNewEvent() {
  yield takeLeading(EVENT_ACTION_ADD, function*({ form }) {
    try {
      const { data } = yield call(
          axiosApi.auth().post,
          APIInternal.ADD_EVENT,
          form
        ),
        { event, error = null } = data;
      if (error !== null) yield _spawnError(error);
      else yield put({ type: EVENT_ACTION_ADDED, item: event });
    } catch (error) {
      yield _spawnError(error);
    }
  });
  // yield takeLeading(EVENT_ACTION_UPDATE,function)
}
export default function* eventSagas() {
  yield combineRootSagas(watchLoginAndFetchAll, addNewEvent);
}
