import React from "react";
import Portal from "@material-ui/core/Portal";

export const MobileAppPortalContext = React.createContext(null);

const MobileAppPortal = ({ children, ...props }) => (
  <MobileAppPortalContext.Consumer>
    {$app => $app && <Portal container={$app}>{children}</Portal>}
  </MobileAppPortalContext.Consumer>
);

export default MobileAppPortal;
