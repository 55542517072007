import React from "react";
import styles from "./PhoneNumberInput.module.scss";
import InputGroup from "react-bootstrap/InputGroup";
// import _countries from "countries-list/dist/countries.emoji.json";
import countries from "./CountriesList";
import ReactSelect, { ReactSelectComponents } from "../ReactSelect/ReactSelect";
// import FormControl from "react-bootstrap/FormControl";
import classnames from "classnames";

import InputLabel from "@material-ui/core/InputLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Form from "react-bootstrap/Form";
import { SelectorSvgIconConverter } from "../Selector/Selector";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";

// let countries = [];
// for (let code in _countries)
//   if (_countries.hasOwnProperty(code)) {
//     countries.push({
//       value: code,
//       label: _countries[code].name,
//       ..._countries[code],
//       emojiCode: _countries[code].emojiU
//         .replace(/\s/g, "")
//         .replace(/U\+/g, "\\0")
//     });
//   }

const selectStyle = {
  control: optionStyles => ({
    ...optionStyles,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    minHeight: "unset",
    height: optionStyles.minHeight - 1,
    minWidth: 80
  }),
  option: (optionStyles, { data }) => ({
    ...optionStyles,
    "&:before": {
      // content: '"\\01F1E6\\01F1EA"'
      content: `"${data.emojiCode}"`,
      display: "inline-block",
      marginRight: 8,
      fontSize: "120%"
    }
  }),
  valueContainer: optionStyles => ({
    ...optionStyles,
    paddingLeft: 4,
    paddingRight: 4
  }),
  menu: optionStyles => ({
    ...optionStyles,
    width: 400
  })
};

const selectComponents = {
  Control: props => {
    return (
      <div className={styles.ControlOuter}>
        <ReactSelectComponents.Control {...props} />
      </div>
    );
  },
  SingleValue: ({ children, data, ...props }) => {
    return (
      <ReactSelectComponents.SingleValue {...props}>
        <span className={styles.Emoji}>{data.emoji}</span>
      </ReactSelectComponents.SingleValue>
    );
  }
};

export const MobileNumberInput = ({
  value,
  className,
  onChange,
  onInput,
  defaultCountry = "65",
  size = "md",
  type = "tel",
  label,
  placeholder,
  placeholderCountry = "Country",
  name = "phone",
  col = 8,
  colCountry = 4,
  disabled,
  isAutoFill = false,
  ...props
}) => {
  String(value)

  //  CountryMenuSelector
  const FormControl = Form.Control,
        [isFocus, setIsFocus] = React.useState(false),
        [anchorEl, setAnchorEl] = React.useState(null),
        [selectedCountry, setSelectedCountry] = React.useState(``),
        [displayedCountry, setDisplayedCountry] = React.useState(``),
        _countryMenuSelector = React.useRef(null)
  const openCountrySelector = (e) => {
    setAnchorEl(e.currentTarget)
    setIsFocus(true)
  }
  const countrySelected = (e, country = 65, countryName = '') => {
    setSelectedCountry(country)
    setDisplayedCountry(countryName)
    setAnchorEl(null)
    setIsFocus(false)
  }
  const closeCountrySelector = () => {
    setAnchorEl(null)
    setIsFocus(false)
  }
  const fillField = () => {
    for (let c of countries) {
      let re = new RegExp(`^${c.phone}`)
      if (value.match(re)) {
        setSelectedCountry(c.phone)
        setDisplayedCountry(c.name)
        setMobileNumber(value.substring(c.phone.length))
        break
      }
    }
  }

  //  MobileNumberInputField
  const [mobileNumber, setMobileNumber] = React.useState(``),
        [disabledState, setDisabledState] = React.useState(false),
        _mobileNumberInputField = React.useRef(null)

  React.useEffect(() => {
    if (value) {
      fillField()
    }
  }, [])

  //  Change MobileNumberInput's value when choosing country or typing number
  React.useEffect(() => {
    onChange({ target: { value: !(selectedCountry && mobileNumber) ? `` : `${selectedCountry}${mobileNumber}` } })
  }, [selectedCountry, mobileNumber])

  // React.useEffect(() => {
  //   if (value) {
  //     fillField()
  //   }
  //   console.table({ value, selectedCountry, mobileNumber })
  // }, [value])

  //  AutoFill event handling
  React.useEffect(() => {
    if (isAutoFill) {
      if (disabled && value) {
        fillField()
        setDisabledState(true)
      }
      if (disabledState && !value) {
        setMobileNumber('')
        setDisabledState(false)
      }
    }
  }, [mobileNumber, value])

  return (
    <Grid container size={size} spacing={2}>
      <Grid item xs={colCountry} sm={colCountry} lg={colCountry}>
        <FormControl
          ref={_countryMenuSelector}
          className={`${styles.CountryMenuSelector} ${isFocus && styles.CountryMenuSelectorFocused} form-control`}
          custom
          readOnly
          style={{
            backgroundImage:
              !displayedCountry
                ? `url(${SelectorSvgIconConverter(faChevronDown, isFocus && "#727476")})`
                : 'none',
              paddingRight:
                !displayedCountry ? `2rem` : ``
          }}
          onFocus={e => {e.target.blur()}}
          onClick={e => openCountrySelector(e)}
          value={displayedCountry}
          // defaultValue={value}
          disabled={!disabled}
          autoComplete={"off"}
          placeholder={placeholderCountry}
        >
        </FormControl>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeCountrySelector}
        >
          {countries.map((c, index) => (
            <MenuItem
              key={index}
              value={c.phone}
              selected={c.phone === selectedCountry}
              onClick={e => countrySelected(e, c.phone, c.name)}
            >
              {c.name}
            </MenuItem>
          ))}
        </Menu>
      </Grid>

      <Grid item xs={col} sm={col} lg={col}>
        <FormControl
          ref={_mobileNumberInputField}
          fullWidth
          className={className}
          name={name}
          type={"tel"}
          disabled={!disabled}
          placeholder={placeholder}
          value={mobileNumber}
          onChange={e => setMobileNumber(e.target.value)}
          onInput={onInput}
          autoComplete={"off"}
          {...props}
        >
        </FormControl>
      </Grid>
    </Grid>
  )
}

const PhoneNumberInput = ({
  value,
  onChange,
  onInput,
  defaultCountry = "65",
  size = "md",
  type = "tel",
  label,
  placeholder = "Phone Number",
  name = "phone",
  col = 8,
  colCountry = 4,
  disabled,
  isAutoFill = false,
  ...props
}) => {
  

  String(value);
  const [country, setCountry] = React.useState(defaultCountry),
    [phoneNumber, setPhoneNumber] = React.useState(),
    [disabledState, setDisabledState] = React.useState(false),
    setValue = v => onChange({ target: { value: v } }),
    $control = React.useRef(null),
    setCountryValue = (newCountry, init = false) => {
      if (typeof value !== "string" || value.length === 0)
        setValue("+" + newCountry?.phone + " ");
      else
        setValue(
          value.replace(
            new RegExp(`\\+${country.phone}\\s*`),
            `+${newCountry?.phone} `
          )
        );
      setCountry(newCountry);
      if ($control.current && !init) $control.current.focus();
    };

  React.useEffect(() => {
    if (typeof value !== "string" || value.length === 0)
      setCountryValue(country, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    onChange({ target: { value: `${country}${phoneNumber}` } });
  }, [country, phoneNumber]);
  React.useEffect(() => {
    if (isAutoFill) {
      if (disabled && value) {
        for (let c of countries) {
          let re = new RegExp(`^${c.phone}`)
          if (value.match(re)) {
            setCountryValue(c.phone)
            setPhoneNumber(value.substring(c.phone.length))
            break
          }
        }
        setDisabledState(true)
      }
      if (disabledState && !value) {
        setPhoneNumber('')
        setDisabledState(false)
        setCountryValue("65")
      }
    }
    // console.log(`value: ${value}\ncountry: ${country}\nphone: ${phoneNumber}`)
  }, [country, phoneNumber, value])

  return (
    <Grid container size={size} spacing={2}>
      <Grid item xs={colCountry} sm={colCountry} lg={colCountry}>
        {/*
        <ReactSelect
          options={countries}
          value={country}
          onChange={setCountryValue}
          styles={selectStyle}
          components={selectComponents}
          isSearchable={false}
        /> 
        */}
        <FormControl
          variant="outlined"
          fullWidth
          className={`${disabled ? styles.CountrySelectDisabled : ''}`}
        >
          <InputLabel id="country-select-outlined-label">Country</InputLabel>
          <Select
            labelId="country-select-outlined-label"
            id="country-select-outlined"
            value={country}
            onChange={e => setCountry(e.target.value)}
            label="Country"
            labelWidth={80}
            disabled={disabled}
          >
            {countries.map(c => (
              <MenuItem value={c.phone}>{c.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={col} sm={col} lg={col}>
        <TextField
          fullWidth
          className={`${styles.PhoneNumberInput}`}
          classes={{}}
          name={name}
          type={"tel"}
          placeholder={placeholder}
          label={label}
          value={phoneNumber}
          onChange={e => setPhoneNumber(e.target.value)}
          onInput={onInput}
          variant="outlined"
          disabled={disabled}
          // InputLabelProps={(phoneNumber ? {shrink: true} : {})}
        />
      </Grid>
      {/*
      <FormControl
        ref={$control}
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
      />
      */}
    </Grid>
  );
};

export default PhoneNumberInput;
