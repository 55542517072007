import React from "react";
import { Button, Row, Input, Alert, Col } from "reactstrap";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import _ from "lodash";
import { axiosApi } from "../../utils/api";
import { NotificationManager } from "react-notifications";
import ReactSelect from "react-select";

const CancelPaymentDialog = props => {
  const { onClose, order, auth, fetchOrder } = props;
  const [reason, setReason] = React.useState(2);
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const _cancelOrder = payload => {
    return axiosApi
      .auth(auth)
      .post(`/v1/booking/a/cancel-order/${payload.order_id}`, payload);
  };

  const onSuccess = e => {
    if (e) {
      const errorMsg = _.get("data.err.message");
      setError(errorMsg);
    } else {
      setError("");
      onClose();
      NotificationManager.success("Confirmed payment successfully.");
      fetchOrder();
    }
    setLoading(false);
  };

  const onSubmit = async () => {
    setLoading(true);
    const { data } = await _cancelOrder({
      order_id: order.id,
      reason: reason,
      description: message
    });
    const { success, err } = data;
    if (success) {
      return onSuccess();
    } else return onSuccess(err);
  };

  return (
    <div className="custom-ui shadow">
      <div className="p-5">
        <Row>
          <h1>Cancel the order</h1>
        </Row>
        <Row>
          <div className="w-100 mb-3">
            Order: <b>{order.order_no}</b>
          </div>
        </Row>
        <Row>
          {/* <Input className="my-3" onChange={e => setMessage(e.target.value)} /> */}
          <Col>
            <ReactSelect onChange={e => setReason(e.value)} placeholder="Reason" options={[ { label: "No payment",  value: 1 }, { label: "Reject / Others",  value: 2 } ]} />
          </Col>
          </Row>
          <Row className="mt-2">
          <Col>
            <textarea row={4} style={{ width: "100%", height: 200 }} disabled={reason===1} className="my-3" onChange={e => setMessage(e.target.value)} />
          </Col>
          </Row>
          <Row className="mt-5">
          <Col>
            <Button color={"secondary"} onClick={onClose}>
              Cancel
            </Button>
            {"   "}
            <Button
              className="sw-100 ml-3"
              color={"primary"}
              disabled={loading}
              onClick={onSubmit}
            >
              {!loading && <>{"Confirm"}</>}
              {loading && (
                <Loader type="ThreeDots" color="white" height={15} width={15} />
              )}
            </Button>
            </Col>
        </Row>
        <Row>
          {error && error.length > 0 && (
            <Alert color="danger" className="mt-3 w-100">
              {error}
            </Alert>
          )}
        </Row>
      </div>
    </div>
  );
};

export default CancelPaymentDialog;
