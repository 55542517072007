import React from "react";
import styles from "./ExternalUsersManagementMobile.module.scss";
import * as classnames from "classnames";
import { Link, withRouter } from "react-router-dom";
import withSimpleForm from "../../utils/SimpleForm";
import { connect } from "react-redux";
import { withTopBarContext } from "../../utils/TopBarContext";
import withToast from "../../HOCs/withToast";
import { compose } from "../../utils/common";
import moment from "moment";
import get from "lodash/get";
// import UserTopBar from "../../components/UserTopBar/UserTopBar";
import { TableMobile } from "../../components/Table/Table";
import { adminFetchIUsers } from "../../store/admin/actions";
import Switch from "react-switch";
import { USER_STATUS } from "../../utils/constant";
import { axiosApi } from "../../utils/api";
import PageTitle from "../../components/UI/PageTitle/PageTitle";
import { parseUserStatus } from "../../utils/common";
import { ExhSelectorGetExhibitorOfUser } from "../../store/exhibitor/selectors";
import groupBy from "lodash/groupBy";
import Select from "react-select";
import { useDispatch } from "react-redux";

const ExternalUsersManagementMobile = compose(
  withTopBarContext(),
  withRouter,
  connect(state => ({
    adminData: state.admin,
    user: state.user,
    exhibitors: groupBy(ExhSelectorGetExhibitorOfUser(state), "email")
  })),
  withToast
)(
  ({
    toastSuccess,
    history,
    toastError,
    ...props
  }) => {
  const dispatch = useDispatch(),
        state = {
          role: "a"
        }

  React.useEffect(() => {
    dispatch(adminFetchIUsers)
  }, [])

  const onModifyUserStatus = (id, status) => {
    const endpointApp = status === USER_STATUS.ACTIVE ? "disable" : "enable";

    axiosApi
      .auth()
      .put(`/v1/a/u/${id}/${endpointApp}`, {})
      .then(updatedStatusRes => {
        if (updatedStatusRes.data && updatedStatusRes.data.success) {
          toastSuccess(
            `Enable/Disable user successfully. Primary User ID ${updatedStatusRes.data?.user}`
          );
          dispatch(adminFetchIUsers);
        } else {
          const msg = get(updatedStatusRes, "data.err.message");
          toastError(
            `Enable/Disable user unsuccessfully. Message: ${msg}`
          );
        }
      });
  }
  const columns = [
    {
      name: "id",
      label: "User Id"
    },
    {
      name: "last_login",
      label: "Last login",
      options: {
        customBodyRender: v => moment(v).format("DD/MM/YYYY HH:mm")
      }
    },
    {
      name: "email",
      label: "Email",
      options: {
        customBodyRender: email => (
          <span
            className={classnames(styles.PointerCursor, "text-primary")}
            onClick={() =>
              props.history.push(`/internal/user-info/${email}`)
            }
          >
            <u>{email}</u>
          </span>
        )
      }
    },
    {
      name: "name",
      label: "Name"
    },
    {
      name: "mobile",
      label: "Mobile Number"
    },
    {
      name: "email",
      label: "Exhibitors",
      options: {
        customBodyRender: email => (
          <div>
            {props.exhibitors[email]?.map(exhibitor => (
              <Link
                to={`/internal/exhibitor/detail/${exhibitor.id}?tab=0`}
                className={"d-block"}
              >
                {exhibitor.brand_name}
              </Link>
            ))}
          </div>
        )
      }
    },
    {
      name: "role",
      label: "Role"
    },
    {
      name: "status_value",
      label: "Status",
      options: {
        customBodyRender: status => parseUserStatus(status)
      }
    },
    {
      name: "created_at",
      label: "Created Date",
      options: {
        customBodyRender: v => moment(v).format("DD/MM/YYYY")
      }
    },
    {
      name: "updated_at",
      label: "Modified Date",
      options: {
        customBodyRender: v => moment(v).format("DD/MM/YYYY")
      }
    },
    {
      name: "status",
      label: "Disable",
      options: {
        customBodyRender: ({ id, status }) => (
          <Switch
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={() => onModifyUserStatus(id, status)}
            checked={status === USER_STATUS.ACTIVE}
          />
        )
      }
    }
  ];
  const {
    adminData: { eUsers, status }
  } = props;
  const options = [
    { value: "a", label: "All Users" },
    { value: "i", label: "Internal" },
    { value: "e", label: "External" }
  ];
  const jsUsers = Array.isArray(eUsers)
    ? eUsers
        .filter(({ role }) => {
          if (state.role === "i") return role !== "USER";
          if (state.role === "e") return role === "USER";
          return true;
        })
        .map(u => ({
          ...u,
          name: `${u.first_name} ${u.last_name}`,
          status: { id: u.id, status: u.status },
          status_value: u.status,
          mobile: `${u.mobile}`
        }))
    : [];

  const isLoading = status === "fetch-internal-user-pending";
  return (
    <div className={styles.P5}>
        <div style={{display: "block"}}>
          <PageTitle className={"h2 mb-0 mr-4"} style={{ width: "100%" }}>
            Users management
          </PageTitle>
          {/* <Button size={"lg"} variant={"secondary"}>
            <FontAwesomeIcon icon={faCloudDownloadAlt} className={"mr-3"} />
            Download
          </Button> */}
          <Select
            defaultValue={[options[0]]}
            className={styles.selectBtn}
            classNamePrefix="select"
            onChange={v => this.setState({ role: v.value })}
            options={options}
          />
        </div>
        <TableMobile isLoading={isLoading} data={jsUsers} columns={columns} options={{ searchOpen: false }} />
      </div>
  )
  
})
export default ExternalUsersManagementMobile;
