import React from "react";
import { Helmet } from "react-helmet";

const withTitle = ({ title }) => Component => props => {
  return (
    <>
      <Helmet>
        <title>{title && `${title} - `}Qooee</title>
      </Helmet>
      <Component {...props} />
    </>
  )
}

export default withTitle
