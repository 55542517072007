import React from "react";
// import styles from "./TooltipWrapper.module.scss";
// import * as propTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import loadable from "@loadable/component";
import Tooltip from "@material-ui/core/Tooltip";

function TooltipWrapper({ title = null, placement = "right", children }) {
  if (isEmpty(title)) return children;
  return (
    <Tooltip title={title} placement={placement} arrow>
      {children}
    </Tooltip>
  );
}

export default TooltipWrapper;
