import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as PropTypes from "prop-types";
import { faCircle } from "@fortawesome/free-solid-svg-icons/faCircle";
import React from "react";
import * as classnames from "classnames";

const FontAwesomeCircle = ({ icon, size = "lg", className = "", ...props }) => (
  <span
    className={classnames(`fa-layers fa-fw fa-${size}`, className)}
    {...props}
  >
    <FontAwesomeIcon icon={faCircle} />
    <FontAwesomeIcon icon={icon} inverse transform="shrink-9" />
  </span>
);
FontAwesomeCircle.propTypes = {
  icon: PropTypes.object.isRequired
};

export default FontAwesomeCircle;
