import { createSelector } from "reselect";

// const roles = ["ADMIN", "OPERATIONS", "FINANCE", "USER"];

export const GetUserRoles = createSelector(
  [state => state.user.obj],
  userObj => {
    const role = userObj?.role;
    if (typeof role === "undefined" || role === null) return null;

    return {
      isClient: role === "USER",
      isNotClient: role !== "USER",
      isAdmin: role === "ADMIN"
    };
  }
);

export const IsUserTokenExpired = createSelector(
  [state => state.user.obj],
  userObj => {
    if (typeof userObj === "undefined" || userObj === null) return true;

    const current = new Date() * 1;
    if (userObj.auth.expired < current) console.log("expired");
    else console.log("not expired");
    return userObj.auth.expired < current;
  }
);
