import React from "react";
import styles from "./Breadcrumb.module.scss";
import "../../App.scss";
// import * as PropTypes from "prop-types";
import classnames from "classnames";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import AuthLink from "../AuthLink/AuthLink";
import Typography from "@material-ui/core/Typography";
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageTitle from "../UI/PageTitle/PageTitle";

const BreadcrumbItem = ({ link, isLast, children }) => {
  const Component = isLast ? Typography : AuthLink,
    props = isLast ? { component: "strong" } : { to: link };
  return <Component {...props}>{children}</Component>;
};

const Breadcrumb = ({ breadcrumbs = [], className }) => (
  <Breadcrumbs aria-label={"breadcrumb"} className={classnames(className)}>
    <BreadcrumbItem key={"home"} link={"/"} isLast={breadcrumbs.length === 0}>
      Qooee
    </BreadcrumbItem>
    {breadcrumbs.map(({ title, to }, index) => (
      <BreadcrumbItem
        isLast={index === breadcrumbs.length - 1}
        link={to}
        key={title}
      >
        {title}
      </BreadcrumbItem>
    ))}
  </Breadcrumbs>
);

export default Breadcrumb;

export const InternalBreadcrumb = ({ breadcrumbs = [] }) => (
  <div className={`AppBody AppBreadcrumb`}>
    <Breadcrumbs aria-label={"breadcrumb"} className={`${styles.Breadcrumb}`}>
      <BreadcrumbItem key={"home"} link={"/"} isLast={breadcrumbs.length === 0}>
        Qooee
      </BreadcrumbItem>
      {breadcrumbs.map(({ title, to }, index) => (
        <BreadcrumbItem
          isLast={index === breadcrumbs.length - 1}
          link={to}
          key={title}
        >
          {title}
        </BreadcrumbItem>
      ))}
    </Breadcrumbs>
    <Card className={`Card WithBreadcrumb`}>
      <Card.Body>
        <Row>
          <Col lg={8}>
            <PageTitle className={`h2 PageTitle`}>
              {breadcrumbs[breadcrumbs.length - 1]?.header || breadcrumbs[breadcrumbs.length - 1]?.title}
            </PageTitle>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  </div>
)