import React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"

const withEnsureLogin = Component => props => {
  const userStatus = useSelector(state => state.user.status),
        history = useHistory()
  // if (!/success/.test(userStatus)) return history.push('/login')
  if (!/success/.test(userStatus)) return null
  return <Component {...props} />
};

export default withEnsureLogin
