import React from "react";
import { injectBookingModule, PrivateRoute } from "../pages/routes";
import Responsive from "../components/UI/Responsive/Responsive";
import { NullComponent } from "../utils/common";

const CartWatcher = injectBookingModule(() =>
    import(/* webpackChunkName: 'payment-user' */ "./CartWatcher/CartWatcher")
  ),
  CartWatcherMobile = injectBookingModule(() =>
    Promise.resolve({ default: NullComponent })
  );

const fragmentRoutes = [
  <PrivateRoute path={"/"} force={true}>
    <Responsive desktop={CartWatcher} mobile={CartWatcherMobile} />
  </PrivateRoute>
].map((r, index) =>
  React.cloneElement(r, {
    key: `fragment-route-${index}`
  })
);

export default fragmentRoutes;
