import React from "react";
import { Button, Row, Input, Alert } from "reactstrap";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import _ from "lodash";
import { axiosApi } from "../../utils/api";
import { NotificationManager } from "react-notifications";
import styles from "./BookingOrderDetail.module.scss";
import classnames from "classnames";
import { CardElement, injectStripe } from "react-stripe-elements";

import PaynowQR from "paynowqr";
import QRCode from "qrcode.react";

const PaynowCheckoutForm = props => {
  console.log(props);
  const { onClose, payment } = props;

  const PayNowQRCanvas = () => {
    let code = new PaynowQR({
      uen: "201402661E", //Required: UEN of company
      amount: payment.short_amount_paid, //Specify amount of money to pay.
      editable: false, //Whether or not to allow editing of payment amount. Defaults to false if amount is specified
      // expiry: '20201231',         //Set an expiry date for the Paynow QR code (YYYYMMDD). If omitted, defaults to 5 years from current time.
      // refNumber: 'TQINV-10001',   //Reference number for Paynow Transaction. Useful if you need to track payments for recouncilation.
      company: "Adi Media Pte Ltd." //Company name to embed in the QR code. Optional.
    });

    //Outputs the qrcode to a UTF-8 string format, which can be passed to a QR code generation script to generate the paynow QR
    let QRstring = code.output();
    return <QRCode value={QRstring} />;
  };

  return (
    <div className={classnames(styles.StripeFormDlg, "custom-ui shadow")}>
      <div className="p-5">
        <Row>
          <h1>Checkout</h1>
        </Row>
        <Row>
          <div className="w-100">
            Amount: <b>${payment.short_amount_paid}</b>
          </div>
          <div className="w-100">
            For Order: <b>{payment.order_no}</b>
          </div>
        </Row>

        <Row>
          <div class="mt-3 mb-3 mx-auto">
            <PayNowQRCanvas />
          </div>
        </Row>

        <Row>
          <div class="mt-3 mb-3 mx-auto">
            <Button color={"secondary"} onClick={onClose}>
              Cancel
            </Button>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default PaynowCheckoutForm;
