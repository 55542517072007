import { createReducer } from "../../utils/store";
// import update from "react-addons-update";
import { combineReducers } from "redux";
import { produce } from "immer";
import { APP_ACTION_LOADED, APP_ACTION_LOADING } from "./consts";
// import { connectRouter } from "connected-react-router";
// import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import { produce } from "immer";

const app = combineReducers({
  loading: createReducer(false, {
    [APP_ACTION_LOADING]: () => true,
    [APP_ACTION_LOADED]: () => false
  }),
  toggle: createReducer(
    { exhibitorList: false },
    {
      "app/exhibitorToggle": (state, { toggle = false }) =>
        produce(state, draft => {
          draft.exhibitorList = toggle;
        })
    }
  )
});

export default app;
