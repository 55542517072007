import React from "react";
import { Link, NavLink } from "react-router-dom";
import withRole from "../../HOCs/withRole";

const AuthLink = withRole(
  ({
    loginSuccess,
    urlHelper,
    to,
    activeClassName = null,
    isActive = null,
    ...props
  }) => {
    const linkProps = {
      to: urlHelper(to),
      ...props
    };
    const Component = activeClassName && isActive ? NavLink : Link;
    if (activeClassName && isActive) {
      linkProps.activeClassName = activeClassName;
      linkProps.isActive = isActive;
    }
    return <Component {...linkProps} />;
  }
);

export default AuthLink;
