import { defineActionNames } from "../../utils/store";

const [
  FSTALL_ACTION_FETCH,
  FSTALL_ACTION_FETCH_SUCCESS,
  FSTALL_ACTION_FETCH_ADMIN,
  FSTALL_ACTION_FETCH_ADMIN_SUCCESS,
  FSTALL_ACTION_SAVE,
  FSTALL_ACTION_SAVE_SUCCESS,
  FSTALL_ACTION_SUBMIT,
  FSTALL_ACTION_SUBMIT_SUCCESS,
  FSTALL_ACTION_APPROVE,
  FSTALL_ACTION_APPROVE_SUCCESS,
  FSTALL_ACTION_REJECT,
  FSTALL_ACTION_REJECT_SUCCESS
] = defineActionNames("food-stall", [
  "fetch",
  "fetch-admin",
  "save",
  "submit",
  "approve",
  "reject"
]);

const [
  FHANDLER_ACTION_FETCH,
  FHANDLER_ACTION_FETCH_SUCCESS,
  FHANDLER_ACTION_SAVE,
  FHANDLER_ACTION_SAVE_SUCCESS,
  FHANDLER_ACTION_UPDATE,
  FHANDLER_ACTION_UPDATE_SUCCESS,
  FHANDLER_ACTION_DELETE,
  FHANDLER_ACTION_DELETE_SUCCESS
] = defineActionNames("food-handler", ["fetch", "save", "update", "delete"]);

const [
  FAPP_ACTION_FETCH,
  FAPP_ACTION_FETCH_SUCCESS,
  FAPP_ACTION_SAVE,
  FAPP_ACTION_SAVE_SUCCESS,
  FAPP_ACTION_SUBMIT,
  FAPP_ACTION_SUBMIT_SUCCESS,
  FAPP_ACTION_REMARK,
  FAPP_ACTION_REMARK_SUCCESS
] = defineActionNames("reg", ["fetch", "save", "submit", "remark"]);

export {
  FSTALL_ACTION_FETCH,
  FSTALL_ACTION_FETCH_SUCCESS,
  FSTALL_ACTION_FETCH_ADMIN,
  FSTALL_ACTION_FETCH_ADMIN_SUCCESS,
  FSTALL_ACTION_SAVE,
  FSTALL_ACTION_SAVE_SUCCESS,
  FSTALL_ACTION_SUBMIT,
  FSTALL_ACTION_SUBMIT_SUCCESS,
  FSTALL_ACTION_APPROVE,
  FSTALL_ACTION_APPROVE_SUCCESS,
  FSTALL_ACTION_REJECT,
  FSTALL_ACTION_REJECT_SUCCESS,
  FHANDLER_ACTION_FETCH,
  FHANDLER_ACTION_FETCH_SUCCESS,
  FHANDLER_ACTION_SAVE,
  FHANDLER_ACTION_SAVE_SUCCESS,
  FHANDLER_ACTION_UPDATE,
  FHANDLER_ACTION_UPDATE_SUCCESS,
  FHANDLER_ACTION_DELETE,
  FHANDLER_ACTION_DELETE_SUCCESS,
  FAPP_ACTION_FETCH,
  FAPP_ACTION_FETCH_SUCCESS,
  FAPP_ACTION_SAVE,
  FAPP_ACTION_SAVE_SUCCESS,
  FAPP_ACTION_REMARK,
  FAPP_ACTION_REMARK_SUCCESS,
  FAPP_ACTION_SUBMIT,
  FAPP_ACTION_SUBMIT_SUCCESS
};
export const FSTALL_ACTION_ERROR = "licenses/error";
