import { axiosApi } from "../../utils/api";
import AdminmActionTypes from "./const";

const _fetchIUsers = () => ({ type: AdminmActionTypes.FETCH_INTERNAL_USERS }),
  _fetchIUsersError = ({ err }) => ({
    type: AdminmActionTypes.FETCH_INTERNAL_USERS_ERROR,
    err
  }),
  _fetchIUsersSuccess = users => ({
    type: AdminmActionTypes.FETCH_INTERNAL_USERS_SUCCESS,
    users
  });

export const adminFetchIUsers = dispatch =>
  new Promise(resolve => {
    dispatch(_fetchIUsers());
    axiosApi
      .auth()
      .get("/v1/a/u/list-users")
      .then(({ status, data }) => {
        if (status === 200) {
          dispatch(_fetchIUsersSuccess(data));
        } else {
          dispatch(_fetchIUsersError(data));
        }
        resolve();
      });
  });
